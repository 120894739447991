
$input-option-checkbox-size: $font-size-base;
$input-option-hover-bg: $blue-lightest;
$input-option-border-color: $gray-200;
$input-option-search-bg: $input-option-hover-bg;

$input-option-arrow-icon-size: $font-size-base;
$input-option-arrow-icon-color: $primary;

$input-option-search-icon-size: 1.25rem;
$input-option-search-icon-color: $input-option-arrow-icon-color;


.SumoSelect {
	width: 100%;
	display: block;
	transition: $input-transition;


	li:before {
		display: none;
	}


	label {
		margin-bottom: 0;
	}


	& > .optWrapper {
		border-color: $input-border-color;
		box-shadow: $input-focus-box-shadow;
		border-radius: $input-border-radius;
	}


	> .optWrapper.selall,
	> .optWrapper.multiple {


		.select-all,
		li.opt,
		.no-match {
			height: $input-height;
			padding: $input-padding-y $input-padding-x $input-padding-y $spacer;
			font-size: $font-size-base;
			line-height: $input-line-height;
			border-radius: $input-border-radius;


			label {
				margin-bottom: 0;
				height: 100%;
			}
		}


		.no-match {
			height: auto !important;
			min-height: $input-height;
			color: $danger;
			font-weight: $font-weight-normal;
		}
	}


/*
	.no-match {
		height: auto !important;
		min-height: $input-height;
		color: $danger;
		font-weight: $font-weight-normal;
	}
*/


	&.open {

		.search-txt {
			transition: $transition-base;
			color: $input-color;
			height: $input-height;
			padding: $input-padding-y $input-padding-x;
			font-size: $font-size-base;
			line-height: $input-line-height;
			font-weight: $input-font-weight;
			border-radius: $input-border-radius;
			background: $input-option-search-bg;
			padding-left: ($input-padding-x * 2) + $input-option-checkbox-size;
			padding-right: ($input-padding-x * 2) + $input-option-arrow-icon-size;


			&::placeholder {
				color: $input-color;
			}
		}


		.search {
			@extend .icon-meta-suche;


			&:before {
				font-size: $input-option-search-icon-size;
				color: $input-option-search-icon-color;
				position: absolute;
				top: 50%;
				left: $input-padding-x;
				transform: translate(-3%, -41%);
				z-index: 99999999;

			}
		}
	}


	& > .CaptionCont {
		transition: $input-transition;


		span {
			cursor: pointer;
			font-size: $font-size-base;
		}


		span.placeholder {
			font-style: normal;
			color: $input-placeholder-color;
		}


		label {
			width: auto;
			margin: 0;
			padding: 0 $input-padding-x;
			display: flex;
			align-content: center;
			cursor: pointer;
			@extend .icon-arrow-dropdown;


			&:before {
				align-self: center;
				font-size: $input-option-arrow-icon-size;
				color: $input-option-arrow-icon-color;
				z-index: 999;
				transition: $transition-base;
			}


			i {
				display: none;
			}
		}
	}


	&.open > .CaptionCont label {
		z-index: 9999;
	}


	&:focus > .CaptionCont,
	&:hover > .CaptionCont {


		label {
			visibility: visible;
		}
	}



	&.open > .CaptionCont {

		label {
			visibility: visible;

			&:before {
				transform: rotate(180deg);
			}
		}
	}
}



.SumoSelect .CaptionCont.SelectBox {
	padding: $input-padding-y $input-padding-x;
	line-height: $input-line-height;
	font-size: $font-size-base;
	font-weight: $input-font-weight;
	color: $input-color;
	border-width: $input-border-width;
	border-radius: $input-border-radius;
	border-color: $input-border-color;
	height: $input-height
}


.SumoSelect > .optWrapper {
	top: 0 !important;
	border-color: $input-border-color;


	.select-all,
	.options li.opt,
	.no-match  {
		height: $input-height !important;
		padding: $input-padding-y $input-padding-x !important;
		font-size: $font-size-base !important;
		line-height: $input-line-height !important;
		border-radius: $input-border-radius !important;
		font-weight: $input-font-weight;

		label {
			margin-bottom: 0;
			height: 100%;
		}
	}


	&.multiple > .options li.opt.selected span i,
	.select-all.selected > span i,
	.select-all.partial > span i {
		background-color: $primary;
	}
}


.SumoSelect.open .SumoUnder[data-search="true"] ~ .optWrapper {
	top: $input-height !important;
}


.SumoSelect > .search + .optWrapper {

}


.SumoSelect.open > .optWrapper {
	border-color: $input-focus-border-color;
}


.SumoSelect > .optWrapper.multiple > .options li.opt,
.SumoSelect .select-all {


	span,
	& > span {
		width: auto;
		margin: 0;


		i {
			position: absolute;
			margin: auto;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			height: $input-option-checkbox-size;
			width: $input-option-checkbox-size;
			border: 1px solid $input-border-color;
			border-radius: 2px;
			box-shadow: none;
			background-color: $input-bg;
		}
	}


	label {
		margin-left: $input-padding-x + $input-option-checkbox-size;
	}
}


.SumoSelect > .optWrapper {


	.select-all,
	& > .options li.opt {
		border-color: $input-option-border-color;
		transition: $transition-base;

		&.sel,
		&:hover,
		&:focus {
			background-color: $input-option-hover-bg;
		}
	}
}


.SumoSelect:hover > .CaptionCont,
.SumoSelect:focus > .CaptionCont {
	box-shadow: none;
	border-color: $input-focus-border-color;
}



.SumoSelect:focus > .CaptionCont,
.SumoSelect.open > .CaptionCont {
	color: $input-focus-color;
	background: $input-focus-bg;
	box-shadow: $input-focus-box-shadow;
	border-color: $input-focus-border-color;
}


// Prevent flicker while loading

.SumoSelect {
	height: 46px;
}

select.form-control {
	height: 46px;
	opacity: 0;
	transition: $transition-base;
}

.SumoSelect select.form-control {
	opacity: 1;
}


