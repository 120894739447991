// @import "../../../build/assets/icons/iconfont";
@import "../../../temp/assets/iconfont/_iconfont.scss";


@font-face {
  font-family: "iconfont";
  src: url("../icons/iconfont.eot");
  src: local('☺︎'),
  		url("../iconfont/iconfont.eot?#iefix") format("embedded-opentype"),
  		url("../iconfont/iconfont.woff") format("woff"),
  		url("../iconfont/iconfont.ttf") format("truetype"),
  		url("../iconfont/iconfont.svg#iconfont") format("svg");
  	font-weight: normal;
  	font-style: normal;
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "iconfont";
    src: url("../iconfont/iconfont.svg#iconfont") format("svg");
  }
}
