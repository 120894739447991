.navigation-mobile {


	@include media-breakpoint-up(lg) {
		display: none;
	}


	// bugfix...
	@-webkit-keyframes fixAndroidBug {
		from {
			padding:0
		} to {
			padding:0
		}
	}


	// DEFAULT STYLING - DO NOT CHANGE - START

	.slide-nav-checkbox,
	.slide-nav-label-root,
	.slide-nav-root {
		display: block;
	}

	.slide-nav-checkbox {
		position:absolute;
		top:-99999px;
		left:-99999px;
		visibility: hidden;
	}

	.slide-nav-label-root {
		display: block;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 9998;
	}

	.slide-nav-root,
	.slide-nav-sub {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 320px;
		background: #fff;
		z-index: 9999;
		right: -400px;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-transform:translate(0,0);
		-ms-transform:translate(0,0);
		transform:translate(0,0);
		-webkit-transition:-webkit-transform .33s ease;
		transition:transform .33s ease;
		-webkit-transform:translate3d(0,0,0); // Force Hardware Acceleration in WebKit with translate3d
		-ms-overflow-style: -ms-autohiding-scrollbar;


		&::-webkit-scrollbar {
			display: none;
		}
	}

	.slide-nav-sub {
		position: absolute;
		bottom: auto;
		bottom: 0;
	}

	.slide-nav-root {
		min-height: 100%;

		ul {
			background: #fff;
			text-decoration:none;
			padding:0;
			margin: 0;
			list-style:none;
			padding-top: 50px;
			box-sizing: border-box;
			min-height: 100%;
		}

		& > ul {
			position: absolute;
			top: 0;
			right: 0;
			bottom: auto;
			left: 0;
			z-index: 99999;
		}

		li {
			// line-height: 50px;
		}

		a, span {
			display:block;
			line-height: 50px;
			padding: 0 50px;
			color:#fff;
			text-decoration:none;
			position: relative;
		}


		a {
			visibility: hidden;
		}

		.slide-nav-sub-heading {
			padding-left: 0;
			color: #fff;
		}

		.slide-nav-label,
		.slide-nav-root-heading {
			// line-height: 50px;
			// display: inline-block;
			// width: 50px;
			// text-align: center;
		}

		.slide-nav-root-heading {
			position: absolute;
			top: 20px;
			left: 30px;
			color: $primary;
			font-weight: 600;
			font-size: 20px;
		}

		.slide-nav-label-sub {
			// line-height: 50px;
			color:#fff;
			cursor:pointer;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.slide-nav-label-back {
			// position:absolute;
			// left: 15px;
			// top: 5px;
			cursor:pointer;
			// color: $primary;
		}

		.slide-nav-label-close {
			position:absolute;
			right: 5px;
			top: 5px;
			cursor:pointer;
			/// color:#fff;
		}
	}

	.slide-nav-checkbox:checked ~ .slide-nav-root,
	.slide-nav-root .slide-nav-checkbox:checked ~ .slide-nav-sub {
		-webkit-transform:translate(-400px,0);
		-ms-transform:translate(-400px,0);
		transform:translate(-400px,0);

		a {
			visibility: visible !important;
		}
	}










	// STYLING

	.slide-nav-label-root {
		display: none; // moved to header
	}


	.slide-nav-root {
		box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.5);
		background: $gray-400;

		ul {
			padding-top: 70px;
			background: $gray-200;
		}

		a,
		span {
			line-height: 1.5;
			padding: ($spacer * 1) ($spacer * 1) ($spacer * 1) ($spacer * 4);
			color: $white;
			font-size: 20px;
			background: $primary;
			border-bottom: 1px solid (darken($blue, 10%));
			font-weight: $font-weight-bold;

			&:hover,
			&:focus {
				background: $blue-darker;
			}
		}
	}


	span,
	.is-active,
	.slide-nav-active {
		// color: $primary;
	}


	label {
		text-indent: -9999em;
		overflow: hidden;
		margin: 0;
		font-weight: 300;
	}


	.slide-nav-label-back {
		text-indent: 0em;
	}


	.slide-nav-label-back,
	.slide-nav-root-heading {
		position: absolute;
		top: 20px;
		left: 0px;
		color: $primary;
		font-weight: $font-weight-bold;
		font-size: 20px;
	}


	.slide-nav-sub-heading {

		a,
		span {
			color: $white;
		}
	}


	.slide-nav-label,
	.slide-nav-label-sub,
	.slide-nav-label-sub-sub,
	.slide-nav-label-sub-sub-sub,
	.slide-nav-label-sub-sub-sub-sub,
	.slide-nav-label-back {

		&:before {
			position: absolute;
			text-indent: 0em;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: 16px;
		}
	}


	.slide-nav-label-sub {
		width: auto;


		&:before {
			display: block;
			position: absolute;
			top: 50%;
			left: auto;
			right: ($spacer * 0.5);
			color: $white;
			font-size: 24px;
			@extend .icon-link_intern;
		}
	}


	.slide-nav-label-sub-sub,
	.slide-nav-label-sub-sub-sub,
	.slide-nav-label-sub-sub-sub-sub, {

		&:before {
			color: $text-color;
		}
	}


	.slide-nav-label-back {
		padding-left: ($spacer * 4);

		&:before {
			@extend .icon-link_intern;
			transform: translate(-50%, -50%) rotate(180deg);
			left: ($spacer * 2);
			font-size: 24px;
		}
	}


	.slide-nav-label-close {

		&:before {
			@extend .icon-close;
		}
	}


	.slide-nav-item-donate {

		a,
		span {
			background: $orange;
			border-bottom: none;

			&:hover,
			&:focus {
				background: darken($orange, 10%);
			}
		}
	}

	.slide-nav-item-shortcut {
		border-bottom: 1px solid $gray-200;

		a,
		span {
			background: $white;
			padding: ($spacer * 1.25) ($spacer * 1.5) ($spacer * 1.25) ($spacer * 4);

			&:hover,
			&:focus {
				background: $gray-400;
			}
		}
	}


	.slide-nav-item-meta {

		a,
		span {
			background: $gray-200;
			//padding: ($spacer * 1.5) ($spacer * 0.5) ($spacer * 0.5) ($spacer * 4);
			padding: ($spacer * 0.5) ($spacer * 0.5) ($spacer * 0.5) ($spacer * 4);
			margin-top: ($spacer * 1);

			&:hover,
			&:focus {
				color: $blue;
				background: $gray-200;
			}
		}


		&.is-current {

			a,
			span {
				color: $blue;
			}
		}
	}


	.slide-nav-item-meta + .slide-nav-item-meta {

		a,
		span {
			// padding-top: ($spacer * 0.5);
			margin-top: 0;
		}
	}

	.slide-nav-item-meta,
	.slide-nav-item-shortcut {

		a,
		span {
			font-size: 18px;
			color: $text-color;
			font-size: 14px;
			border-bottom: none;
		}
	}

	.slide-nav-item-informieren,
	.slide-nav-item-leben,
	.slide-nav-item-mitmachen,
	.slide-nav-item-meta-intern,
	.slide-nav-item-meta-logout,
	.slide-nav-item-meta-back,
	.slide-nav-item-shortcut,
	.slide-nav-sub-heading {

		a,
		span {

			&:before {
				display: block;
				position: absolute;
				top: 50%;
				left: ($spacer * 2);
				transform: translate(-50%,-50%);
				color: $white;
				font-size: 30px;
			}
		}
	}

	.slide-nav-item-informieren,
	.slide-nav-item-leben,
	.slide-nav-item-mitmachen {

		a,
		span {

			&:before {
				color: $white;
			}
		}
	}


	.slide-nav-item-shortcut {

		> a,
		> span {

			&:before {
				color: $primary;
			}
		}
	}

	.slide-nav-item-informieren {

		> a,
		> span,
		.slide-nav-sub-heading a,
		.slide-nav-sub-heading span {
			@extend .icon-navi-birne;
		}
	}

	.slide-nav-item-leben {

		> a,
		> span,
		.slide-nav-sub-heading a,
		.slide-nav-sub-heading span {
			@extend .icon-navi-herz;
		}
	}

	.slide-nav-item-mitmachen {

		> a,
		> span,
		.slide-nav-sub-heading a,
		.slide-nav-sub-heading span {
			@extend .icon-navi-sonne;
		}
	}

	.slide-nav-item-shortcut-phone {

		> a,
		> span {
			@extend .icon-header-kontakt-telefon;
		}
	}

	.slide-nav-item-shortcut-mail {

		> a,
		> span {
			@extend .icon-header-kontakt-email;
		}
	}

	.slide-nav-item-shortcut-newsletter {

		> a,
		> span {
			@extend .icon-sticky-newsletter;
		}
	}

	.slide-nav-item-meta-intern {

		> a,
		> span {
			@extend .icon-meta-login;

			&:before {
				color: $text-color;
			}
			
			&:hover:before {
				color: $primary;
			}
		}
	}
	
	.slide-nav-item-meta-logout {

		> a,
		> span {
			@extend .icon-meta-logout;

			&:before {
				color: $text-color;
			}
			
			&:hover:before {
				color: $primary;
			}
		}
	}
	
	.slide-nav-item-meta-back {

		> a,
		> span {
			@extend .icon-link_intern;

			&:before {
				color: $text-color;
				transform: translate(-50%,-50%) rotate(-180deg);
			}
			
			&:hover:before {
				color: $primary;
			}
		}
	}
	
	

	.slide-nav-sub li:not(.slide-nav-sub-heading) {

		> a,
		> span {
			background: #fff;
			color: $text-color;
			border-bottom-color: $gray-400;
			position: relative;
			font-size: 16px;
			padding: 1rem 3rem 1rem 1rem;

			&:before {
				content: "";
				display: block;
				height: 100%;
				width: ($spacer * 0.5);
				position: absolute;
				top: 0 !important;
				left: 0;
				transform: translate(0);
			}
		}
	}
}


.slide-nav-sub {

	li[class^='slide-nav-sub-item--'] {

		&.is-current {

			> a,
			> span {
				color: white;
				z-index: 1;

				&:before {
					width: 100%;
					z-index: -1;
				}

				.slide-nav-label {

					&:before {
						color: white;
					}
				}
			}
		}

		> a:focus,
		> a:hover {
			background: $blue-lighter !important;
		}
	}


	li.slide-nav-sub-item {

		&.is-current {

			> a,
			> span {
				background: $blue-lighter;
			}
		}

		> a:focus,
		> a:hover {
			background: $blue-lighter !important;
		}
	}

	li.slide-nav-sub-heading {

		> a:focus,
		> a:hover {
			background: $blue-darker !important;
		}
	}
}







button.slide-nav-trigger {
	border: none;
	background: transparent;
	border: none;
	padding: 0;
	margin: 0;
}




@each $key, $value in $areas {

	.slide-nav-sub-item--#{$key} {

		> a,
		> span {

			&:before {
				background: $value;
			}
		}


		.slide-nav-sub-heading {

			a,
			span {
				background: $value;
				border-bottom-color: $value;
			}
		}
	}
}



// dev


/*

.navigation-mobile #slide-nav-checkbox-root {
    position: absolute;
    top: 90px;
    left: 0px;
    visibility: visible;
	z-index: 999999;
}


.slide-nav-label-sub.has-focus {
	text-decoration: underline !important;
	background: red !important;
}
*/

