// Browsersupport: IE9+
.uploaded-logo {
	background: #fff;
	margin-right: 20px;
	padding: 20px;
	float: left;
	border: 1px solid #ccc;
}

.upload {
  position: relative;
  display: block;
  overflow: hidden;

  label {
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: 0;
	display: block;

    &:before, &:after {
        display: block;
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        //white-space: nowrap;
    }

    &:before {
        padding: 0 20px;
        /*
        position: absolute;
        left: 50%;
        transform: translate(-50%,0);
        bottom: 40px;
        */
        //width: 160px;
        position: absolute;
        left: 25px;
		top: 50%;
		transform: translateY(-50%);
		width: auto;
        z-index: 2;
        color: #fff;
        text-align: center;
        content: attr(data-select-text);
        border: 1px solid #ccc;
        color: $white;
    	background-color: white;
    	-webkit-transition: all .2s ease-in-out;
    	transition: all .2s ease-in-out;
        font-size: $font-size-base;
        font-weight: 400;
    	box-sizing: border-box;
        padding: 7px 15px;
        width: 170px;

        color: $white;
    	background-color: $blue-darker;
    	border-color: $blue-darker;
    }

    &:after {
        position: relative;
		width: 100%;
		margin-top: 0;
		padding: 20px 20px 20px 220px;
		line-height: 24px;
		height: auto;
		background: white;
		content: attr(data-input-value);
		text-align: left;
		font-size: $font-size-sm;
		font-weight: 300;
		border: 1px solid #ccc;
        /*
        border: 1px dashed #e0e3ef;
        background-image: url('../../assets/images/upload.svg');
        background-repeat: no-repeat;
        background-position: top 50px center;
        */
        color: $text-color;
    }
  }

    &:hover {
       label:before {
            color: white;
    	    background-color: $blue;
    	    border-color: $blue;
        }

        label:after {
            // box-shadow: inset rgba(0, 0, 0, 0.18) 5px 5px 10px;
        }
    }

  &.upload--done {

  }

  input[type=file] {
    position: absolute;
    top: 100%;
    margin-top: -280px;
    z-index: 1;
    height: 280px;
    width: 100%;
    opacity: 0;

    &:not(.empty):valid + label:before {
      content: attr(data-remove-text);
    }

    &:not(.empty):valid + label:after {
        color: $text-color;
    }

    &:invalid + label:after,
    &.empty + label:after  {
      content: attr(data-drag-text);
    }
  }
}


@include media-breakpoint-down(md) {

	.upload {

    	label {

        	&:before {
	        	top: 20px;
	        	transform: unset;
	        	left: 20px;
        	}

        	&:after {
            	padding-top: 80px;
            	padding-left: 20px;
            	padding-right: 20px;
            	padding-bottom: 20px;
            	background-image: none;
        	}
    	}
	}
}


.form-control-file {
	@include focus();
}
