
.deckhead {
	font-size: $font-size-xs;
	line-height: 1.4;
	color: $text-color;


	> span + span,
	> span + date,
	> date + span,
	> date + date {


    	&:before {
	    	content: "·";
	    	padding: 0 4px;


			@include media-breakpoint-up(sm) {
				padding: 0 7px;
			}
    	}
	}


	.deckhead__author {
		color: $primary;
		font-weight: $font-weight-bold;


		&:before {
			color: $text-color;
			font-weight: $font-weight-normal;
		}
	}
}