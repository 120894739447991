

html {
    height: 100%;
}


html,
body {
	margin: 0;
	overflow-x: hidden;
	font-weight: 300;
	background: $body-bg;
	/*
	font-size: $font-size-xs;

	@include media-breakpoint-up(md) {
		font-size: $font-size-base;
	}
	*/
}


.main {
    min-height: 100%;
	position: relative;
	padding-top: 70px;
	z-index: 1; // TODO: fix for various z-index issues?


	@include media-breakpoint-up(lg) {
		padding-top: 270px;
	}
}


.site-type--regional .main {

	@include media-breakpoint-up(lg) {
		padding-top: 250px;
	}
}


.main__middle {
	padding-top: floor(($spacer * 1 ));
	position: relative;

	@include media-breakpoint-down(sm) {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}

	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		top: 0;
		right: 10px;
		left: 10px;
		background: $white;
		display: block;
	}


	@include media-breakpoint-up(xs) {
		padding-bottom: ($spacer * 1 ); // 4
	}


	@include media-breakpoint-up(md) {
		padding-top: ($spacer * 5 );
		padding-bottom: ($spacer * 2 ); // 7
	}


	@include media-breakpoint-up(lg) {

		&:before {
			right: floor(( $grid-gutter-width / 2 ));
			left: floor(( $grid-gutter-width / 2 ));
		}
	}
}


@media print {

	html, body {
		background: transparent;
	}

	.main {
    	padding-top: 130px !important;
	}

}