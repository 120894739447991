
.filter {


	.form-group {
		margin-bottom: ($spacer * 2);
	}
}


.filter__footer {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;


	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}


	.btn-reset {
		margin-right: ($spacer * 0.5);


		@include media-breakpoint-up(sm) {
			margin-right: ($spacer * 1);
		}
	}


	&.filter__footer--right {
		justify-content: flex-end;
	}


	&.filter__footer--left {
		justify-content: flex-start;
	}
}


.filter__submit {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding-top: ($spacer * 1);
	padding-bottom: ($spacer * 1);
	border-top: 1px solid $gray-300;
	border-bottom: 1px solid $gray-300;


	@include media-breakpoint-up(sm) {
		justify-content: flex-end;
	}


	@include media-breakpoint-up(lg) {
		width: auto;
		padding: 0;
		border: 0;
	}
}


.filter__lang-variant {
	margin-bottom: ($spacer * 2);


	@include media-breakpoint-up(md) {
		margin-bottom: ($spacer * 2);
	}


	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
	}
}















/*
	.filter__expand {
		color: gray;
		cursor: pointer;
		font-size: $font-size-xs;
		text-align: right;


		&:after {
			&:extend(.icon-arrow_dropdown:before);
			padding-left: 10px;
			padding-right: 5px;
			font-size: 8px;
		}


		&:hover {
			color: $brand-primary;
			text-decoration: none;
		}


		@media (min-width: $screen-sm-min) {
			display: none !important;
		}
	}
*/


/*
	.filter__close {
		color: gray;
		cursor: pointer;
		display: none;
		font-size: $font-size-xs;
		text-align: right;


		&:after {
			&:extend(.icon-close_big:before);
			padding-left: 7px;
			font-size: 12px;
		}


		&:hover {
			color: $brand-primary;
			text-decoration: none;
		}


		@media (min-width: $screen-sm-min) {
			display: none !important;
		}
	}
*/


/*
	.filter__panel {
		display: none;
		position: relative;


		@media (min-width: $screen-sm-min) {
			display: block !important;
			padding-left: 0;
			padding-right: 0;
		}
	}
*/


/*
	&.is-expanded {

		.filter__panel {
			display: block;
		}


		.filter__expand {
			display: none;
		}


		.filter__close {
			display: block;
		}
	}
*/



/*
.filter--toogle {

	.filter--toogle__hidden {
		display: none;
		position: relative;
	}

	.filter--toogle__btn {

	}

}


.filter--toogle__close {
	position: absolute;
	right: 0;
	top: 5px;
	font-size: $font-size-xs;
	color: $gray;

	&:after {
		&:extend(.icon-close:before);
		padding-left: 5px;
	}

	&:hover {
		color: $brand-primary;
		text-decoration: none;
	}

}
*/

