

.list__item {
	position: relative;


	.list__wrap {
		clear: both;
		overflow: hidden;
		padding-bottom: floor(($spacer * 1));
		margin-bottom: floor(($spacer * 1));
		border-bottom: 1px solid #e8e8e8;


		@include media-breakpoint-up(sm) {
			padding-bottom: floor(($spacer * 2));
			margin-bottom: floor(($spacer * 2));
		}
	}


	&:first-child .list__wrap {
		padding-top: floor(($spacer * 1));
		border-top: 1px solid #e8e8e8;
	}


	@include media-breakpoint-up(sm) {


		&:first-child .list__wrap{
			padding-top: floor(($spacer * 2));
		}
	}
}


.list__deckhead {
	margin-bottom: floor(($spacer * 0.25));
}


.list__image {
	display: none;

	@include media-breakpoint-up(sm) {
		display: block;
		float: left;
		width: 270px;
		margin-right: floor(( $grid-gutter-width / 2 ));

		.image__credits {
			// right: floor(( $grid-gutter-width / 2 ));
		}
	}


	@include media-breakpoint-up(md) {
		margin-right: floor(( $grid-gutter-width * 1 ));
	}
}


.list__subcategory {
	padding: ($spacer * 1.5) ($grid-gutter-width * 0.666667);
	margin-bottom: 0;
	position: relative;
	z-index: 1;
	background: #f7f7f7;
	color: $primary;
	@extend .h4;
	text-align: left;


	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 66px;
		width: 5px;
	}


	@include media-breakpoint-up(md) {
		padding: ($spacer * 1.5) ($grid-gutter-width * 1);


		&:before {
			// height: 87px;
			height: 66px;
		}
	}
}


@each $key, $value in $areas {

	.list__item--#{$key} {

		.list__subcategory {
			color: $value;


			&:before {
				background: $value;
			}
		}
	}
}

// new sass map with area names as classes

@each $key, $value in $area-colors {

	.list__item--#{$key} {

		.list__subcategory {
			color: $value;


			&:before {
				background: $value;
			}
		}
	}
}


@include media-breakpoint-up(sm) {

	.list__image--book {
		position: relative;
		text-align: center;
		background: $gray-100;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 ($spacer * 2) ($spacer * 2) ($spacer * 2);


		.image__book {
			display: flex;
			align-items: center;
			justify-content: center;
		}


		img {
			position: relative;
			margin: 0 auto;
			align-self: center;
		}


		.image__shadow {
			position: relative;
			display: flex;
			justify-content: center;
			align-self: center;


			&:before {
				content: "";
				position: absolute;
				right: -15px;
				bottom: 0;
				width: 30px;
				height: 30px;
				background: $gray-300;
				transform: skewX(-45deg);
			}
		}


		.image__credits {
			background: transparent;
			color: $gray-600;
			padding: 4px;
		}
	}
}


/*
.list__title {
	margin-bottom: floor(($spacer * 0.5));
	-webkit-backface-visibility: hidden;
	transition:color .2s ease-in-out;


	&.h1 {
		line-height: 1.3;
		margin-top: floor(($spacer * 0.5));
		margin-bottom: floor(($spacer * 1.75));
	}


	&.h2 {
		line-height: 1.4;
	}
}
*/


.list__subtitle {
	display: block;
	font-size: $font-size-base;
	margin-top: ($spacer * 0.25)
}


.list__text {
	overflow: hidden;


	p {
		margin-bottom: 0;
	}


	.link-group {

		ul {
			margin-bottom: 0;
		}


		a[class^="icon-link"] {
			padding-left: 30px;
		}
	}
}


.list__item--publication .list__text {


	p {
		margin-bottom: ($spacer);
	}
}


.list__item {

	// Wrap Link
	a {
		text-decoration: none;


		.list__title {
			color: $text-color;
		}


		p {
			color: $text-color;
		}


		.read-more {
			font-weight: 600;
		}


		&:hover,
		&:focus {
			text-decoration: none;


			.list__title {
				color: $primary;
			}


			.read-more {
				color: $primary;
			}


			.btn-outline-primary {
				background: $primary;
				color: white;
			}
		}
	}
}


// Single Links
.list__title a {
	color: $primary;
}



