// Remember:
// Every file in styles/ acts as an entry-point and gets tranformed to build/. Feel free to create as much as you want.
// Files in subdirectories will be ignored but you can import them in entry-point-files.
// Add an _ to a file and it will just be ignored(good for tests).
// Add your entry-point-files to templates/layout/yourlayout.html
// Example: Create a new directory for your component and import it to this entry-point-file like this...
// @import "mycomponent/mycomponent.less";
// @import "../modules/mycomponent/mycomponent.less";





// ===============================================================================
// LIBS
// ===============================================================================



@import "_iconfont.scss";
@import "_variables.scss"; 	// Copy bootstrap variables in here, edit and remove '!default' flag. Add your own variables at the end of this file
@import "_libs.scss"; 		// Imports bootstrap styles – and more…
@import "_fonts.scss";






// ===============================================================================
// MIXINS
// ===============================================================================

@import "mixins/_wide-bg.scss";
@import "mixins/_focus.scss";
@import "mixins/_mixins.scss";





// ===============================================================================
// LAYOUTS
// ===============================================================================

@import "../../patterns/layouts/_main.scss";
@import "../../patterns/layouts/_grid.scss";
@import "../../patterns/layouts/_aspect-ratio.scss";


// @import "../../patterns/layouts/_grid-helpers.less";
// @import "../../patterns/layouts/_embed-responsive-helpers.less";







// ===============================================================================
// ATOMS
// ===============================================================================

// @import "../../patterns/atoms/lists/lists.less";
// @import "../../patterns/atoms/read-more/read-more.less";

// TYPOGRAPHY
@import "../../patterns/atoms/typography/_link.scss";
@import "../../patterns/atoms/typography/_headings.scss";
@import "../../patterns/atoms/typography/_paragraph.scss";
@import "../../patterns/atoms/typography/_list.scss";

// BLOCKQUOTES
@import "../../patterns/atoms/blockquote/_blockquote.scss";
@import "../../patterns/atoms/blockquote/_cite.scss";

// LINKS
@import "../../patterns/atoms/icon-links/_icon-links.scss";

// TABLES
// @import "../../patterns/atoms/tables/tables.less";

// MEDIA
// @import "../../patterns/atoms/media/_audio.scss";
// @import "../../patterns/atoms/media/_credits.scss";
// @import "../../patterns/atoms/media/_caption.scss";
@import "../../patterns/atoms/media/_image.scss";
// @import "../../patterns/atoms/media/_video.scss";

// BUTTONS
@import "../../patterns/atoms/buttons/_buttons.scss";

// FORM ELEMENTS
@import "../../patterns/atoms/forms/_forms.scss";
@import "../../patterns/atoms/forms/_file.scss";
@import "../../patterns/atoms/forms/_checks.scss";
@import "../../patterns/atoms/forms/_selects.scss";

// MISC
@import "../../patterns/atoms/logo/_logo.scss";
// @import "../../patterns/atoms/seperator/_seperator.scss";

// SPECIAL
@import "../../patterns/atoms/lang-accessibility/_lang-accessibility.scss";
@import "../../patterns/atoms/focus/_focus.scss";



// ===============================================================================
// MOLECULES
// ===============================================================================

@import "../../patterns/molecules/lightbox/_lightbox.scss";
@import "../../patterns/molecules/breadcrumb/_breadcrumb.scss";
@import "../../patterns/molecules/pagination/_pagination.scss";
@import "../../patterns/molecules/deckhead/_deckhead.scss";
@import "../../patterns/molecules/lists/_lists.scss";
@import "../../patterns/molecules/follow/_follow.scss";
@import "../../patterns/molecules/shares/_shares.scss";
@import "../../patterns/molecules/filter/_filter.scss";
@import "../../patterns/molecules/teaser/_teaser.scss";
@import "../../patterns/molecules/textrow/_textrow.scss";
@import "../../patterns/molecules/headline/_headline.scss";
@import "../../patterns/molecules/link-group/_link-group.scss";
@import "../../patterns/molecules/accordion/_accordion.scss";
@import "../../patterns/molecules/facts/_facts.scss";
@import "../../patterns/molecules/team/_team.scss";
@import "../../patterns/molecules/address/_address.scss";
@import "../../patterns/molecules/carousel/_carousel.scss";
@import "../../patterns/molecules/hero/_hero.scss";
@import "../../patterns/molecules/newsletter-sticky/_newsletter-sticky.scss";
@import "../../patterns/molecules/contact-shortcuts/_contact-shortcuts.scss";
@import "../../patterns/molecules/navigation/_navigation-main.scss";
@import "../../patterns/molecules/navigation/_navigation-meta.scss";
@import "../../patterns/molecules/navigation/_navigation-mobile.scss";
@import "../../patterns/molecules/search/search.scss";
@import "../../patterns/molecules/glossary-list/_glossary-list.scss";
@import "../../patterns/molecules/squared-links/_squared-links.scss";
@import "../../patterns/molecules/tabs/_tabs.scss";
@import "../../patterns/molecules/steps/_steps.scss";
@import "../../patterns/molecules/notices/_notices.scss";
@import "../../patterns/molecules/newsletter-note/_newsletter-note.scss";
@import "../../patterns/molecules/map/_map.scss";
@import "../../patterns/molecules/top/_top.scss";
@import "../../patterns/molecules/skip-link/_skip-link.scss";

// Ext
@import "../../patterns/molecules/cookieconsent/_cookieconsent.scss";



// ===============================================================================
// ORGANISMS
// ===============================================================================

@import "../../patterns/organisms/header/_header.scss";
@import "../../patterns/organisms/footer/_footer.scss";
@import "../../patterns/organisms/facebookstream/_facebookstream.scss";
@import "../../patterns/organisms/shop/_shop.scss";
@import "../../patterns/organisms/shop/shop-list-item/_shop-list-item.scss";
@import "../../patterns/organisms/shop/shop-cart-item/_shop-cart-item.scss";
@import "../../patterns/organisms/shop/shop-detail/_shop-detail.scss";
@import "../../patterns/organisms/shop/shop-minicart/_shop-minicart.scss";
@import "../../patterns/organisms/shop/shop-featured-item/_shop-featured-item.scss";
@import "../../patterns/organisms/shop/checkout-pager/_checkout-pager.scss";
@import "../../patterns/organisms/contact-form/_contact-form.scss";
@import "../../patterns/organisms/sitemap/_sitemap.scss";
@import "../../patterns/organisms/404/_404.scss";
@import "../../patterns/organisms/solr-search/_solr-search.scss";
@import "../../patterns/organisms/read-speaker/_read-speaker.scss";
@import "../../patterns/organisms/fundraising-form/_fundraising-form.scss";












