// Lists
// -------------------------


li {
	// font-size: $font-size-md;
	
	
	@include media-breakpoint-down(sm) {
		font-size: $font-size-sm;
		line-height: 1.6;
	}
}