cite {
    color: $text-color;
    font-style: normal;
	display: block;
	font-size: $font-size-xs;
	margin: 0 0 ($spacer * 1.5);


	@include media-breakpoint-up(md) {
		font-size: $font-size-sm;
	}
}