
.logo-primary {
	display: block;
    text-decoration: none;
    height: auto;
	
	
	img {
		display: block;
		width: 286px;
		height: auto;
	}
	

	svg {
		display: block;
		width: 100%;
		width: 100% \9; // IE 10 fix
		height: auto;
	}
}
