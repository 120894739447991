

// Focus outline

.is-focusable:focus,
.is-focusable:active:focus,
a:focus,
a:active:focus,
button:focus,
button:active:focus,
input:focus,
input:active:focus,
input:focus + label:before, // custom check box
input:active:focus + label:before, // custom check box
select:focus,
select:active:focus,
textarea:focus,
textarea:active:focus,
*[tabindex="0"]:focus,
*[tabindex="0"]:active:focus {
	outline-width: 2px !important;
	outline-style: dotted !important;
	outline-color: $orange !important;
	// box-shadow: none !important;
}


// Remove focus outline when no keyboard input is detected

body:not(.user-is-tabbing) {

	.is-focusable:focus,
	.is-focusable:active:focus,
	a:focus,
	a:active:focus,
	button:focus,
	button:active:focus,
	input:focus,
	input:active:focus,
	select:focus,
	select:active:focus,
	textarea:focus,
	textarea:active:focus,
	*[tabindex="0"]:focus,
	*[tabindex="0"]:active:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}