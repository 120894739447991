/*
  Icon Font: iconfont
*/

@font-face {
  font-family: "iconfont";
  src: url("./iconfont.eot");
  src: url("./iconfont.eot?#iefix") format("embedded-opentype"),
       url("./iconfont.woff") format("woff"),
       url("./iconfont.ttf") format("truetype"),
       url("./iconfont.svg#iconfont") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "iconfont";
    src: url("./iconfont.svg#iconfont") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-akkordion-oeffnen:before,
.icon-arrow-button-next:before,
.icon-arrow-button-prev:before,
.icon-arrow-dropdown:before,
.icon-blockquote_end:before,
.icon-blockquote_start:before,
.icon-close:before,
.icon-delete:before,
.icon-facebook:before,
.icon-fehler:before,
.icon-header-kontakt-email:before,
.icon-header-kontakt-telefon:before,
.icon-info:before,
.icon-leichte-sprache:before,
.icon-link_download:before,
.icon-link_extern:before,
.icon-link_intern:before,
.icon-link_phone:before,
.icon-mail:before,
.icon-mail-small:before,
.icon-menu:before,
.icon-message-shop-bestellt:before,
.icon-meta-login:before,
.icon-meta-logout:before,
.icon-meta-suche:before,
.icon-navi-birne:before,
.icon-navi-herz:before,
.icon-navi-sonne:before,
.icon-phone:before,
.icon-pin:before,
.icon-pin-lebenshilfe:before,
.icon-pin-recht:before,
.icon-plus:before,
.icon-print:before,
.icon-reload:before,
.icon-schwere-sprache:before,
.icon-shop-delete:before,
.icon-slider-arrow:before,
.icon-slider-arrow__next:before,
.icon-slider-arrow__prev:before,
.icon-sticky-newsletter:before,
.icon-teaser-birne:before,
.icon-teaser-herz:before,
.icon-teaser-share:before,
.icon-teaser-sonne:before,
.icon-tickbox:before,
.icon-twitter:before,
.icon-upload:before,
.icon-warenkorb:before,
.icon-youtube:before {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-akkordion-oeffnen:before { content: "\f104"; }
.icon-arrow-button-next:before { content: "\f13d"; }
.icon-arrow-button-prev:before { content: "\f13e"; }
.icon-arrow-dropdown:before { content: "\f13c"; }
.icon-blockquote_end:before { content: "\f144"; }
.icon-blockquote_start:before { content: "\f145"; }
.icon-close:before { content: "\f106"; }
.icon-delete:before { content: "\f147"; }
.icon-facebook:before { content: "\f103"; }
.icon-fehler:before { content: "\f108"; }
.icon-header-kontakt-email:before { content: "\f129"; }
.icon-header-kontakt-telefon:before { content: "\f10a"; }
.icon-info:before { content: "\f10c"; }
.icon-leichte-sprache:before { content: "\f10d"; }
.icon-link_download:before { content: "\f133"; }
.icon-link_extern:before { content: "\f130"; }
.icon-link_intern:before { content: "\f12e"; }
.icon-link_phone:before { content: "\f14a"; }
.icon-mail:before { content: "\f126"; }
.icon-mail-small:before { content: "\f13f"; }
.icon-menu:before { content: "\f114"; }
.icon-message-shop-bestellt:before { content: "\f115"; }
.icon-meta-login:before { content: "\f116"; }
.icon-meta-logout:before { content: "\f149"; }
.icon-meta-suche:before { content: "\f117"; }
.icon-navi-birne:before { content: "\f118"; }
.icon-navi-herz:before { content: "\f119"; }
.icon-navi-sonne:before { content: "\f11a"; }
.icon-phone:before { content: "\f143"; }
.icon-pin:before { content: "\f134"; }
.icon-pin-lebenshilfe:before { content: "\f11b"; }
.icon-pin-recht:before { content: "\f11c"; }
.icon-plus:before { content: "\f146"; }
.icon-print:before { content: "\f127"; }
.icon-reload:before { content: "\f11d"; }
.icon-schwere-sprache:before { content: "\f11e"; }
.icon-shop-delete:before { content: "\f142"; }
.icon-slider-arrow:before { content: "\f11f"; }
.icon-slider-arrow__next:before { content: "\f136"; }
.icon-slider-arrow__prev:before { content: "\f13b"; }
.icon-sticky-newsletter:before { content: "\f120"; }
.icon-teaser-birne:before { content: "\f121"; }
.icon-teaser-herz:before { content: "\f122"; }
.icon-teaser-share:before { content: "\f140"; }
.icon-teaser-sonne:before { content: "\f123"; }
.icon-tickbox:before { content: "\f124"; }
.icon-twitter:before { content: "\f101"; }
.icon-upload:before { content: "\f125"; }
.icon-warenkorb:before { content: "\f141"; }
.icon-youtube:before { content: "\f102"; }
