
// Add outline on focus for keyboard users

@mixin focus($width:2px, $color:$orange, $style:dotted ) {

/*
	&:focus,
	&:active:focus,
	&.focus {
	  outline-width: $width !important;
	  outline-style: $style !important;
	  outline-color: $color !important;
	  box-shadow: none !important;
	}
*/
}
