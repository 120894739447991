

.accordion {
	position: relative;
	// overflow: hidden;


	.accordion__item {
		border-top: 1px solid #e8e8e8;
		position: relative;


        &:last-child {
	        border-bottom: 1px solid #e8e8e8;
        }
	}


	.accordion__button {
		font-size: $h3-font-size;
		display: block;
		cursor: pointer;
		margin: 0;
		position: relative;
		padding: 0;
		transition: all 0.2s ease-in-out;
		@extend .icon-close;


		&:before {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%) rotate(45deg);
			font-size: 20px;
			transition: all 0.2s ease-in-out;
			color: $gray;
			z-index: 999;
		}


		&:hover,
		&:active,
		&:focus {
			outline: none;

			&:before {
				color: $text-color;
				transform: translateY(-50%) rotate(0);
			}
		}


		button {
			all: inherit;
			margin: 0;
			display: block;
			width: 100%;
			padding: ($spacer * 0.5) 70px ($spacer * 0.5) 0;


			&:focus {
				outline: none !important;
				background: #f3f8fb;
				color: $blue;
			}
		}


		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;


			&:before {
				right: floor(( $grid-gutter-width * 0.75 ));
			}


			button {
				padding: ($spacer * 1.25) 70px ($spacer * 1.25) 0;
			}
		}
	}


	.accordion__panel {
		position: absolute;
		left: -10000em;
		padding: 0 floor(( $grid-gutter-width * 0.5 )) ($spacer * 0.5);
		opacity: 0;
		transform: translateY(-5px);
		transition: opacity .3s ease-in-out .1s, transform .3s ease-in-out 0s;
		z-index: 1;


		@include media-breakpoint-up(sm) {
			padding: 0 floor(( $grid-gutter-width * 0.75 )) ($spacer * 0.5);
		}
	}


	.isExpanded {
		border-top: 1px solid $primary;
		background: rgba(243, 248, 251, 1);


		.accordion__button {
			color: $primary;
			padding: 0;


			&:before {
				transform: translateY(-50%) rotate(0);
				color: $primary;
			}


			button {
				padding: ($spacer * 0.5) 70px ($spacer * 0.5) 20px;

			}


			@include media-breakpoint-up(sm) {
				padding: 0;


				&:before {
					transform: translateY(-50%) rotate(0) scale(1.2);
				}


				button {
					padding: ($spacer * 1.25) floor(( $grid-gutter-width * 0.75 ));
					margin-left: 0;
				}
			}
		}


		& > .accordion__panel {
			position: relative;
			left: auto;
			opacity: 1;
			transform: translateY(0);
		}
	}


	.textrow {

		.text {

			p, li, h5 {
				font-size: $font-size-sm;
			}
		}
	}
}

