// ========================================
// Sitemap
// ========================================


// Genereal

.sitemap {
	display: flex;
	list-style: none outside;
	padding: 0;
	margin: 0;
}


// Level 1

.sitemap--level-1 {
	flex-direction: column;


	> li {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: ( $spacer * 1);


		@include media-breakpoint-up(sm) {
			margin-bottom: ( $spacer * 2);
		}
	}
}


// Level 2

.sitemap--level-2 {
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -15px;


	> li {
		padding: 0 15px;
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: ( $spacer * 2);


		@include media-breakpoint-up(sm) {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: ( $spacer * 3);
		}


		@include media-breakpoint-up(md) {
			flex: 0 0 33.3333%;
			max-width: 33.3333%;
		}


		@include media-breakpoint-up(lg) {
			flex: 0 0 25%;
			max-width: 25%;
		}
	}


	li {


		a {
			color: $text-color;
			text-decoration: none;


			&:hover,
			&:active {
				text-decoration: underline;
			}
		}
	}
}


// Level 3

.sitemap--level-3 {
	flex-direction: column;


	> li {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: ( $spacer * 1);


		&:last-of-type {
			margin-bottom: 0;
		}
	}
}



// Headlines

.sitemap__h1,
.sitemap__h2 {
	margin-bottom: 0;
}


.sitemap__h1 {
	border-top: 1px solid $gray-400;
	margin-bottom: 0;
	color: $blue;
	padding: ( $spacer * 2) 0;


	@include media-breakpoint-up(sm) {
		padding: ( $spacer * 3) 0;
	}
}


.sitemap__h2 {
	margin-bottom: ( $spacer * 1);
}


// Headline Icons

.sitemap__h1--informieren,
.sitemap__h1--leben,
.sitemap__h1--mitmachen {
	position: relative;
	padding-left: 32px;


	&:before {
		font-size: 28px;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(0, -55%);
	}


	@include media-breakpoint-up(sm) {
		padding-left: 48px;


		&:before {
			font-size: 36px;
			transform: translate(0, -55%);
		}
	}
}


.sitemap__h1--informieren {
	@extend .icon-navi-birne;
}


.sitemap__h1--leben {
	@extend .icon-navi-herz;
}


.sitemap__h1--mitmachen {
	@extend .icon-navi-sonne;
}
