// Body text
// -------------------------


p {
	font-size: $font-size-base;


	@include media-breakpoint-down(sm) {
		font-size: $font-size-sm;
		line-height: 1.6;
		margin-bottom: $spacer;
	}
}


b,
strong {
	font-weight: bold;
}


.textrow--text-overflow-hidden .textrow__text {
	overflow: hidden;
}

