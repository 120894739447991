quote,
blockquote {
	border: 0; // BT3 normalize
	padding: 0; // BT3 normalize
	margin: 0; // BT3 normalize
	position: relative;
	overflow: hidden;
	
	
	p {
		color: $primary;
		margin: 0 0 ($spacer * 1.5);
		
		
		@extend .icon-blockquote_start;
		
		&:before {
			padding-right: 40px;
		}
		
		
		&:after {
			content: "\f144";
			font-family: "iconfont";
			padding-left: 30px;
		}
	}
}




@include media-breakpoint-up(md) {

	.blockquote--left {
		width: 405px;
		float: left;
		padding-right: 30px;
		margin-left: -30px;
	}
	
	
	.blockquote--right {
		width: 420px;
		float: right;
		padding-left: 45px;
		margin-right: -90px;
	}
}