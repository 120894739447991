
.header__contact-shortcut {
	margin-left: ($spacer * 2);
	font-weight: $font-weight-bold;
	font-size: $font-size-xs;
	color: $black;
	padding-left: 55px;
	position: relative;
	min-width: 260px;
	transition: $transition-base;
	display: none;


	@include media-breakpoint-up(lg) {
    	display: inline-block;
	}


	&:before {
		font-size: 40px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		color: $blue;
		transition: $transition-base
	}


	&:hover,
	&:active {
		color: $blue;
		text-decoration: none;


		&:before {
			// color: $black;
			text-decoration: none;
		}
	}
}


.header__contact-shortcut--phone {
	@extend .icon-header-kontakt-telefon;
}


.header__contact-shortcut--email {
	@extend .icon-header-kontakt-email;
}


@media print {
	
	.header__contact-shortcut {
		display: none !important;
	}
}