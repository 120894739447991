
a[class^="icon-link"] {
	display: inline-block;
	position: relative;
	padding-left: floor(($grid-gutter-width * 1));


	&:before {
		position: absolute;
		left: 0;
		font-size: 22px;
	}


	&:hover,
	&:active,
	&:focus {


		&:before {
			text-decoration: none;
		}
	}


	@include media-breakpoint-up(lg) {
		padding-left: 36px;


		&:before {
			font-size: 28px;
		}
	}
}


a.icon-link-- {


	&intern {
		@extend .icon-link_intern;


		&:before {
			top: 4px;
			top: 1px;
		}
	}


	&extern {
		@extend .icon-link_extern;


		&:before {
			top: 4px;
			top: 2px;
		}
	}


	&mail {
		@extend .icon-mail;


		&:before {
			font-size: 18px;
			top: 4px;
			left: 3px;


			@include media-breakpoint-up(lg) {
				font-size: 24px;
				top: 3px;
				left: 3px;
			}
		}
	}


	&download {
		@extend .icon-link_download;


		&:before {
			top: 6px;
		}
	}


	&phone {
		@extend .icon-link_phone;


		&:before {
			font-size: 18px;
			top: 5px;
			
			@include media-breakpoint-up(lg) {
				font-size: 26px !important;
			}
		}
	}
}

