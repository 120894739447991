
.hero {
	//margin-top: -16px;

	.image__credits {
		right: auto;
		left: 0;
	}
}


.hero--banner {
	padding-top: 4.5rem;
	position: relative;
	height: 100%;


	figure,
	.image {
		height: 100%;
	}


	.container {
		position: relative;
	}


	.hero__image {
		background: $gray-500;
		overflow: hidden;
		margin-top: -4.5rem;
		height: 320px;
	}


	.hero__heading {
		background: white;
		margin-bottom: 0;
		padding: 16px 20px;
		display: inline-block;
		position: absolute;
		z-index: 1;
		right: 0;
		bottom: -32px;
		color: $primary;
		font-size: 1.25rem;


		@include media-breakpoint-up(lg) {
			padding: 24px 30px;
			font-size: 1.75rem;
			bottom: -40px;
		}


		&:before {
			content: "";
			position: absolute;
			left: 0;
			top:0;
			bottom: 0;
			width: 2000em;
			background: white;
			box-shadow: 0 0 20px rgba(0,0,0,.2);
			z-index: -1;
		}
	}


	.hero__image {
		background: $gray-500;
		height: ($spacer * 15);
		width: 100%;
		overflow: hidden;


		@include media-breakpoint-up(sm) {
			height: ($spacer * 20);
		}


		@include media-breakpoint-up(md) {
			height: ($spacer * 20);
		}


		@include media-breakpoint-up(lg) {
			height: ($spacer * 20);
		}


		@include media-breakpoint-up(xl) {
			height: ($spacer * 20);
		}


		picture {
			width: auto;
			height: 100%;

			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			@include media-breakpoint-up(xxl) {
// 				width: 100%;
// 				height: auto;
			}


			img {
				display: block;
				width: auto;
				height: 100%;
				max-width: initial;


				@include media-breakpoint-up(xxl) {
					// width: 100%;
					// height: auto;
				}
			}
		}


		// @media (min-width: 1320px) {
		@media (min-width: 1320px) {

			picture {
				width: 100%;
				height: auto;


				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}







.hero--stage {

	.slick-list {
		margin-bottom: 0;


		@include media-breakpoint-up(md) {
			margin-bottom: ($spacer * 1);
		}
	}


	.hero__item {
		position: relative;


		@include media-breakpoint-up(sm) {
			height: ($spacer * 30);
		}


		@include media-breakpoint-up(md) {
			height: ($spacer * 32);
		}


		@include media-breakpoint-up(lg) {
			height: ($spacer * 34);
		}


		@include media-breakpoint-up(xl) {
			height: ($spacer * 36);
		}
	}


	.hero__item--right > .container > .row {
		justify-content: flex-end;
	}


	.hero__item--left > .container > .row {
		justify-content: flex-start;
	}


	.hero__image {
		background: $gray-500;
		// height: 544px;
		height: ($spacer * 15);
		width: 100%;
		overflow: hidden;
		position: absolute;


		@include media-breakpoint-up(sm) {
			height: ($spacer * 30);
		}


		@include media-breakpoint-up(md) {
			height: ($spacer * 32);
		}


		@include media-breakpoint-up(lg) {
			height: ($spacer * 34);
		}


		@include media-breakpoint-up(xl) {
			height: ($spacer * 36);
		}


		picture {
			width: auto;
			height: 100%;

			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);


			@include media-breakpoint-up(xxl) {
				width: 100%;
				height: auto;
			}


			img {
				display: block;
				width: auto;
				height: 100%;

				@include media-breakpoint-up(xxl) {
					width: 100%;
					height: auto;
				}
			}
		}
	}


	.container {
		height: 100%;
		display: flex;
		align-items: center;
	}


	.teaser__item {
		margin-top: 33.333%;


		@include media-breakpoint-up(sm) {
			margin-top: 0;
		}


		.teaser__wrap {
			background-color: rgba(white, 0.8 );
			transition: $transition-base;

			&:hover,
			&:active {
				background-color: rgba(white, 1 );
			}
		}


		.teaser__subcategory {
			/*
			color: $text-color !important;


			&:hover,
			&:active {
				color: $text-color !important;
			}
			*/

			&:before {
				background: none;
			}
		}


		.teaser__category--informieren,
		.teaser__category--mitmachen,
		.teaser__category--aus-dem-leben,
		.teaser__subcategory {
			color: $primary !important;
		}

		.teaser__title,
		.teaser__text,
		.teaser__text p {
			color: $text-color !important;
		}

		& > a {

			&:hover,
			&:active {
				.teaser__category--informieren,
				.teaser__category--mitmachen,
				.teaser__category--aus-dem-leben,
				.teaser__subcategory {
					color: $primary !important;
				}
				.teaser__title,
				.teaser__text,
				.teaser__text p {
					color: $text-color !important;
				}
			}
		}

		.teaser__read-more {
			color: $blue;
			background: $gray-100;
			border-color: $gray-100;
		}


		@each $key, $value in $areas {

			&.teaser__item--#{$key} {

				.teaser__wrap {
					background-color: rgba( $value, .75 );

					&:hover,
					&:active {
						background-color: rgba( $value, 1 );
					}
				}

				.teaser__category--informieren,
				.teaser__category--mitmachen,
				.teaser__category--aus-dem-leben,
				.teaser__subcategory,
				.teaser__title,
				.teaser__text,
				.teaser__text p {
					color: white !important;
				}

				& > a {

					&:hover,
					&:active {
						.teaser__category--informieren,
						.teaser__category--mitmachen,
						.teaser__category--aus-dem-leben,
						.teaser__subcategory,
						.teaser__title,
						.teaser__text,
						.teaser__text p {
							color: white !important;
						}
					}
				}

				.teaser__read-more {
					color: white;
					background-color: $value;
					border-color: $value;
				}
			}
		}
	}
}



// hide hero content until carousel init

.hero--stage {
	visibility: hidden;


	&.slick-initialized {
		visibility: visible;
	}
}


@each $key, $value in $areas {

	.page-type--#{$key},
	.hero--#{$key} {


		&.hero--banner .hero__heading {
			color: $white;


			&:before {
				background: $value;
			}
		}
	}
}

// produces more meaningful class names

@each $key, $value in $area-colors {

	.page-type--#{$key},
	.hero--#{$key} {


		&.hero--banner .hero__heading {
			color: $white;


			&:before {
				background: $value;
			}
		}
	}
}


// Pause button for hero strage

.hero__play {
	position: absolute;
	bottom: 60px;
	left: 30px;
}

