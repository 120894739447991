// Checkout Pager
// ==========================================


.checkout-pager {

	button,
	a {
		width: 100%;


		@include media-breakpoint-up(sm) {
			width: auto;


			&:first-of-type {
				margin-bottom: 0;

			}
		}


		&:first-child {
			margin-bottom: floor(($spacer * .5));
		}


		&:last-child:nth-of-type(2) {
			float: none;

			@include media-breakpoint-up(sm) {
				float: right;
			}
		}
	}
	a:first-of-type {
		float: left;
	}
	button,
	a {
		float: right;
	}
	.btn.disabled,
	.btn:disabled {
	    cursor: not-allowed;
	}

}
