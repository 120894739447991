
.follow {
	list-style: none outside;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;


	@include media-breakpoint-up(sm) {
		justify-content: flex-start;
	}
}


.follow__item {
	height: 40px;
	width: 40px;
	margin: 0 $spacer;


	.follow__link {
		display: block;
		height: 40px;
		width: auto;
		font-size: 40px;
		line-height: 40px;
		transition: $transition-base;


		&:hover,
		&:active,
		&:focus {
		 	text-decoration: none;
		 	color: $blue-light;
		}
	}
}


.follow__link--facebook {
	@extend .icon-facebook;
}


.follow__link--twitter {
	@extend .icon-twitter;
}


.follow__link--youtube {
	@extend .icon-youtube;
}

