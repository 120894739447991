
.shop-item {
	padding: ($spacer * 2) 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: $border-base;
	flex-wrap: wrap;


	@include media-breakpoint-up(sm) {
		flex-wrap: nowrap;
	}


	&:first-of-type {
		border-top: $border-base;
	}


	&:last-of-type {
		margin-bottom: ($spacer * 4);
	}
}


.shop-item__image {
	flex: 1 0 160px;
	max-width: 160px;
	margin-bottom: ($spacer * 1.5);
	margin-right: 0;


	@include media-breakpoint-up(sm) {
		margin-right: ($spacer * 1);
	}


	@include media-breakpoint-up(md) {
		flex: 1 0 200px;
		max-width: 200px;
	}


	@include media-breakpoint-up(lg) {
		flex: 1 0 270px;
		max-width: 270px;
	}


	@include media-breakpoint-up(lg) {
		margin-right: ($spacer * 2);
	}
}


.shop-item__body {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
}


.shop-item__description {
	margin-bottom: ($spacer * 0.5);
}


.shop-item__pricing {
	display: flex;
	flex-direction: column;
	padding-top: ($spacer * 1);
	margin-bottom: ($spacer * 1);
}


.pricing__price {
	font-size: $font-size-md;
	font-weight: $font-weight-bold;
}


.pricing__price--original {
	text-decoration: line-through;
}


.pricing__price-info {
	font-size: $font-size-xs;
}


.shop-item__more-link {
	font-weight: 700;
	align-self: flex-start;
}


.shop-item__put-to-cart {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: stretch;
	margin-bottom: 0;
	position: relative;
	transition: $transition-base;


	label.error {
		flex: 0 0 100%;
		max-width: 100%;
	}
}


.put-to-cart__qty-input {
	width: 46px;
	margin-right: ($spacer);
	padding-left: 0;
	padding-right: 0;
	text-align: center;
}



.shop-item--publication {


	.image__link {
		display: block;
	}


	.publication-wrap {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $gray-200;
		padding-right: 0;
		position: relative;
		height: 270px;


		.image {
			padding: 0 ($spacer * 1.75);


			@include media-breakpoint-up(lg) {
				padding: 0 ($spacer * 2.5);
			}
		}


		.shadow-wrap {
			position: relative;
			display: block;


			&:before {
				content: "";
				position: absolute;
				right: -10px;
				bottom: 0;
				width: 20px;
				height: 20px;
				background: #c6c6c6;
				transform: skewX(-45deg);


				@include media-breakpoint-up(lg) {
					right: -15px;
					width: 30px;
					height: 30px;
				}
			}
		}


		img {
			position: relative;
			z-index: 1;
		}
	}
}
