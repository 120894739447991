
$squared-link-item-size: ($font-size-base * 3);
$squared-link-item-margin: ($spacer * 1);


.squared-links__list {
	display: flex;
	flex-wrap: wrap;
	list-style: none outside;
	margin: 0 ($squared-link-item-margin * -0.5);
	padding: 0;
}


.squared-links__item {
	height: $squared-link-item-size;
	width: $squared-link-item-size;
	margin: ($squared-link-item-margin / 2);
	overflow: hidden;


	&.is-active {

		a,
		span {
			color: $white;
			background: $blue;

			&:hover,
			&:active,
			&:focus {
				color: $white;
				background: $blue;
			}
		}
	}

	&.is-disabled {

		a,
		span {
			color: $gray-400;
			background: $gray-200;

			&:hover,
			&:active,
		&:focus {
				color: $gray-400;
				background: $gray-200;
			}
		}
	}


	a,
	span {
		color: $blue;
		background: $gray-200;
		display: block;
		height: 100%;
		text-align: center;
		font-weight: $font-weight-bold;
		line-height: $squared-link-item-size;


		&:hover,
		&:active,
		&:focus {
			background: $gray-400;
			text-decoration: none;
		}
	}
}
