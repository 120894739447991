
.featured-item {
	margin: 0;
	background: $blue-lighter;
	position: relative;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	// flex-wrap: nowrap;


	@include media-breakpoint-up(sm) {
		flex-wrap: nowrap;
	}
}


.featured-item__image {
	flex: 1 0 100%;
	max-width: 100%;
	margin: 0;


	@include media-breakpoint-only(sm) {
		padding: ($spacer * 1) 0 ($spacer * 1) ($spacer * 1);
	}

	@include media-breakpoint-only(md) {
		padding: ($spacer * 2) 0 ($spacer * 2) ($spacer * 2);
	}


	@include media-breakpoint-up(sm) {
		flex: 1 0 40%;
		max-width: 40%;
		margin-right: ($spacer * 1);
	}


	@include media-breakpoint-up(lg) {
		margin-right: ($spacer * 2);
	}
}


.featured-item__body {
	flex: 1 1 100%;
	max-width: 100%;
	margin: 0;
	padding: ($spacer * 2) ($spacer * 1) ($spacer * 4) ($spacer * 1);


	@include media-breakpoint-up(sm) {
		flex: 1 1 60%;
		max-width: 60%;
		padding: ($spacer * 1) ($spacer * 1) ($spacer * 2.5) 0;
	}

	@include media-breakpoint-up(md) {
		padding: ($spacer * 2) ($spacer * 1) ($spacer * 2.5) 0;
	}


	@include media-breakpoint-up(lg) {
		padding: ($spacer * 2) ($spacer * 2) ($spacer * 2.5) 0;
	}
}


.featured-item__title {
	font-size: $font-size-medium;
	font-weight: 600;
	margin-bottom: ($spacer * 0.5);


	@include media-breakpoint-up(md) {
		font-size: $font-size-large;
		margin-bottom: ($spacer * 1);
	}
}


.featured-item__description {
	margin-bottom: ($spacer * 0);

	@include media-breakpoint-down(xs) {
		display: none;
	}
}


.featured-item__link {
	position: absolute;
	right: 0;
	bottom: 0;
}




// Featured Publication

.featured-item--publication {


	.image__link {
		display: block;
	}


	.publication-wrap {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $gray-300;
		padding-right: 0;
		position: relative;
		// height: ($spacer * 23);
		height: 388px;


		.image {
			padding: 0 ($spacer * 1.75);


			@include media-breakpoint-up(lg) {
				padding: 0 ($spacer * 2.5);
			}
		}


		.shadow-wrap {
			position: relative;
			display: block;


			&:before {
				content: "";
				position: absolute;
				right: -10px;
				bottom: 0;
				width: 20px;
				height: 20px;
				background: #c6c6c6;
				transform: skewX(-45deg);


				@include media-breakpoint-up(lg) {
					right: -15px;
					width: 30px;
					height: 30px;
				}
			}
		}


		img {
			position: relative;
			z-index: 1;
		}
	}
}


// Carousel

.carousel--featured-item {
	margin-left: 0 !important;
	margin-right: 0 !important;
}



