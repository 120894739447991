$input-hover-border-color:			$input-focus-border-color;
$input-font-weight: 				$font-weight-bold;
$form-label-font-size:				$font-size-base;
$form-label-color: 					$text-color;
$form-label-font-weight: 			$font-weight-normal;


.form--highlight {
	background: #e6f3fc;
	padding: 1.5rem 30px;
}


.form-control {
	font-weight: $input-font-weight;


	&:hover,
	&:active,
	&:focus {
		border-color: $input-hover-border-color;
	}


	&.is-disabled,
	&:disabled,
	&[readonly] {
		cursor: not-allowed;


		&:hover,
		&:active,
		&:focus {
			border-color: $input-border-color;

		}
	}
}


.form-group {
	margin-bottom: ($spacer * 1.5);
}


.form-group--has-no-label {


	@include media-breakpoint-up(sm) {
		text-align: left;
		padding-top: 1.75rem;
	}
}





// Help text & validation error text

.form-text,
.form-description,
.form-group label.error {
	display: block;
	font-size: 0.875rem;
	padding-top: 0.25rem;
	// margin-top: 0.5rem;
	// margin-bottom: 0.25rem;
}


// Help text

.form-text,
.form-description {
	font-style: italic;
	color: $text-color;
}


// Validation error text

.form-group label.error {
	color: $danger;
	font-weight: 600;
	margin-bottom: 0;
	// font-size: $font-size-xs;
	// line-height: 20px;
	// margin-top: -1px;
	// background: $danger;
}


// Validation error text - checkbox groups

.checkbox__error-container label.error {
    margin-top: -0.5rem;
	// top: -5px;
    // position: relative;
    // color: #e53b20;
    // font-size: 0.75rem;
    // line-height: 20px;
}


// Validation error input

.form-control.error {
	color: $danger;
	border-color: $danger;

	&:hover,
	&:active,
	&:focus {
		border-color: $danger;
		box-shadow: 0 5px 0 $danger;
	}
}


// Validation error input  - checkbox groups

.checkbox__error-container {

	.form-control.error {

		&:hover,
		&:active,
		&:focus {
			box-shadow: none;
			border: 0;
			// border-color: transparent;
		}
	}
}



/*

label.error {
    position: absolute;
	color: ($red);
    top: 65px;
    text-align: left;
	font-size: $font-size-xxs;
}
*/



// Label

.form-label,
.form-check-label,
.legend-for-label,
.form-group label,
legend {
	font-size: $form-label-font-size;
	font-weight: $form-label-font-weight;
	color: $form-label-color;
	display: inline-block;
	margin-bottom: .25rem;


	&.form-label--strong,
	&.form-check-label--strong,
	&.legend-for-label--strong {
		font-weight: $font-weight-bold;
		color: $blue;
	}
}


.legend-for-label {
	margin-bottom: ($spacer * 1)
}






// Placeholder Text

.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-webkit-input-placeholder {
    color: $text-color;
    font-weight: 300;
    font-style: italic;


    &.big-search__input {
	    color: $blue;
		font-weight: 700;
		font-style: normal;
    }
}


.form-inline .form-group {
	margin-right: ($spacer * 2);
	display: inline;
}











/*
.input-group-append {

	.btn {
		border-color: transparent;
		background-color: transparent;
	}
}
*/

/*
.form-actions {
	margin-bottom: floor((@line-height-computed * 1));
}
*/


/*
.form-fieldset,
.form fieldset {
	margin-bottom: floor((@line-height-computed * 2));
}
*/



/*
.form-group {
	margin-bottom: floor((@line-height-computed * 0.75));
	position: relative;
}
*/



/*
.form-inline .form-group {
	margin-right: floor((@line-height-computed * 0.75));
	vertical-align: top;
	margin-bottom: floor((@line-height-computed * 0.75));
	position: relative;
}
*/


/*
.form-inline .control-label {
	margin-bottom: 5px;
}
*/


/*
.form-group--columns {


	@media (min-width: @screen-sm-min) {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
	}


	@media (min-width: @screen-md-min) {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
	}
}
*/




// Legend

/*
legend,
.form__legend {
	border-top: 5px solid @brand-primary-light;
	padding-top: floor((@line-height-computed * 0.5));
	border: 0;
	color: @brand-primary;
	margin-bottom: floor((@line-height-computed * 1));
	font-size: @font-size-h4-mobile;
	font-family: @headings-font-family;
	font-weight: 300;
	line-height: @headings-line-height;


	@media (min-width: @screen-sm-min) {
		font-size: @font-size-h4;
	}
}
*/




/*
.input-group-addon {
    padding: 6px 15px;
    font-size: 14px;
    color: #9395ab;
    background-color: #f5f6fa;
    border: 1px solid #e0e3ef;
    border-radius: 0;
}
*/



// Labels

/*
.form {

	label {
		font-size: @font-size-small;
		line-height: 1.4;
		font-weight: 300;
		color: @brand-primary-dark;
	}
}


.form-group--has-no-label {
	text-align: right;
	padding-top: floor((@line-height-computed * 0.25));


	@media (min-width: @screen-sm-min) {
		text-align: left;
		padding-top: 32px;
	}
}
*/




// Fields

/*
input.form-control,
select.form-control,
textarea.form-control {
	height: 44px;
	padding: 6px 15px;
	font-size: @font-size-small;
	box-shadow: none;
	border-radius: 0;
	border: 1px solid @brand-primary-light;
	color: @text-color;


	&:hover {
		box-shadow: 0 5px 0 @brand-primary-light;
	}


	&:focus {
		border-color: @brand-primary-light;
		outline: 0;
		box-shadow: 0 5px 0 @brand-primary-light;
	}
}


textarea.form-control {
	resize: vertical;
	min-height: 198px;
}


textarea.textarea--rte {
	min-height: 298px;
}


@media (max-width: @screen-sm-min) {

	input,
	textarea,
	select {
		font-size: 16px !important; // do not scroll into form-element on mobile devices
	}
}
*/















// Table Overview

.form .table-responsive {


	.table {
		border-bottom: 1px solid #f0ecd5;
	}


	.table > thead > tr > th,
	.table > tbody > tr > th,
	.table > tfoot > tr > th,
	.table > thead > tr > td,
	.table > tbody > tr > td,
	.table > tfoot > tr > td {
		padding: 16px 0;
		border-color: #f0ecd5;
		// font-size: @font-size-base;
		font-weight: 300;


		&.summary-table-first-col {
			width: 30%;
			font-weight: 600;
		}
	}
}




// Pflichtfeld

/*
.form-group--mandatory {


	p {
		font-size: @font-size-xs;
		font-weight: 400;
		color: #b4b4b5;
	}
}
*/




// Form Navigation

/*
.form-navigation {
	margin-bottom: floor((@line-height-computed * 1));


	@media (min-width: @screen-sm-min) {
		margin-top: floor((@line-height-computed * 1));
	}


	.btn-toolbar {
		margin-left: 0;


		.btn {
			margin-left: 20px;
		}


		.btn-cancel {
			padding: 10px 40px;
			font-size: 14px;
			line-height: 26px;
			color: #b4b4b5;
			font-weight: 400 !important;


			&:hover {
				color: @text-color;
			}
		}
	}


	.form-group--mandatory {
		float: none;


		@media (min-width: @screen-sm-min) {
			float: left;


			p {
				margin-bottom: 0;
				line-height: 50px;
			}
		}
	}
}
*/






/*
.btnSeperatorText {
	font-size: @font-size-xs;
	color: @brand-primary;
	position: relative;
	display: inline-block;
	padding: 0 40px;


	&:before {
		content: "";
		position: absolute;
		left: 10px;
		right: 10px;
		top: 50%;
		height: 1px;
		z-index: -2;
		background: @brand-primary;
	}


	&:after {
		content: "";
		position: absolute;
		left: 30px;
		right: 30px;
		top: 0;
		bottom: 0;
		z-index: -1;
		background: white;
	}
}
*/



.multiple__item {
	position: relative;
}


.multiple__delete {
	position: absolute;
	right: 0;
	top: 0;
	// width: 40px;
	// text-indent: -10000em;
	// font-size: 2rem;
	color: $blue-darker;
	cursor: pointer;
	@extend .icon-delete;


	&:before {
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		text-indent: 0;
		text-align: center;
	}


	&:hover {
		color: $orange;
	}
}

.multiple__add {
	margin-top: -1.5rem;
}







// Powermail Form

.form--powermail {

	/*
	.form__heading {
		display: none;
	}
	*/



	/* Hide HTML5 Up and Down arrows. */

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
	    -webkit-appearance: none;
	    margin: 0;
	}

	input[type="number"] {
	    -moz-appearance: textfield;
	}




	/* Mehrstufige Formulare */

	.btn-group {
		margin-bottom: 40px;
		border-bottom: 1px solid #ccc;


		.btn {
			margin-bottom: -1px;
			margin-left: -1px;


			&:first-of-type {
				margin-left: 0;
			}
		}
	}


	.powermail_tab_navigation {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 15px;
		position: relative;
		width: 100%;
		float: left;


		&:before {
		    content: "";
		    top: 0;
		    left: 15px;
		    right: 15px;
		    position: absolute;
		    display: block;
		    background: url(../images/bg_dots_xs.png) left top repeat-x;
			height: 2px;
		}
	}

}




// später mit cols !!!

.form__group {
	@extend .form-group;
	/*
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
	float: left;
	width: 100%;

	&.layout1 {
		float: left;
		width: 50%;
	}


	&.layout2 {
		float: left;
		width: 33.33333333%;
	}


	&.layout3 {
		float: left;
		width: 25%;
	}
	*/


	label.error {
		// display: block;
	}


	label p {
		margin: 0;
		display: inline;
	}
}





.form__description {
	color: #b4b4b5;
	font-size: 15px;
	// display: block;
}




.form__element {
	@extend .form-control;
}



// Captcha

.form__captchaimage {
	padding-right: 20px;
	float: left;
	height: 42px;
	width: auto;
}


.form__element--captcha {
	float: left;
	// width: 280px;
	// height: 53px;
	width: 80px;
	text-align: center;
	font-weight: 700;
}



.table-striped {
	margin-bottom: 15px;
}


.table-striped > tbody > tr:nth-of-type(2n+1) {
	background: url(../images/bg_pattern.png) center center repeat;
}

.table-hover > tbody > tr:hover {
    background-color: transparent;
}


.form__confirmation-navigation {
    padding-top: 15px;
    position: relative;
}

.form__confirmation-navigation:before {
    content: "";
    top: 0;
    left: 15px;
    right: 15px;
    position: absolute;
    display: block;
    background: url(../images/bg_dots_xs.png) left top repeat-x;
    height: 2px;
}