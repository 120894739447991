
.facebookstream {
	padding-top: ($spacer * 2);
	padding-bottom: ($spacer * 2);
	background: #fff;
	@include wide-bg(#fff);


	&:before {
		z-index: -1;
	}
}


.facebookstream__title {
	margin-left: ($spacer * 1);
	color: $blue;
	display: flex;
	align-items: center;
	flex-shrink: 0;
		flex-wrap: nowrap;
	max-width: 100%;
	margin-bottom: ($spacer * 2);


	&:before {
		border-radius: 50%;
		background: $blue;
		color: #fff;
		text-align: center;
		display: inline-block;
		width: ($spacer * 2);
		height: ($spacer * 2);
		margin-right: ($spacer * 0.5);
		line-height: ($spacer * 2);
		font-size: 1.125rem;


		@include media-breakpoint-up(md) {
			width: ($spacer * 4);
			height: ($spacer * 4);
			margin-right: ($spacer * 1);
			line-height: ($spacer * 4);
			font-size: 2.25rem;
		}
	}
}


.facebookstream__item {
	padding: 0 ($spacer * 1);


	.media {
		margin: 0;
		
		.image img {
			max-height: 320px;
			width: auto;
		}
	}


	.item__meta {
		font-size: $font-size-xs;
		color: $gray-600;
		margin-bottom: ($spacer * 0.5 );
		display: flex;
		flex-wrap: wrap;
	}


	.item__date {
		margin-right: ($spacer * 1);
		flex-shrink: 0;
	}


	.item__share {
		display: inline-block;


		&:hover {
			color: $blue-darker;
			text-decoration: none;
		}


		&:before {
			font-size: $font-size-lg;
		}
	}


	.item__text {
		margin-bottom: 0;


		&:last-of-type {
			margin-bottom: ($spacer * 0.5 );
		}
	}


	.item__more-link {
		font-weight: 700;
		color: $blue;
	}
}


.facebookstream__footer {
	display: flex;
	justify-content: center;
	margin-top: 48px;


	@include media-breakpoint-up(md) {
		justify-content: flex-end;
		margin-top: 0;
	}


	.btn {
		z-index: 99999;
		padding-right: 3rem !important;

		
		&:before {
			font-size: 1.5rem !important;
		}
	}
}


.facebookstream__slider {
	width: 100%; // slick slider inside flexbox container fix
	margin-bottom: ($spacer * 2);


	.slick-dots {
		transform: translateY(60px);
		position: relative;
		z-index: 9;
	}
}































	/*
	.btn {
		border: 1px solid #3b5998;
		color: #3b5998;
		background: transparent;
		text-align: left;
		padding: 12px floor(( @grid-gutter-width * 3 )) 12px floor(( @grid-gutter-width * 1 ));
		position: relative;


		i {
			background: #3b5998;
			color: white;
			width: 50px;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			display: block;
			font-size: 36px;
			line-height: 50px;
			text-align: center;


			&:before {
				transform: translate(20px,-50%);
				top: 50%;
				position: absolute;
				left: 0;
				font-size: 26px;
			}
		}


		&:hover {
			background: #3b5998;
			color: white;
		}
	}


	.slick-dots {
		padding: 0;
		text-align: center;
		margin-bottom: 0;
		position: absolute;
		bottom: -150px;
		left: 50%;
		transform: translateX(-50%);


		@media (max-width: @screen-sm-min) {
			bottom: -180px;
		}


		li {
			display: inline;
			padding: 0 4px !important;


			&:before {
				display: none;
			}


			button {
				border: 0;
				background: @brand-primary-lighter;
				box-shadow: none !important;
				width: auto;
				padding: 0;
				border-radius: 50%;
				height: 12px;
				width: 12px;
				text-indent: -10000em;
				-webkit-backface-visibility:hidden;
				-webkit-transition:background .2s ease-in-out;
				-moz-transition:background .2s ease-in-out;
				-o-transition:background .2s ease-in-out;
				-ms-transition:background .2s ease-in-out;
				transition:background .2s ease-in-out;
			}


			&:hover,
			&:focus,
			&:active,
			&.slick-active {

				button {
					background: @brand-primary;
				}
			}
		}
	}
	*/
