
.address__item {
	position: relative;
	padding-left: 30px;
	@extend .icon-pin;
	
	
	&:before {
		position: absolute;
		left: 0;
		color: $blue;
		top: 2px;
		font-size: 22px;
	}
	
	
	p,
	address {
		font-size: $font-size-sm;
		margin-bottom: ($spacer * 1);
	}
}