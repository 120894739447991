


// Mixins
// =========================================================


.border-bottom-highlight {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		height: 1px;
		width: 30px;
		background: $gray-500;
	}
}


.cart-col--product {
	float: none;
	width: 100%;

	@include media-breakpoint-up(sm) {
		float: left;
		width: 65%;
	}
}


.cart-col--quantity {
	float: left;
	width: 50%;

	@include media-breakpoint-up(sm) {
		width: 20%;
	}
}


.cart-col--total {
	float: left;
	width: 50%;

	@include media-breakpoint-up(sm) {
		width: 15%;
	}
}


// General shop styles
// =========================================================





.shop-heading {
	color: $primary;
	border-bottom: 5px solid $blue-light;
}


h2.shop-heading {
	font-size: 25px;
	padding-bottom: ($spacer * 0.5);
	margin-bottom: ($spacer * 0.5);
}


h3.shop-heading {
	font-size: 20px;
	padding-bottom: ($spacer * 0.5);
	margin-bottom: ($spacer * 1);
}









// Checkout
// =========================================================



.cart__header {


	@include media-breakpoint-up(sm) {
		display: block;
		// @extend .clearfix;
		border-bottom: 1px solid $gray-300;
	}
}


.cart__header > div {
	display: none;


	@include media-breakpoint-up(sm) {
		display: block;
		color: $gray;
		font-size: $font-size-sm;
		padding-bottom: ($spacer * 0.5);
	}
}


.cart__header__product {
	@extend .cart-col--product;
	@extend .border-bottom-highlight;
}


.cart__header__quantity {
	@extend .cart-col--quantity;
	@extend .border-bottom-highlight;
}


.cart__header__total {
	@extend .cart-col--total;
	@extend .border-bottom-highlight;
	text-align: right;


	&:before {
		left: auto;
		right: 0;
	}
}





// Cart Items


.cart__item {
	@extend .clearfix;
	border-bottom: 1px solid $gray-500;
	padding: ($spacer *1) 0 0 0;


	@include media-breakpoint-up(sm) {
		padding: ($spacer *1) 0 ($spacer * 1) 0;
	}


	&:first-of-type {
		border-top: 5px solid $blue-light;


		@include media-breakpoint-up(sm) {
			border-top: 0;
		}
	}


	&:last-of-type {
		// border: none;
	}


	& + .subdivision {
		border-top: none;
	}


	&.cart__item--papierversion {

		.product__image {
			background-image: url('../images/shop--papierversion.svg');
		}
	}


	&.cart__item--cd-single-user {

		.product__image {
			background-image: url('../images/shop--cd-single-user.svg');
		}
	}


	&.cart__item--cd-site-license {

		.product__image {
			background-image: url('../images/shop--cd-site-license.svg');
		}
	}


	&.cart__item--pdf-single-user {

		.product__image {
			background-image: url('../images/shop--pdf-single-user.svg');
		}
	}


	&.cart__item--pdf-site-license {

		.product__image {
			background-image: url('../images/shop--pdf-site-license.svg');
		}
	}


	&.cart__item--filme-fed {

		.product__image {
			background-image: url('../images/shop--filme-fed.svg');
		}
	}


	&.cart__item--filme {

		.product__image {
			background-image: url('../images/shop--filme.svg');
		}
	}
}


.cart__item__product {
	@extend .clearfix;
	@extend .cart-col--product;
	padding-bottom: ($spacer * 1);


	@include media-breakpoint-up(sm) {
		padding-bottom: 0;
		padding-right: floor(( $grid-gutter-width * 1 ));
	}


	.product__image {
		width: floor(( $grid-gutter-width * 2.5 ));
		margin-right: floor(( $grid-gutter-width * .5 ));
		float: left;

		width: 90px;
		height: 90px;
		background-size: contain;


		@include media-breakpoint-up(sm) {
			width: floor(( $grid-gutter-width * 3 ));
			margin-right: floor(( $grid-gutter-width * 1 ));
		}
	}


	.product__text {
		overflow: hidden;
	}


	.product__text__deckhead {
		color: $gray;
		margin-bottom: ($spacer * .125);
		font-size: $font-size-xs;
	}


	.product__text__title {
		margin-bottom: ($spacer * .25);


		a {
			font-weight: 700;
			color: $primary;
			font-size: $font-size-base;
			text-decoration: none;
		}
	}


	.product__text__description {
		margin: 0;
		font-size: $font-size-xs;


		@include media-breakpoint-up(sm) {
			font-size: $font-size-sm;
		}
	}
}






//  Item quantity


.cart__item__quantity {
	@extend .cart-col--quantity;


	span:first-of-type {
		padding-right: floor(( $grid-gutter-width * 0.5 ));
		height: 40px;
		display: inline-block;
		vertical-align: baseline;
		font-size: $font-size-xs;

		@include media-breakpoint-up(sm) {
			padding-right: 0;
			//@extend .text-hide;
		}
	}


	&.cart__item__quantity--no-edit {

		span + span {
			@extend .cart__item__amount;
			width: 50%;
			text-align: left;
			float: none;
			height: 40px;
			display: inline-block;


			@include media-breakpoint-up(sm) {
				width: 70%;

			}
		}
	}
}


.item__quantity__input {
	height: 40px;
	width: 40px;
	border: none;
	border-bottom: 1px solid rgb(210, 210, 209);
	background: #f4f3f1;
	text-align: center;
	color: $primary;
	font-weight: 700;
	font-size: $font-size-xs !important; // TODO: Fix declaration in "forms.less", so no "!important" is needed
	display: inline-block;
	vertical-align: baseline;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;


	@include media-breakpoint-up(sm) {
		height: 60px;
		width: 60px;
		font-size: $font-size-base;
		font-weight: 700;
	}


	&:focus {
		outline: none;
		color: #696;
		background: #eff5ef;
		border-bottom: 1px solid #696;
	}
}






// Quantity reset


.item__quantity__reset {
	height: 40px;
	width: 40px;
	position: relative;
	float: right;
	transition: all .2s ease-in-out;
	display: inline-block;
	vertical-align: top;
	float: none;
	color: $gray;
	text-decoration: none;


	@include media-breakpoint-up(sm) {
		height: 60px;
		float: right;
	}


	&:hover,
	&:focus,
	&:active {
		color: $primary;
		text-decoration: none;
	}


	&:before {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		// &:extend(.icon-trash:before);
	}


	span {
		@extend .text-hide;
	}
}


.cart__item__amount,
.total__item__amount {
	@extend .cart-col--total;
	text-align: right;
	font-weight: 400;
	font-size: $font-size-base;
}


.cart__item__amount {
	padding-top: 7px;


	@include media-breakpoint-up(sm) {
		padding-top: 20px;
	}
}






// Reload cart button


.reload-cart {
	padding: 0;
	text-align: right;
	transition: all .2s ease-in;


	.reload-cart__btn {
		transition: visibility 0, opacity .3s ease-in;
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
	}


	&.cart-has-changed {
		padding: ($spacer * .5) 0 ($spacer * 1.5) 0;


		.reload-cart__btn {
			visibility: visible;
			opacity: 1;
			pointer-events: auto;
		}
	}
}









// Cart Total


.total {
	@extend .clearfix;
	border-top: 5px solid $blue-light;
	font-size: $font-size-small;
	text-align: right;
}


.total__item {
	@extend .clearfix;
	border-bottom: 1px solid $gray-500;
	padding: ($spacer * .5) 0;
}


.total__item__label {
	width: auto;
	float: left;
	font-weight: 400;
	color: $gray-500;
	text-align: left;


	@include media-breakpoint-up(sm) {
		width: 85%;
		text-align: right;
	}


	span {
		font-size: $font-size-sm;
		font-weight: 300;
		color: $gray;
		display: block;


		@include media-breakpoint-up(sm) {
			display: inline;
		}
	}
}


.total__item__amount {
	width: auto;
	float: right;


	@include media-breakpoint-up(sm) {
		width: 15%;
		float: left;
	}
}


.total__item--grand-total {
	border-bottom: 5px double $primary;


	.total__item__label {
		font-size: $font-size-base;
		font-weight: 700;
		color: $primary;


		span {
			font-size: $font-size-small;
			color: $primary;
		}
	}


	.total__item__amount {
		font-weight: 700;
		color: $primary;
	}
}






// Versandwahl und Zahlungsmethode
.order-options--overview {


	p,
	span {
		font-size: $font-size-small;
	}


	p {
		margin-bottom: ($spacer * .5);
	}


	.h4,
	.h5 {
		margin-bottom: ($spacer * .5);
		font-size: $font-size-small;
	}


	.order-options__item {
		border-bottom: 1px solid $gray-100;
		padding-top: ($spacer * 1);
		padding-bottom: ($spacer * .5);
	}
}


.order-options__item {

	label {
		font-size: $font-size-small;
	}
}


.order-options__notice {
	font-style: italic;
	color: $gray;
}








// Checkout Steps
// ==========================================


.checkout-steps {


	// disabling button behaviour while maintaining button styling

	.btn {
		cursor: default;


		&:hover,
		&:focus,
		&:active {
			color: white;
			background: $primary;
		}


		&.isCurrent {
			&:hover,
			&:focus,
			&:active {
				color: $primary;
				background: white;
			}
		}


		& + .btn {
			margin-left: 0;
		}
	}


	@media (max-width:716px) {
		width: 100%;


		.btn,
		.btn + .btn,
		.btn:first-child {
			float: none;
			width: 100%;
			border: none;
			margin-left: 0;
			border-bottom: 1px solid white;
			padding: 5px 0;
			font-size: $font-size-xs;


			&:last-of-type {
				border-bottom: none;
			}


			&:before {
				display: none;
			}
		}
	}


	.isDone:after {
		// &:extend(.icon-check_small:before);
		padding-left: 10px;
		font-size: 14px;
		height: 14px;
		width: 14px;
		display: inline-block;
	}
}



// Misc.
// ==========================================


.subdivision {
	border-top: 1px solid $gray-500;
	border-bottom: 1px solid $gray-500;
	padding: ($spacer * .5) 0;

	@include media-breakpoint-up(sm) {
		padding: ($spacer * 1) 0;
	}


	& + .subdivision {
		border-top: 0;
	}


	.checkbox {
		margin-bottom: 0;
	}
}


.required-fields-info {
	font-size: $font-size-sm;
}


.shop .radio {
	margin: 0 0 7px;
}


.alt-shipping,
.alt-billing {
	display: none;
	margin-top: ($spacer * 1);
}









// Checkout Steps
// =============================
