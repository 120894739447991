
.newsletter-sticky.btn {
	background: $white;
	font-size: $font-size-base;
	color: $text-color;
	line-height: 32px;
	z-index: 9999999;
	position: fixed;
	right: 0;
	top: 50%;
	transform: rotate(-90deg) translate(50%, 100%);
	transform-origin: right center;
	box-shadow: 0 0 40px rgba($black, 0.1);
	display: none;
	padding-right: 3.5rem;
	transition: box-shadow 0.2s ease-in-out, transform 0.4s ease-in;


	@include media-breakpoint-up(lg) {
		display: block;
	}


	&:before {
		font-size: $font-size-xl;
		color: $blue;
		transform: rotate(90deg) translate(-50%, -50%);
		right: 2rem;
	}


	&:hover,
    &:focus,
    &:active {
	    color: $text-color;
		box-shadow: 0 0 30px rgba($black, 0.3);
    }


    &.is-visible {
	    transform: rotate(-90deg) translate(50%, -50%);
    }
}


@media print {

	.newsletter-sticky {
		display: none !important;
	}
}