// TODO: refactor different instances?



// BIG SEARCH STARTSEITE

.quicksearch {
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
	
	
	&:hover,
	&:focus,
	&:active {
		// box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
	}
}



// BIG SEARCH BAR

.big-search {
	padding: ($spacer * 2);
	background: white;


	@include media-breakpoint-down(sm) {
		justify-content: flex-end;
		padding: ($spacer * 1);


		& > .form-control,
		& > .custom-select,
		& > .custom-file {
	  		width: 100%;
	  		margin-bottom: 0;
		}


		.input-group-append {
			margin-left: 0;
			margin-top: 16px;
		}
	}


	@include media-breakpoint-down(md) {

		& > .form-control {
			font-size: 1rem;
		}
	}


	&.big-search--404 {
		padding: 0;
		margin-bottom: ($spacer * 5);
	}
}

.big-search__input {
	&:focus {
		box-shadow: none;
	}
}

.big-search__submit {
	font-size: 1rem;
	padding-right: 3rem !important;
	
	&:before {
		font-size: 1.5rem !important;
	}
}





// INLINE SEARCH

.inline-search {
	position: relative;
	z-index: 0;
}


.inline-search__input,
.inline-search__submit {
	border-color: transparent;
	border-width: 1px;
}


.inline-search__input {
	border-radius: 4px !important;
	// box-shadow: inset 0 5px 5px  rgba($black, 0.1);
	color: $text-color;
	border-color: transparent !important;


	&::placeholder {
		color: $text-color;
	}


	&:focus {
		border-color: $gray-500 !important;
		box-shadow: inset 0 2px 10px  rgba($black, 0.3);


		& + .inline-search__submit {
			z-index: 4;
		}
	}

}


.inline-search__submit {
	border-bottom-right-radius: 4px;;
	border-top-right-radius: 4px;;
	background: transparent;
	width: $input-height;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	// z-index: 4;
	@extend .icon-meta-suche;


	&:before {
		font-size: $font-size-xl;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: $transition-base;
	}


	&:hover,
	&:active {

		&:before {
			color: $primary;
		}
	}
}



