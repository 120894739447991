
$navMainBorder: 1px solid darken($primary, 8%);
$navMainHeight: 80px;


.navigation-main {
	position: relative;
	z-index: 1;


	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		height: 100%;
		width: 4000px;
		background: $blue;
		z-index: -1;
	}
}


.navigation-main__first-level {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: $navMainHeight;
}


.first-level__item {
	flex-grow: 1;
	margin: 0;
	padding: 0;
	text-align: center;
	border-left: $navMainBorder;
	text-decoration: none;


	&:last-child {
		border-right: $navMainBorder;
	}


	&:not(.nohover):hover,
	&.hoverIntent {


		> a,
		> a:hover,
		> a:active,
		> a:focus,
		> span {
			color: $blue-darker;
			background: $white;
			text-decoration: none;


			&:after {
				right: -1px;
			}
		}


		.navigation-main__second-level {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}


	&.is-current {

		> a,
		> a:hover,
		> a:active,
		> a:focus,
		> span {
			color: $blue-darker !important;
			background: $white;
			text-decoration: none;


			&:after {
				right: -1px;
			}
		}
	}

	> a,
	> a:hover,
	> a:active,
	> span {
		font-weight: 700;
		font-size: 20px;
		line-height: $navMainHeight;
		color: white;
		background: $primary;
		text-decoration: none;
		display: block;
		height: $navMainHeight;
		transition: $transition-base;
		position: relative;
		text-decoration: none;


		@include media-breakpoint-up(xl) {
    		font-size: 24px;
		}


		// top border

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: -4px;
			right: 100%;
			left: 0;
			height: 8px;
			margin: 0;
			background: darken($primary, 8%);
			transition: $transition-base;
		}


		// Icons

		&:before {
			font-size: 45px;
			padding-right: 8px;
			vertical-align: -23%;

			@include media-breakpoint-up(xl) {
    			font-size: 54px;
			}
		}
    }

	// For keyboard users

    > a:focus {
		color: $blue-darker;
		background: $white;
		text-decoration: none;


		&:after {
			right: -1px;
		}
	}


    &.first-level__item--informieren {

		> a,
		> span {
			@extend .icon-navi-birne;
		}
	}


	&.first-level__item--aus-dem-leben {

		> a,
		> span {
			@extend .icon-navi-herz;
		}
	}


	&.first-level__item--mitmachen {

		> a,
		> span {
			@extend .icon-navi-sonne;
		}
	}

	&.first-level__item--documents {

		> a,
		> span {
			@extend .icon-link_download;
		}
	}


	&.first-level__item--profile {

		> a,
		> span {
			@extend .icon-meta-login;
		}
	}


	&.first-level__item--back {

		> a,
		> span {
			@extend .icon-link_intern;
		}
	}

	// Icons internal pages

	&.first-level__item--documents,
	&.first-level__item--profile,
	&.first-level__item--back {


		> a,
		> span {

			&:before {
				font-size: 32px;
				vertical-align: -14%;


				@include media-breakpoint-up(xl) {
	    			font-size: 40px;
	    			vertical-align: -16%;
				}
			}
		}
	}
}


.navigation-main__second-level {
	display: flex;
	opacity: 0;
	visibility: hidden;
	flex-wrap: wrap;
	transform: translateY(-20%);
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	list-style: none outside;
	padding: ($spacer * 1) 0 ($spacer * 4) 0;
	margin: 0 -0.5%;
	transition: all 0.2s ease-out;
	transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	z-index: -2;


	// debug mode
/*
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
*/


	&:before {
		overflow: hidden;
		position: absolute;
		right: 0;
		bottom: 0;
		color: $blue-lightest;
	}


	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		height: 100%;
		width: 4000px;
		z-index: -1;
		background: $white;
		box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
	}
}


.first-level__item--informieren .navigation-main__second-level {
	@extend .icon-navi-birne;

	&:before {
		height: 210px;
		width: 260px;
		font-size: 260px;
	}
}


.first-level__item--aus-dem-leben .navigation-main__second-level {
	@extend .icon-navi-herz;

	&:before {
		height: 237px;
		width: 310px;
		font-size: 320px;
	}
}


.first-level__item--mitmachen .navigation-main__second-level {
	@extend .icon-navi-sonne;

	&:before {
		height: 240px;
		width: 290px;
		font-size: 290px;
	}
}





.second-level__item {
	flex: 0 0 48.5%;
	min-height: 80px;
	margin: ($spacer * 1) 0.5%;
	text-align: left;


	@include media-breakpoint-up(md) {
    	flex: 0 0 32.333%;
	}


	@include media-breakpoint-up(xl) {
    	flex: 0 0 24%;
	}


	> a,
	> span {
		font-weight: 700;
		text-align: left;
		text-decoration: none;
		border-bottom: 1px solid $gray-300;
		padding: ($spacer * 1) 0 ($spacer * 1) ($spacer * 1);
		display: block;
		height: 100%;
		transition: $transition-base;
		position: relative;
		z-index: 1;


		&:before {
			background: $blue-lightest;
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: -1;
			width: ($spacer / 2);
			height: 100%;
			transition: $transition-base;
		}


		&:hover,
		&:active,
		&:focus {
			color: $blue-darker;
			text-decoration: none;


			&:before {
				width: 100%;
			}
		}
    }


    &.is-current {

	    > a,
		> span {

			&:before {
				width: 100%;
			}
		}
    }
}


@each $key, $value in $areas {


	.second-level__item--#{$key} {


		> a,
		> span {
			color: $value;


			&:before {
				background: $value;
			}


			&:hover,
			&:active,
			&:focus {
				color: #fff;
			}
		}


		&.is-current {

		    > a,
			> span {
				color: white;
			}
	    }
	}
}


.sub-toggle {
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	border: 0;
	padding: 0;
	background: transparent;
	color: #fff;
	height: 32px;
	width: 32px;
	@extend .icon-arrow-dropdown;


	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: $transition-base;
	}


	&:not(:focus) {
	    width: 1px;
	    height: 1px;
	    overflow: hidden;
	    clip: rect(0, 0, 0, 0);
	    white-space: nowrap;
	    clip-path: inset(50%);


	    &:before {
		    display: none;
	    }
	}
}


.hoverIntent {

	.sub-toggle {
		color: $blue-darker;


		&:before {
			transform: translate(-50%, -50%) rotate(180deg);
		}
	}
}


.is-current {

	.sub-toggle {
		color: $blue-darker;
	}
}



/*


 @mixin is-active {
    &:hover,
    &:focus,
    &:active,
    &.is-current {
      @content;
    }
}

.XXXlink {
	color: pink;

	@include is-active() {
		color: red;
		text-decoration: line-through;
	}
}
*/


.site-type--regional .navigation-main__first-level {
    height: 70px;
}


.site-type--regional .first-level__item {

	> a,
	> a:hover,
	> a:active,
	> span {
		font-size: 18px;
		line-height: 70px;
		height: 70px;


		@include media-breakpoint-up(xl) {
    		font-size: 18px;
		}

	}
}


