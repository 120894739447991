.breadcrumb {
	display: none;


	@include media-breakpoint-up(sm) {
		display: block;
	}

	ol {
		position: relative;
		margin: 0;
		padding: 0;


		li {
			margin: 0;
			padding: 0 10px 0 0;
			float: left;
			list-style: none;
			color: $gray-900;
			font-size: $font-size-xs;
			@extend .icon-arrow-button-next;


			&:before {
				font-size: 8px;
				padding-right: 2px;
				top: -2px;
				position: relative;
			}


			&:first-of-type {

				&:before {
					display: none;
				}
			}


			a {
				color: $gray-900;
				text-decoration: none;


				&:hover,
				&:focus {
					color: $primary;
					text-decoration: none;
				}
			}


			&:last-of-type {

				a,
				span {
					//color: $primary;
					color: $gray-900;
				}
			}
		}
	}
}

@media print {

	.breadcrumb {
		// display: none !important;
	}
}