
.nav-tabs {
	border-bottom: none;


	@include media-breakpoint-up(sm) {
		border-bottom: $border-base;
		border-color: $gray-500;
	}


	.nav-item {
		display: block;
		width: 100%;
		margin-bottom: -1px;


		@include media-breakpoint-up(sm) {
			margin-right: $spacer;
			display: list-item;
			width: auto;
		}
	}


	.nav-link {
		color: $blue;
		background: $blue-lighter;
		border-color: $gray-500;
		font-weight: bold;
		transition: $transition-base;
		position: relative;
		padding-top: 10px;
		padding-bottom: 10px;


		@include media-breakpoint-up(sm) {
			border-color: $blue-lighter;
			border-bottom-color: $gray-500;
		}


		&:before {
			content: "";
			display: block;
			height: 4px;
			background: transparent;
			position: absolute;
			top: -4px;
			left: -1px;
			right: -1px;
			transition: $transition-base;
		}


		&:hover,
		&:active,
		&.active {
			background: #fff;
			border-color: $gray-500;


			&:before {
				background: $blue;
			}
		}


		&:hover,
		&:active {

		}


		&.active {
			cursor: default;
			border-bottom-color: $gray-500;


			@include media-breakpoint-up(sm) {
				border-bottom-color: #fff;
			}
		}
	}
}




