@import "vendor/vue-multiselect.min";

.multiselect {
    font-size: 16px;
    box-sizing: border-box;

    strong {
        font-weight: 300;
    }
}

.multiselect__tags {
    border-radius: 0;
    border: 0;
    padding: 12px 40px 4px 15px;
    min-height: 44px;
}

.multiselect__select {
	width: 44px;
	height: 44px;
	top: 0;
	right: 0;
	color: $primary;

	&:before {
		font-family: "iconfont";
		content: "\f13c";
		border: 0 !important;
		top: 50%;
		left: 50%;
		right: auto;
		font-size: 16px;
		position: absolute;
		margin: 0;
		transform: translate(-50%,-50%);
	}
}

.multiselect .multiselect__strong {
	font-weight: 700;
	color: $primary;
}

.multiselect--active .multiselect__select:before {
    color: $primary;
}


.multiselect__content-wrapper {
	left: -1px;
	right: -1px;
}

.multiselect__option{
	white-space: normal;

}

.multiselect__option--highlight {
    background: $primary;
}

.multiselect__option--selected,
.multiselect__option--selected.multiselect__option--highlight {
    background: $blue-lighter;
    color: $primary;
}

.multiselect__content-wrapper {
    border-radius: 0 !important;
}


.multiselect__option--selected {
	@extend .icon-tickbox;
	position: relative;

	&:before {
        position: absolute;
        top: 12px;
        right: 14px;
        font-size: 12px;
    }
}

.multiselect__option--selected:after {
	content: "" !important;
}





#map {
  position: relative;
  width: 100%;
  font-size: 15px;
  margin-bottom: 100px;

  a {
      text-decoration: none;
  }


  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }

  .loading {

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255,255,255,0.9);
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      background-image: url('../images/ajax-loader.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
      height: 64px;
      width: 64px;
    }
  }

  #map-google {
    position: relative;
    height: 480px;
    background: #eee;

    @include media-breakpoint-up(sm) {
        margin-left: 320px;
        height: 800px;
    }
  }

  .map-sidebar {
    position: relative;
    background-color: $white;
    background-image: url('../images/bg_map_tool.jpg');
    box-sizing: border-box;
    overflow: hidden;
    z-index: 1;
    height: 700px;

    @include media-breakpoint-up(sm) {
        position: absolute;
        width: 320px;
        top: 0;
        left: 0;
        bottom: 0;
        height: 800px;
    }

    .map-sidebar__link {
      display: block;
      line-height: 24px;
      padding: 10px 10px;
      text-decoration: none;
      color: $primary;
      font-weight: 700;
      background: $gray-100;
      position: relative;

      @extend .icon-arrow-button-prev;

      &:before {
	      padding-right: 6px;
      }
    }

    .map-sidebar__link--white {
        background: #fff;
        color: $primary;


        &:after {
            height: 1px;
            left: 30px;
            right: 30px;
            background: $gray-300;
            content: '';
            position: absolute;
            bottom: 0;
        }
    }

    .map-list__results {
	    padding: 15px 0;
		margin: 15px 30px 0;
		line-height: 20px;
		border-bottom: 1px solid $gray-300;
		color: $gray-600;
		box-sizing: border-box;
		font-weight: 300;
    }
  }

  .map-search {
    padding: 30px 0;

    .countries-select,
    .states-select,
    .themes-select {
      .multiselect__single {
        display: none !important;
      }
    }

    form {
      display: block;

    }

    h4 {
	    text-transform: uppercase;
    }

    input, select, label, button {
      display: block;
      width: 100%;
      line-height: 24px;
      padding: 10px 15px;
      box-sizing: border-box;
      border-radius: 0;
      border: 0;
      background-color: #fff;
      font-size: 16px;
      font-weight: 700;
      color: $primary;
    }

    input, .multiselect, button {

      border: 1px solid #d1d7d1;
    }

    label {
      background-color: transparent;
    }

    button {
      width: 50%;
      position: relative;
      text-align: left;
      float: right;
      padding-left: 30px;

        &:before {
            position: absolute;
            top: 50%;
            right: 0px;
            //&:extend(.icon-arrow_next:before);
            transform: translate(-50%,-50%) rotate(0deg);
            color: #999999;
        }
    }

    [type="checkbox"] {
      display: none;
    }

    .field {
      margin-bottom: 20px;
      box-sizing: border-box;
      display: block;
      float: left;
      width: 100%;
      padding: 0 30px;
    }

    .fieldset .field {
	    padding: 0;
    }

    .field-2 {
      width: 50%;

      input {
          border-right: 1px solid #d1d7d1;
      }
    }

    label {
      cursor: pointer;
      position: relative;
      display: block;
      font-size: 16px;
      padding: 0;
      font-weight: 300;
      margin: 0;
    }

    .fieldset {
        float: left;
        width: 100%;
        padding: 0 30px 5px;
        border-bottom: 1px solid $gray-200;
        margin-bottom: 20px;
    }

    .fieldset--top {
        border-top: 1px solid $gray-200;
    }

    .field-checkbox {
      height: auto;
	  padding: 0;

      label {
	    font-weight: 700;
	    line-height: 20px;
	    font-size: 14px;
        padding: 0 0 0 35px;
        color: $text-color;
      }

    }

    input[type="checkbox"], input[type="radio"] {
      position: absolute;
      visibility: hidden !important;
    }

    input[type="checkbox"] + label, input[type="radio"] + label {

	  /*
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -14px;
        box-sizing: border-box;
      }

      &:before {

        width: 28px;
        height: 28px;
        left: 0px;
        background: #fff;
        border: 1px solid #e4e3e1;
      }
      &:after {
        width: 15px;
        height: 15px;
        left: 7.5px;
        background: #BDBDBD;
        border-radius: 50%;
        transition: all 200ms ease-out;
        margin-top: -7.5px;
      }
      */

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -14px;
        box-sizing: border-box;
        width: 28px;
        height: 28px;
        left: 0px;
        border: 1px solid #e4e3e1;
      }


    }

    input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {
		@extend .icon-tickbox;

		&:before {
        	position: absolute;
			top: 4px;
			left: 7px;
			font-size: 12px;
        	box-sizing: border-box;
        	color: $primary;
        }
	/*
      &:after {
        background: $primary;
      }
      */
    }
  }




.map-list {
    position: absolute;
    background: #fff;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;

    .map-list__entity {
		display: block;
		padding: 15px 60px 15px 30px;
		font-size: 15px;
		line-height: 20px;
		text-decoration: none;
		position: relative;
        color: $gray-900 !important;
        font-weight: 300;
		@extend .icon-link--intern !optional;

		&:after {
            height: 1px;
            left: 30px;
            right: 30px;
            background: $gray-300;
            content: '';
            position: absolute;
            bottom: 0;
        }

        &:before {
            position: absolute;
            top: 50% !important;
            right: 30px;
            //&:extend(.icon-arrow:before);
            transform: translateY(-50%) rotate(0deg);
            color: #999999;
            font-size: 24px;
            color: $primary;
            margin-top: 5px;
        }

        .map-list__entity__themes {
        	margin-bottom: 7px;
        	font-size: 13px;
        	line-height: 18px;

        	span {
	        	display: inline-block;

	        	&:before {
		        	content: "·";
		        	padding: 0 5px;
	        	}

	        	&:first-child:before {
		        	display: none;
	        	}
        	}
    	}

        .map-list__entity__title {
        	color: $primary;
			font-weight: 700;
    	}

        &:hover {

	        &:before {
		        right: 20px;
	        }


	        .map-list__entity__title {
        		color: #f7a600;
    		}
        }
    }
}

  .map-detail {
    position: absolute;
    background: #fff;
    top: 88px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px 0 0 0;

    .map-detail__title,
    .map-detail__street,
    .map-detail__location,
    .map-detail__tel,
    .map-detail__fax,
    .map-detail__email,
    .map-detail__website,
    .map-detail__organisation,
    .map-detail__length,
    .map-detail__entity {
      display: block;
      line-height: 24px;
      text-decoration: none;
    }

    .map-detail__entity {
		display: block;
		padding: 15px 60px 15px 30px;
		font-size: 15px;
		line-height: 20px;
		text-decoration: none;
		position: relative;
        color: $gray-900 !important;
        font-weight: 300;
		@extend .icon-link--intern !optional;

		&:after {
            height: 1px;
            left: 30px;
            right: 30px;
            background: $gray-300;
            content: '';
            position: absolute;
            bottom: 0;
        }

        &:before {
            position: absolute;
            top: 50% !important;
            right: 30px;
            //&:extend(.icon-arrow:before);
            transform: translateY(-50%) rotate(0deg);
            color: #999999;
            font-size: 24px;
            color: $primary;
            margin-top: 5px;
        }

        .map-detail__entity__themes {
        	margin-bottom: 7px;
        	font-size: 13px;
        	line-height: 18px;

        	span {
	        	display: inline-block;

	        	&:before {
		        	content: "·";
		        	padding: 0 5px;
	        	}

	        	&:first-child:before {
		        	display: none;
	        	}
        	}
    	}

        .map-detail__entity__title {
        	color: $primary;
			font-weight: 700;
    	}

        &:hover {

	        &:before {
		        right: 20px;
	        }


	        .map-detail__entity__title {
        		color: #f7a600;
    		}
        }
    }

/*
    .map-detail__length {
      padding: 13px 0;
      margin: 0 30px;
      line-height: 24px;
      border-bottom: 1px solid #cccccc;
      color: $primary;
      box-sizing: border-box;
      font-weight: 400;
      margin-bottom: 10px;
    }
    */

    .map-detail__entity {
      display: block;
      padding: 10px 60px 10px 30px;
      line-height: 24px;
      text-decoration: none;

    }

    .map-detail__title {
        padding: 0px 30px;
    }

	.map-detail__themes {
        position: relative;
        padding: 0 30px;
    	margin-bottom: 7px;
    	font-size: 13px;
    	line-height: 18px;
	}

	.map-detail__organisation {
        position: relative;
        margin: 30px 0;
        padding: 0 30px;
    }

	.map-detail__address,
	.map-detail__contact {
        position: relative;
        margin: 20px 0;
        padding: 0 30px 0 65px;
    }

    .map-detail__address {
       	@extend .icon-pin;

        &:before {
            position: absolute;
            top: -1px !important;
            left: 30px !important;
            transform: translate(0%,-0%) rotate(0deg) !important;
            font-size: 26px !important;
            color: $primary;
        }
    }

    .map-detail__email {
        position: relative;
        margin: 15px 0;
        padding: 0 10px 0 65px;
        font-weight: 700;
        display: inline-block;
        max-width: 100%;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
		@extend .icon-mail;

        &:before {
            position: absolute;
            top: 2px !important;
            left: 30px !important;
            transform: translate(0%,-0%) rotate(0deg) !important;
            font-size: 20px !important;
            color: $primary;
        }
    }

    .map-detail__website {
        position: relative;
        margin: 15px 0;
        padding: 0 10px 0 65px;
        font-weight: 700;
        display: inline-block;
        max-width: 100%;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
        @extend .icon-link_extern;

        &:before {
            position: absolute;
            top: -1px !important;
            left: 27px !important;
            transform: translate(0%,-0%) rotate(0deg) !important;
            font-size: 24px !important;
            color: $primary;
        }
    }

    .map-detail__organisation-profile {
        position: relative;
        margin: 15px 0;
        padding: 0 0 0 35px;
        font-weight: 700;
        display: inline-block;
        @extend .icon-link_extern;

        &:before {
            position: absolute;
            top: -1px !important;
            left: -3px !important;
            transform: translate(0%,-0%) rotate(0deg) !important;
            font-size: 24px !important;
            color: $primary;
        }
    }

    .map-detail__profile {
        width: 260px;
        margin: 15px 30px;
    }
  }

  .map__infobox {
    background-color: $white;
    background-image: url('../images/bg_map_tool.jpg');
    width: 280px; // 280
    line-height: 30px;
    padding: 10px 30px;
    box-sizing: border-box;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);

    &:after {
    	top: 100%;
    	left: 50%;
    	border: solid transparent;
    	content: " ";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    	border-color: rgba(55, 116, 106, 0);
    	border-top-color: $white;
    	border-width: 15px;
    	margin-left: -15px;
    }

    & > img {
      position: absolute !important;
      top: 0;
      right: 0;
    }

    a {
      color: $text-color;
      text-decoration: none;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      display: block;
      padding: 10px 0;
    }
  }
}


#map .map-sidebar {
	overflow: visible;
}


#fieldstates .multiselect__content-wrapper,
.multiselect__content-wrapper {

	@include media-breakpoint-down(md) {
		max-height: none !important;
	}
}

.page-type--iframe #map {
	margin-bottom: 0;
}

.page-type--iframe #map #map-google {
	height: 480px;
}

@include media-breakpoint-up(sm) {

	.page-type--iframe,
	.page-type--iframe body {
		height: 100%;
	}

	.page-type--iframe #map {
		height: 100%;
		margin-bottom: 0;
	}

	.page-type--iframe #map > div {
		height: 100%;
	}

	.page-type--iframe #map #map-google {
		height: 100%;
	}

	.page-type--iframe #map .map-sidebar {
		height: 100%;
	}

}


.page-type--iframe .cc-window {
	display: none !important;
}