
$check-size:				$font-size-xl;
$check-border-color:		$input-border-color;
$check-border-color:		$input-border-color;
$check-border-width:		$input-border-width;
$check-hover-border-color:	$input-hover-border-color;
$check-focus-border-color:	$input-focus-border-color;
$check-bg:					$input-bg;
$check-disabled-bg:			$input-disabled-bg;


.radio,
.checkbox,
.form-check,
.form-check-inline {
	margin: 0;
	padding: 0;
	margin-bottom: ($spacer * 0.5);
	position: relative;


	&.radio-inline,
	&.checkbox-inline,
	&.form-check--inline {
		margin-right: ($spacer * 2);
		display: inline;
	}


	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		left: 3px;
		top: 50%;
		transform: translateY(-50%);
		margin: 0;


		&:disabled,
		&[readonly] {


			&:hover,
			&:active,
			&:focus {


				& + label {
					cursor: not-allowed;

					&:before {
						border-color: $input-border-color;
					}
				}
			}
		}
	}


	label {
		padding-left: $check-size + $input-padding-x;
		position: relative;
		cursor: pointer;
		transition: $transition-base;


		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
			border-style: solid;
			border-color: $input-border-color;
			border-width: $check-border-width;
			width: $check-size;
			height: $check-size;
			background: $check-bg;
			transition: $transition-base;
		}


		a {
			// text-decoration: underline;
		}


		&:focus,
		&:hover,
		&:active {
			color: $text-color;


			&:before {
				border-color: $check-hover-border-color;
			}
		}

		.error {
			&:before,
			&:after {
				display: none;
			}
		}
	}




	input[type="checkbox"] + label,
	input[type="radio"] + label {


		&:before,
		&:after {
			transition: $transition-base;
		}
	}


	input[type="checkbox"]:checked + label {

		&:before {
			color: $blue;
			text-align: center;
			font-size: 11px;
			line-height: 26px !important;
			@extend .icon-tickbox;
		}
	}


	input[type="radio"] + label {

		&:before {
			border-radius: 50%;
		}

		&:after {
			content: "";
			display: block;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: ($check-size / 2);
			transform: translate(-50%, -50%);
			width: ($check-size / 2);
			height: ($check-size / 2);
			background: $check-bg;
		}
	}


	input[type="checkbox"]:focus + label,
	input[type="radio"]:focus + label,
	input[type="radio"]:focus:checked + label {
		color: $blue;

		&:before {
			border-color: $blue !important;
			background: $blue-lightest;
		}

		&:after {
			background: transparent;
		}
	}


	input[type="radio"]:focus:checked + label {

		&:after {
			background: $blue;
		}
	}


	input[type="radio"]:checked + label {

		&:before {
			border-color: $blue !important;
			background: #edf4fa;
		}

		&:after {
			background: $blue;
		}
	}
}


.form-group.form-group--radio,
.form-group.form-group--check {
	margin-top: 0.75rem;
}




// TODO: refactoring, work in progress below

/*
.form-check {
	margin: 0;
	padding: 0;
	margin-bottom: ($spacer * 0.5);


	&.form-check-inline {
		margin-right: ($spacer * 2);
	}
}


.form-check-input {
	position: absolute;
	left: 3px;
	top: 50%;
	transform: translateY(-50%);
	margin: 0;


	// Labels

	& + .form-check-label {
		padding-left: $check-size + $input-padding-x;
		position: relative;
		cursor: pointer;
		transition: $transition-base;

		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
			border-style: solid;
			border-color: $input-border-color;
			border-width: $check-border-width;
			width: $check-size;
			height: $check-size;
			background: $check-bg;
			transition: $transition-base;
		}

		&:after {
			transition: $transition-base;
		}

		&:focus,
		&:hover,
		&:active {
			color: $text-color;

			&:before {
				border-color: $check-hover-border-color;
			}
		}
	}


	// Checkbox specific

	&[type="checkbox"] {

		// Checkbox checked

		&:checked {

			& + .form-check-label {

				&:before {
					text-align: center;
					font-size: 11px;
					line-height: 26px !important;
					@extend .icon-tickbox;
				}
			}
		}
	}


	// Radio button specific

	&[type="radio"] {

		& + .form-check-label {

			&:before {
				border-radius: 50%;
			}

			&:after {
				content: "";
				display: block;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: ($check-size / 2);
				transform: translate(-50%, -50%);
				width: 0;
				height: 0;
				background: $check-bg;
			}
		}


		// Radio button checked

		&:checked {

			& + .form-check-label {
				color: $blue;

				&:after {
					background: $blue;
					width: ($check-size / 2);
					height: ($check-size / 2);
				}
			}
		}
	}


	// Disabled state

	&:disabled,
	&[readonly] {

		&:hover,
		&:active,
		&:focus {

			& + .form-check-label {
				cursor: not-allowed;

				&:before {
					border-color: $input-border-color;
				}
			}
		}
	}
}
*/
