

.textrow {


	p,
	ul li,
	ol li,
	address {


		@include media-breakpoint-up(lg) {
			font-size: $font-size-md;
		}


		a {
			font-weight: 600;
		}
	}


	ul,
	ol {
		margin-left: 0;
		padding-left: 0;
		overflow: hidden;
		margin-bottom: ($spacer * 0.5);


		@include media-breakpoint-up(sm) {
			margin-bottom: ($spacer * 1);
		}


		ul, ol {
			margin-top: ($spacer * 0.25);
			margin-bottom: 0;


			@include media-breakpoint-up(sm) {
				margin-bottom: 0;
			}


			li:last-of-type {
				margin-bottom: 0;
			}
		}
	}


	ul {

		li {
			list-style: none;
			position: relative;
			margin: 0 0 ($spacer * 0.5);
			padding-left: 25px;
			overflow: hidden;


			&:before {
				content: "";
				position: absolute;
				top: 10px;
				width: 4px;
				height: 4px;
				background: $primary;
				left: 2px;
				border-radius: 5px;
			}


			@include media-breakpoint-up(lg) {
				padding-left: 50px;


				&:before {
					width: 6px;
					height: 6px;
					top: 13px;
				}
			}
		}
	}


	ol {
		counter-reset: list;


		& > li {
			margin: 0 0 ($spacer * 0.5);
			list-style: none;
			position: relative;
			overflow: hidden;
			padding-left: 25px;


			@include media-breakpoint-up(lg) {
				padding-left: 50px;
			}


			&:before {
				counter-increment: list;
				content: counter(list) '.';
				position: absolute;
				top: 0;
				left: 0;
				color: $primary;
				font-weight: 600;
			}
		}
	}


	ul, ol {

		ul {

			& > li {
				margin: 0 0 ($spacer * 0.25);
				font-size: $font-size-xs;
			}


			@include media-breakpoint-up(lg) {
				padding: ($spacer * 0.5) 0;


				& > li {
					padding-left: 20px;
					font-size: $font-size-base;


					&:before {
						width: 4px;
						height: 4px;
						top: 10px;
					}
				}
			}
		}


		&.list-unstyled,
		&.list-inline {

			li {
				padding-left: 0;


				&:before {
					display: none;
				}
			}
		}
	}


	.media {
		margin: 0 -20px ($spacer * 1);

		&.media--full-width {
			padding: 0;


			@include media-breakpoint-up(sm) {
				padding: 0;
			}


			@include media-breakpoint-up(lg) {
				padding: 0;
			}
		}
	}


	@include media-breakpoint-up(sm) {


		.media {
			margin: 0 0 ($spacer * 1);
		}


		.media--left {
			float: left;
			width: 50%;
			padding: 0 floor(( $grid-gutter-width )) 0 0;
		}


		.media--right {
			float: right;
			width: 50%;
			padding: 0 0 0 floor(( $grid-gutter-width ));
		}


		.media--left .image,
		.media--right .image {
			margin: 0;
		}
	}


	@include media-breakpoint-up(lg) {


		.media--left,
		.media--right {
			width: 370px;
			padding: 0;


			&.media--wide {
				width: 470px;
			}
		}


		.media--left {
			margin-right: floor(($grid-gutter-width * 1.5));
			margin-left: 0;


			&.media--wide {
				margin-left: -11.4%;


				.media__caption {
					padding-left: 22.8%;
				}
			}
		}


		.media--right {
			margin-left: floor(($grid-gutter-width * 1.5));
			margin-right: floor( -($grid-gutter-width * 1));
			margin-right: 0;


			&.media--wide {
				margin-right: -23.05%;


				.media__caption {
					padding-right: floor(($grid-gutter-width * 1));
				}
			}
		}
	}
}



.textrow--intro {


	p, li {
		font-size: $font-size-md;


		@include media-breakpoint-up(sm) {
			font-size: $font-size-md;
		}


		@include media-breakpoint-up(lg) {
			font-size: $font-size-xl;
		}
	}
}






.textrow--col-1,
.textrow--col-2,
.textrow--col-3,
.textrow--col-4,
.textrow--col-5 {

	p,
	ul li,
	ol li,
	address {
		font-size: $font-size-sm;


		@include media-breakpoint-up(md) {
			font-size: $font-size-base;
		}
	}


	ul,
	ol {

		li {
			padding-left: 20px;
		}
	}


	ul li:before {
		top: 7px;
	}



	.link-group {
		// .link-group--no-border;
		// .link-group--compact;
	}
}


.textrow--col-4,
.textrow--col-5 {


	h4,
	h5 {
		font-size: $font-size-sm;
		margin-bottom: ($spacer * 0.5);
	}


	p,
	ul li,
	ol li,
	address {
		font-size: $font-size-sm;
	}
}


.textrow--highlight {

	.textrow__text {
		background: $blue-lighter;
		padding: 1rem 20px 1px;

		@include media-breakpoint-up(md) {
			padding: 1.5rem 30px 1px;
		}
	}
}

