

// HEADER

.header {
	background: $white;
	z-index: 3;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;


	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		height: 100%;
		width: 4000px;
		background: $white;
	}


	@include media-breakpoint-down(md) {
		// overflow-x: hidden;
	}
}


.header__bottom,
.header__teaser {
	display: none;


	@include media-breakpoint-up(lg) {
		display: flex;
	}
}


.header__top {
	height: 60px;
	position: relative;
	display: none;
	justify-content: space-between;
	align-items: center;


	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		height: 100%;
		width: 4000px;
		background: $gray-200;
	}


	@include media-breakpoint-up(lg) {
		display: flex;
	}
}


.header__middle {
	display: flex;
	align-items: center;
	margin-bottom: 0;
	background: #fff;
	height: 70px;
	// border-bottom: 8px solid $primary;
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);


	@include media-breakpoint-up(lg) {
		height: 130px;
		border-bottom: none;

	}
}


.header__bottom {
	position: relative;
}


.header__logo {
	margin-right: auto;
	margin-left: 0;
	z-index: 999;


	@include media-breakpoint-up(xl) {
		margin-left: ($grid-gutter-width * 1);
	}


	svg {
		width: 165px;

		@include media-breakpoint-up(lg) {
			width: 250px;
		}

		@include media-breakpoint-up(xl) {
			width: 285px;
		}
	}
}





// SITE SEARCH


.header__site-search {
	flex: 1 1 50%;
	margin: 0 ($spacer * 2);
}




// STICKY HEADER


.header__bottom {
	width: 100%;
	transition: all 0.2s ease-in;
}


.header__bottom.scroll-direction-is-down.scroll-offset-small-is-reached {
	transform: translateY(0);
	position: relative;
	top: auto;
}


.header__bottom.scroll-direction-is-down.scroll-offset-small-is-reached.scroll-offset-out-is-reached,
.header__bottom.scroll-direction-is-down.scroll-offset-small-is-reached {
	transform: translateY(-100%);
	position: fixed;
	top: 0;
	width: 100%;
}


.header__bottom.scroll-offset-small-is-reached.scroll-offset-out-is-reached.scroll-direction-is-up,
.header__bottom.scroll-offset-small-is-reached.scroll-direction-is-up,
.header__bottom.scroll-offset-small-is-reached.scroll-direction-is-down {
	transform: translateY(0);
	position: fixed;
	top: 0;
	width: 100%;
}




// TEST

@include media-breakpoint-down(lg) {


	.header__middle {
		width: 100%;
		transition: all 0.2s ease-in;
	}


	.header__middle.scroll-direction-is-down.scroll-offset-small-is-reached {
		transform: translateY(0);
		position: relative;
		top: auto;
	}


	.header__middle.scroll-direction-is-down.scroll-offset-small-is-reached.scroll-offset-out-is-reached,
	.header__middle.scroll-direction-is-down.scroll-offset-small-is-reached {
		transform: translateY(-100%);
		position: fixed;
		top: 0;
		width: 100%;
	}


	.header__middle.scroll-offset-small-is-reached.scroll-offset-out-is-reached.scroll-direction-is-up,
	.header__middle.scroll-offset-small-is-reached.scroll-direction-is-up,
	.header__middle.scroll-offset-small-is-reached.scroll-direction-is-down {
		transform: translateY(0);
		position: fixed;
		top: 0;
		width: 100%;
	}
}




// HEADER MOBILE


.header__search-link,
.slide-nav-trigger,
.navigation-mobile .slide-nav-root .slide-nav-label.slide-nav-label-close {
	z-index: 1;
	margin-bottom: 0;
	height: 60px;
	width: 60px;
	z-index: 1;
	position: relative;
	color: $blue;


	&:before {
		display: block;
		height: 30px;
		width: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 30px;
		transition: $transition-base;
	}


	&:hover,
	&:active {
		text-decoration: none;


		&:before {
			color: $black;
		}
	}


	@include media-breakpoint-up(lg) {
    	display: none;
	}
}


.header__search-link {
	@extend .icon-meta-suche;
}


.slide-nav-trigger {
	@extend .icon-menu;
}


.slide-nav-label-close {
	@extend .icon-close;
	position: absolute !important;
}


@media print {

	.header__bottom,
	.header__top {
		display: none !important;
	}
}