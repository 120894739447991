
.not-found-wrap {
	background: none;


	@include media-breakpoint-up(lg) {
		background-image: url('../../assets/images/404.png');
		background-repeat: no-repeat;
		background-size: 387px 301px;
		background-position: 90% center;
		position: relative;
		z-index: 1;
	}
}
