.nwl__widget {

	.nwl__wrap {
		background: $blue;
		padding: ($spacer * 2) $grid-gutter-width ($spacer * 1);
		overflow: hidden;
		clear: both;
		background-image: url("../images/bg_icon_teaser_newsletter.png");
		background-repeat: no-repeat;
		background-position: 93% 160%;
		// background-size: 20% 20%;

        display: flex;
        flex-direction: column;
        // align-content: flex-end;

		@include media-breakpoint-up(md) {
			overflow: visible;
			box-shadow: 0 0 40px rgba(0,0,0, 0.1);
		}

        & .nwl__heading {
    		background: white;
    		margin: -($spacer * 2) floor(-($grid-gutter-width * 1)) ($spacer * 2);
    		padding: ($spacer * 1) $grid-gutter-width;
    		z-index: 1;
    		position: relative;
    		color: $primary;
    		font-size: 1.125rem;
    	}

        & p {
            color: $white;
        }

        & a {
		    align-self: flex-end;
		    padding: 0.5rem 1.5rem;
		    margin: -1rem -1.9rem;
			background-color: $eastern-blue;
		    border-color: $eastern-blue;
        }

	}

}
