
.shop-item__title {
	margin-bottom: ($spacer * 1);
	transition: $transition-base;
}


.shop-item__sub-title {
	margin-top: ($spacer * -0.5);
	margin-bottom: ($spacer * 1);
	display: block;
	transition: $transition-base;
	font-weight: 700;
}


.shop-detail__body {
	display: flex;
	flex-direction: column;


	.shop-item__title {
		margin-bottom: ($spacer * 2);
	}


	.shop-item__sub-title {
		margin-top: ($spacer * -1.5);
		margin-bottom: ($spacer * 2);
	}


	.shop-item__put-to-cart {
		margin-top: auto;
		justify-content: flex-start;
		margin-top: ($spacer * 1);
		margin-bottom: ($spacer * 2);


		@include media-breakpoint-up(md) {
			justify-content: flex-end;
			margin-top: auto;
			margin-bottom: 0;
		}


		label.error {
			flex: 0 0 100%;
			max-width: 100%;


			@include media-breakpoint-up(md) {
				text-align: right;
				margin-bottom: 0;
			}
		}
	}
}


.shop-detail__image {
	margin-bottom: ($spacer * 2.5);


	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}
}


.shop-detail .carousel--shop-detail {
	margin-left: 0;
	margin-right: 0;


	.slick-arrow {
		top: auto !important;
		bottom: -8px !important;
		height: ($spacer * 2.5);
		width: ($spacer * 2.5);


		@include media-breakpoint-up(md) {
			bottom: -34px !important;
		}


		&:before {
			font-size: 40px;
			line-height: 40px;
		}


		&.slick-next {
			right: -22px;

			@include media-breakpoint-up(md) {
			}
		}


		&.slick-prev {
			left: 0;

			@include media-breakpoint-up(md) {
			}
		}
	}


	.carousel__wrap {
		margin-bottom: ($spacer * 2.5);


		img {
			width: 100%;
		}
	}


	.carousel__wrap .slick-list {
		margin-bottom: 0;
	}


	.carousel__dots {
		margin-bottom: 14px;
	}


	.lightbox {

		.icon-lightbox  {
			top: 85%;
			font-size: 40px;
			height: 40px;
			width: 40px;
			opacity: 1;
		}


		&:hover {

			.icon-lightbox  {
				text-shadow: rgba(0, 0, 0, 0.7) 0 0 25px;
			}
		}
	}
}


.shop-detail .carousel__item--publication {


	.image__link {
		display: block;
	}


	.publication-wrap {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $gray-300;
		padding-right: 0;
		position: relative;
		height: auto;
		position: relative;


		.image {
			padding: 0 ($spacer * 1.75);
			position: static;


			@include media-breakpoint-up(lg) {
				padding: 0 ($spacer * 2.5);
			}
		}


		.shadow-wrap {
			position: relative;
			display: block;

// 			display: inline-block;

			&:before {
				content: "";
				position: absolute;
				right: -10px;
				bottom: 0;
				width: 20px;
				height: 20px;
				background: #c6c6c6;
				transform: skewX(-45deg);


				@include media-breakpoint-up(lg) {
					right: -15px;
					width: 30px;
					height: 30px;
				}
			}
		}


		img {
			position: relative;
			z-index: 1;


		}
	}
}
