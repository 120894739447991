.pagination {
    position: relative;
}

.pagination__info {
	color: $text-color;
	font-size: $font-size-xs;
}

.pagination__list {
	margin: 0;
	padding: 0;
	list-style-type: none;

	& > li {
    	padding: 0;
    	padding-left: 0 !important;
		display: inline-block;
		margin-right: 5px;
		margin-bottom: 10px;
		float: left;


		&:before {
    		display: none;
		}

		& > a,
		& > span {
    		color: $primary;
			text-align: center;
			text-decoration: none;
			padding: 6px;
			-webkit-backface-visibility: hidden;
			transition: all .2s ease-in-out;
			font-size: 14px;
			font-weight: 600;
			line-height: 28px;
			height: 40px;
			width: 40px;
    		display: inline-block;
            background-color: #f7f7f7;


			&:hover,
			&:focus {
                background: $primary;
                color: white;
			}
		}


        &.pagination__current > a,
		&.pagination__current > span {
    		color: white;
            background-color: $primary;


            &:hover,
			&:focus {
            	// background: darken($primary, 15%);
			}
        }

		&.pagination__first,
		&.pagination__previous,
		&.pagination__next,
		&.pagination__last {

			& > a,
			& > span {
				font-size: 14px;
				line-height: 26px;
        	}
        }


        &.pagination__previous > a,
        &.pagination__previous > span {
			@extend .icon-arrow-button-prev;
			padding-right: 0;
        }


		&.pagination__next > a,
		&.pagination__next > span {
			@extend .icon-arrow-button-next;
			padding-left: 12px;
		}


        &.pagination__first,
		&.pagination__last {
			display: none;
		}


		&.pagination__first > a,
		&.pagination__first > span {
            @extend .icon-arrow-button-prev;
        }


		&.pagination__last > a,
		&.pagination__last > span {
            @extend .icon-arrow-button-next;
        }


        &.pagination--is-disabled > a,
        &.pagination--is-disabled > span {
			pointer-events: none;
			opacity: 0.5;
		}
	}
}