
.skip-link {
	position: absolute;
    left: 2rem;
    top: -101%;
    z-index: 99;
    box-shadow: 0 5px 0 rgba(0,0,0,0.2);


    &:focus {
	    top: 2rem;
	}


	&.skip-link--nav-main {
		display: none;
	}


	@include media-breakpoint-up(lg) {


		&.skip-link--nav-main {
			display: inline-block;
		}
	}
}
