.cc-window {
	z-index: 99999;
	font-size: $font-size-xs;
	position: relative;
	color: $white;
	background-color: $text-color;
	/*
	background-color: $primary;
	*/
	/*
	color: $text-color;
	background-color: $gray-200;
	*/
	opacity: 1;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	border-top: 1px solid $white;
	
	
	.cc-link {
		text-decoration: underline;
		font-weight: 600;
		color: $text-color;
		color: white;
		
		
		&:hover {
			text-decoration: none;
		} 
	}
	
	
	.cc-message {
		float: left;
		padding: ($spacer * 1.25) ($spacer * 1) 0 ($spacer * 1);
	}
	
	
	.cc-compliance {
		float: right;
		padding: ($spacer * 1.25) ($spacer * 1);
	}
	
	
	.cc-dismiss {
		/*
		position: absolute;
		top: ($spacer * 1.25);
		right: ($spacer * 1);
		*/
		position: relative;
		color: $text-color;
		color: white;
		padding-right: 40px;
		cursor: pointer;
		text-indent: -10000em;
		
		
		&:before {
			content: "";
			border: 1px solid $text-color;
			border: 1px solid white;
			border-radius: 50%;
			display: block;
			width: 30px;
			height: 30px;
			position: absolute;
			right: 0;
			top: -5px;
		}
		
		
		&:after {
			content: "\f106";
			font-family: "iconfont";
			display: block;
			height: 30px;
			width: 30px;
			position: absolute;
			right: 0;
			top: -5px;
			font-size: 14px;
			text-align: center;
			line-height: 30px;
			text-indent: 0;
		}
	}
}


.cc-invisible {
	display: none !important;
}


@media print {
	
	.cc-window {
		display: none !important;
	}
}
