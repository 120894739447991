
.carousel {
	position: relative;
	// margin-left: -30px;
	// margin-right: -30px;

	@include media-breakpoint-up(md) {
		margin-left: 0;
		margin-right: 0;
	}


	.image {
		width: 100%;
	}
}


.carousel__wrap {
    // display: none;
    visibility: hidden;


    &.slick-initialized {
		// display: block;
        visibility: visible;
    }


	&.slick-slider {
		position: static !important;
	}


	@include media-breakpoint-up(sm) {
		padding-bottom: 0;
	}


	.slick-list {
		margin-bottom: ($spacer * 2.5);


		@include media-breakpoint-up(md) {
			margin-bottom: ($spacer * 1);
		}
	}
}


.carousel__nav {

	.slick-arrow {
		position: absolute;
		bottom: 0;
		// display: block;
		border: 0;
		background: transparent;
		box-shadow: none !important;
		cursor: pointer;
		width: auto;
		height: auto;
		padding: 0;
		z-index: 1;
		outline: none;


		&:before {
			font-size: 35px;
			color: $text-color;
			transition: $transition-base;
		}


		&:hover,
		&:active {

			&:before {
				color: $blue;
			}
		}


		&.slick-prev  {
			left: 5px;
			@extend .icon-slider-arrow__prev;
		}


		&.slick-next  {
			right: -15px;
			text-align: right;
			@extend .icon-slider-arrow__next;
		}


		@include media-breakpoint-up(md) {
			bottom: auto;
			top: 50%;
			transform: translateY(-50%);


			&.slick-prev  {
				left: -45px;
			}


			&.slick-next  {
				right: -75px;
			}


			&:before {
				font-size: 60px;
			}
		}
	}


	.slick-dots {
		display: none;


		@include media-breakpoint-up(md) {
			display: block;
		}

		li button {
			cursor: pointer;
		}
	}
}


.carousel__status {
	// display: block;
	text-align: center;
	color: $gray-500;


	@include media-breakpoint-up(md) {
		display: none;
	}
}


.slick-arrow.slick-hidden + .carousel__dots + .carousel__status {
	display: none;
}


.carousel__aspect-ratio {
	background: $gray-100;
	padding: 0;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;


	.media,
	.image,
	.image a,
	.image picture {
		// height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}


	img {
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
		margin: 0 auto;
	}
}


.carousel--col-1 .carousel__aspect-ratio {
	padding: 0;
}


.carousel__item {


	.media__caption {
		display: none;
	}
}


.carousel--col-3 {


	.slick-list {
		margin: 0;


		@include media-breakpoint-up(sm) {
			margin: 0 -15px;
		}
	}


	.carousel__item {


		.media {
			margin: 0 15px ($spacer * 1);
		}
	}
}


.carousel__item,
.featured-item {
	align-self: stretch;
}





