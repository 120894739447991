// Headings
// -------------------------

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-top: 0;
	display: block;


	@include media-breakpoint-down(sm) {
		word-break: break-word;
		hyphens: auto;
	}
}


h1,
.h1 {
	font-weight: 300;
	margin-bottom: ( $spacer * 3.5 );
	color: $primary;


	@include media-breakpoint-down(xs) {
		font-size: $font-size-lg;
		line-height: 1.6;
		margin-bottom: ( $spacer * 1.5 );
	}
}


h2,
.h2 {
	margin-bottom: ( $spacer * 1.5 );


	@include media-breakpoint-down(xs) {
		font-size: $font-size-md;
		margin-bottom: ( $spacer * 1 );
	}
}


h3,
.h3 {
	margin-bottom: ( $spacer * 1 );
}


h4,
.h4 {
	margin-bottom: ( $spacer * 1 );
}


h5,
.h5 {
	margin-bottom: ( $spacer * 1 );
}


h6,
.h6 {
	font-weight: 300;
	margin-bottom: ( $spacer * 1 );
}