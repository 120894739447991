// Link
// -------------------------


p,
li {
	a {
		color: $link-color;
		text-decoration: $link-decoration;
		transition: $transition-base;


		&:hover,
		&:active,
		&:focus {
			color: $link-hover-color;
			text-decoration: $link-hover-decoration;
		}
	}
}



a {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		transition: $transition-base;
		color: $text-color;
		text-decoration: none;
	}


	&:hover,
	&:active,
	&:focus {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		.h1,
		.h2,
		.h3,
		.h4,
		.h5,
		.h6 {
			color: $blue;
			text-decoration: none;
		}
	}
}
