
// $btn-box-shadow: 0;
// $btn-box-shadow--active: 0;




.btn-primary {
	color: $white;
	background-color: $blue;
	border-color: $blue;


	&:hover,
	&:focus,
	&:active,
	&:active:focus,
	&.focus,
	&.active {
		color: $white;
		background-color: $blue-darker;
		border-color: $blue-darker;
    }

}


.btn-secondary {
	color: $blue;
	background-color: $gray-200;
	border-color: $gray-200;


	&:hover,
	&:focus,
	&:active,
	&:active:focus,
	&.focus,
	&.active {
	    color: $blue-darker;
		background-color: $gray-400;
		border-color: $gray-400;
    }
}


.btn-lightblue {
	color: $blue;
	background-color: $blue-another-kind;
	border-color: $blue-another-kind;


	&:hover,
	&:focus,
	&:active,
	&:active:focus,
	&.focus,
	&.active {
	    color: $blue-darker;
		background-color: $blue-light;
		border-color: $blue-light;

    }
}


.btn-outline-primary {
	color: $blue;
	background-color: $white;
	border-color: $gray-400;


    &:hover,
    &:focus,
    &:active,
    &.focus,
    &.active {
	    color: $blue-darker;
		background-color: $gray-200;
		border-color: $gray-400;
    }
}


.btn-outline-secondary {
    color: $text-color;
	background-color: $white;
	border-color: $blue;


    &:hover,
    &:focus,
    &:active,
    &.focus,
    &.active {
	    color: $text-color;
		background-color: $gray-200;
		border-color: $blue;
    }
}




// Button with icon


.btn-icon-left,
.btn-icon-right {
	position: relative;


	&:before {
		position: absolute;
		top: 50%;
		font-size: 1rem;
	}


	&.btn-lg:before {
		font-size: $font-size-md;
	}


	&.btn-sm:before {
		font-size: $font-size-xs;
	}
}


.btn-icon-left {
	padding-left: ($btn-padding-x * 1.75 );


	&:before {
		transform: translate(-50%, -50%);
		left: ($btn-padding-x * 1 );

	}


	&.btn-lg {
		padding-left: ($btn-padding-x-lg * 1.75);

		&:before {
			left: ($btn-padding-x-lg * 1 );
		}
	}


	&.btn-sm {
		padding-left: ($btn-padding-x-sm * 1.75);

		&:before {
			left: ($btn-padding-x-sm * 1 );
		}
	}
}


.btn-icon-right {
	padding-right: ($btn-padding-x * 1.75 );


	&:before {
		transform: translate(50%, -50%);
		right: ($btn-padding-x * 1 );
	}


	&.btn-lg {
		padding-right: ($btn-padding-x-lg * 1.75);


		&:before {
			right: ($btn-padding-x-lg * 1 );
		}
	}


	&.btn-sm {
		padding-right: ($btn-padding-x-sm * 1.75);


		&:before {
			right: ($btn-padding-x-sm * 1 );
		}
	}
}




// Reset/Reload button

.btn-reset {
	background-color: transparent;
	color: $gray-900;
	padding-left: 0;
	padding-right: 0;
	font-size: $font-size-xs;


	&:hover,
    &:focus,
    &:active,
    &.focus,
    &.active {
	    color: $primary;
		background-color: transparent;
		border-color: transparent;
    }


	&:before {
		margin-right: ($spacer * 0.75);
	}
}



// Accessibility!

a,
.btn,
button {
	@include focus();
}









/*
.btn-quadrat {


	@media (min-width: $screen-sm-min) {
		width: 60px;
		height: 60px;
		padding: 0;
	}
}
*/



/*
.btn-group {
	box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
	background: $brand-primary;


	.btn {
		background: $brand-primary;
		box-shadow: none;
		border: 0;
		border-left: 1px solid rgba(255,255,255,.5);


		&:first-of-type {
			border-left: 0;
		}


		&.btn-md {
			font-size: $font-size-base;
		}
	}


	.isCurrent {
		background: white;
		color: $brand-primary;

		&:before {
			content: "";
			position: absolute;
			left: 30%;
			bottom: 0;
			height: 1px;
			width: 40%;
			background: $brand-primary;
		}
	}
}
*/
