
.team {
    position: relative;


	.team__item {


		.team__image,
		.image {
			margin-bottom: ($spacer * 1);
		}


		.team__text {


			a {
				word-break: break-all;
				hyphens: all;
			}
		}


		.team__title {
			padding: 0;
			color: $text-color;
			position: relative;
			margin: 0 0 ($spacer * 0.125);
			z-index: 1;
			display: inline-block;
		}


		.team__mail,
		.team__profile {
			font-weight: 600;
		}


		p {
			color: $text-color;
			font-size: 15px;
			line-height: 22px;
			margin-bottom: ($spacer * 0);
			position: relative;
			hyphens: auto;


			.mail {
				padding-left: 0;


				&:before {
					display: none;
				}
			}
		}


		.read-more {
			display: inline;
			color: $link-color;
			-webkit-backface-visibility:hidden;
			transition:color .2s ease-in-out;
		}


		&:hover,
		&:focus {
			text-decoration: none;


			.h2:before {
				background: $primary;
			}


			.read-more {
				color: $link-hover-color;
			}
		}


		/*
		@media (max-width: 480px ) {


			&.col-xs-6 {
	    		width: 100%;
	    		clear: both;
	    		overflow: hidden;
	    	}
		}
		*/

		/*
	    @include media-breakpoint-down(xs) {


			&.col-xs-6:nth-child(odd) {
	    		clear: both;
	    		overflow: hidden;
	    	}
		}


		@include media-breakpoint-only(sm) {


	    	&.col-sm-4:nth-of-type(3n+1) {
	    		clear: both;
	    		overflow: hidden;
	    	}
	    }


	   @include media-breakpoint-up(md) {


	    	&.col-md-4:nth-of-type(3n+1) {
	    		clear: both;
	    		overflow: hidden;
	    	}


	    	&.col-md-3:nth-of-type(4n+1) {
	    		clear: both;
	    		overflow: hidden;
	    	}
	    }
	    */
	}
}


.team-widget {

	.team__wrap {
		background: $blue-lighter;
		padding: ($spacer * 2) $grid-gutter-width ($spacer * 1);
		overflow: hidden;
		clear: both;
		background-image: url("../images/bg_icons_teaser_kontakt.png");
		background-repeat: no-repeat;
		background-position: bottom -70px right 10px;


		@include media-breakpoint-up(md) {
			overflow: visible;
			box-shadow: 0 0 40px rgba(0,0,0, 0.1);
		}
	}


	.team-widget__row {

		@include media-breakpoint-up(lg) {
			padding-right: 33.333%;
		}
	}


	.team__heading {
		background: white;
		margin: -($spacer * 2) floor(-($grid-gutter-width * 1)) ($spacer * 2);
		padding: ($spacer * 1) $grid-gutter-width;
		z-index: 1;
		position: relative;
		color: $primary;
		font-size: 1.125rem;
	}


	.team__item {
		min-width: 320px;


		.team__image,
		.image {
			margin-bottom: 0;
			margin-right: $grid-gutter-width;
			width: 70px;
			height: 70px;
			border-radius: 50%;
			float: left;


			img {
				border-radius: 50%;
			}
		}


		.team__text {
			overflow: hidden;
		}
	}
}