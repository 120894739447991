@font-face {
	font-family: "Open Sans";
	src: local('☺'),
		 url("../statics/fonts/OpenSans-Light.woff2") format("woff2"),
		 url("../statics/fonts/OpenSans-Light.woff") format("woff"),
		 url("../statics/fonts/OpenSans-Light.otf") format("opentype");
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "Open Sans";
	src: local('☺'),
		 url("../statics/fonts/OpenSans-LightItalic.woff2") format("woff2"),
		 url("../statics/fonts/OpenSans-LightItalic.woff") format("woff"),
		 url("../statics/fonts/OpenSans-LightItalic.otf") format("opentype");
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: "Open Sans";
	src: local('☺'),
		 url("../statics/fonts/OpenSans-SemiBold.woff2") format("woff2"),
		 url("../statics/fonts/OpenSans-SemiBold.woff") format("woff"),
		 url("../statics/fonts/OpenSans-SemiBold.otf") format("opentype");
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "Open Sans";
	src: local('☺'),
		 url("../statics/fonts/OpenSans-SemiBoldItalic.woff2") format("woff2"),
	 	 url("../statics/fonts/OpenSans-SemiBoldItalic.woff") format("woff"),
	 	 url("../statics/fonts/OpenSans-SemiBoldItalic.otf") format("opentype");
	font-style: italic;
	font-weight: 600;
}
