// Aspect ratio mixin




[class^="aspect-ratio--"],
.aspect-ratio--3x2,
.aspect-ratio--2x3,
.aspect-ratio--3x4,
.aspect-ratio--4x3,
.aspect-ratio--16x9,
.aspect-ratio--1x2,
.aspect-ratio--2x1,
.aspect-ratio--1x1 {
	position: relative;
	

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: 75%;
		background: rgba(255,255,0,.7);
	}
	
	
	& > .aspect-ratio__item,
	& > object,
	& > video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}


// Ready-made aspect ratio classes


.aspect-ratio--3x2:before {
	padding-top: calc(100% * 2/3); 
}


.aspect-ratio--2x3:before {
	padding-top: calc(100% * 3/2); 
}


.aspect-ratio--3x4:before {
	padding-top: calc(100% * 4/3); 
}


.aspect-ratio--4x3:before {
	padding-top: calc(100% * 3/4); 
}


.aspect-ratio--16x9:before {
	padding-top: calc(100% * 9/16); 
}


.aspect-ratio--1x2:before {
	padding-top: calc(100% * 2/1); 
}


.aspect-ratio--2x1:before {
	padding-top: calc(100% * 1/2); 
}


.aspect-ratio--1x1:before {
	padding-top: calc(100% * 1/1); 
}










.embed-responsive-3by2 {
	padding-bottom: 66.666666%;
}


.embed-responsive-2by3 {
	padding-bottom: 66.666666%;
}


.embed-responsive-4by3 {
	padding-bottom: 66.666666%;
}


.embed-responsive-3by4 {
	padding-bottom: 66.666666%;
}


.embed-responsive-2by1 {
	padding-bottom: 50%;
}


.embed-responsive-1by2 {
	padding-bottom: 100%;
}