// Checkout Steps
// =========================================================

.steps {
	text-align: left;
	width: 100%;


	.btn-primary {
		background: $white-ice;
		color: $black;
	}


	.steps__button {
		flex: 0 1 25%;
		border: 0;
		border-left: 1px solid $blue-light;
		border-right: 1px solid $blue-light;
		pointer-events: none;
	}

	.steps__button:first-of-type {
		border-left: 0;
	}

	.steps__button:last-of-type {
		border-right: 0;
	}

	.steps__button--is-current {
		background: $curious-blue;
		color: $white;
		border-left: 1px solid $gray-300;
		z-index:1;
	}

	.steps__button--is-done {
		background: $curious-blue;
		color: $white;
		border-left: 1px solid $white;
		z-index:1;

		&:before {
			@extend .icon-tickbox;
			display: inline-block;
			position: absolute;
			font-size: 0.75rem;
		    left: 0.75rem;
		    top: 50%;
		    transform: translateY(-50%);
		}
	}

}


// Shoping Cart Steps
// =========================================================
.cart__head {
		border-top: ($border-width * 8) solid $gray-100;
		padding: ($spacer * 0.5) 0;
		border-bottom: ($border-width * 1) solid $gray-300;
		display: flex;
		& > div {
			color: $black;
		}
}

.shop__cart {
	.cart__header {
		border-top: ($border-width * 8) solid $gray-100;
		padding: ($spacer * 0.5) 0 0 0;
		border-bottom: 0; //($border-width * 1) solid $gray-300;
		display: flex;
		& > div {
			color: $black;
		}

	}

	.cart__header--quantity {
		text-align: center;
	}

	.cart__header--price {
		text-align: right;
		padding-right: ($spacer * 1.5);
	}
}

.total__price,
.order-options {
	.cart__header .cart__header--product {
		width: 45%;
		font-weight: $font-weight-bold;
		font-size: $font-size-md;
	    line-height: 122%;
		padding-left: ($spacer * 1);
	}

	.cart__header .cart__header--quantity {
		width: 40%;
	}

	.cart__header .cart__header--price {
		font-weight: $font-weight-bold;
	}

	.cart__header:first-of-type {
		border-top: 0; //($border-width * 8) solid $gray-100;
	}

	.cart__header {
		border-top: ($border-width * 1) solid $gray-300;
	}

	.cart__header--quantity {
		@extend .cart-col--quantity;
		text-align: right;
		padding-right: ($spacer * 1.5);
	}

	.cart__header--price {
		@extend .cart-col--total;
		text-align: right;
		padding-right: ($spacer * 1.5);
		font-weight: $font-weight-bold;
	}

	.cart__header:last-of-type {
		border-bottom: ($border-width * 8) solid $gray-100;
	}

	.cart__header--product {
		font-weight: $font-weight-bold;
	}

	.cart-header--total {
		padding: ($spacer * 2) 0;
		background: $white-ice;
		border: 0px !important;
	  // padding-bottom: 2.5rem;

    	& > .cart__header--price {
			font-size: $font-size-md;
		    line-height: 122%;
		}
	}
}

.order-options .cart__header:last-of-type {
    padding-bottom: 2rem;
}

.shop--checkout {
	input:focus::placeholder,
	textarea:focus::placeholder {
		opacity: .2;
	}
	.shop-heading {
		// border-bottom: ($border-width * 8) solid $gray-100;
		color: ($black);
		font-size: ($font-size-md);
    	border-bottom: 0;
	}
	.subdivision {
		border-top: ($border-width * 1) solid $gray-300;
		border-bottom: ($border-width * 1) solid $gray-300;

		& .checkbox {
		    margin-bottom: -.5rem;
		}
	}

	.misc {
		border-top: ($border-width * 8) solid $gray-100;
		border-bottom:($border-width * 8) solid $gray-100;
		padding: ($spacer * 2) 0;
	}
	.required-fields-info {
		text-align: right;
		border-bottom: 0;
		color: ($black);
	}
}

.shop__checkout--orderdate {
	padding-top: ($spacer * 2);
	border-top: ($border-width * 8) solid $gray-100;
}

.shop__checkout--orderdate dt {
	float: left;
	width: 25%;
}

.payment,
.product_list {
	// border-bottom: ($border-width * 8) solid $gray-100;
}

.shop__item--checkout {
	padding-bottom: ($spacer * 2);
	margin-bottom: ($spacer * 2);
	border-bottom: ($border-width * 1) solid $gray-300;

	&:first-of-type {
		margin-top: ($spacer * 2);
	}

	&:last-of-type {
		border-bottom: ($border-width * 8) solid $gray-100;
		padding-bottom: ($spacer * 2);
		margin-bottom: ($spacer * 2);
	}

	.shop__item--image {
		padding-right: ($spacer *1);
	}

	.shop__item--headline,
	.shop__item--count,
	.shop__item--price {
		align-self: center;
	}

	.icon-shop-delete {
		font-weight: $font-weight-bold;
		font-size: $font-size-lg;
		color: $blue;
	}

	.shop__item--headline h4 {
		font-weight: $font-weight-bold;
		font-size: $font-size-md;
		margin: 0;
	}

	.shop__item--price {
		text-align: right;
	    padding-right: ($spacer * 1.5);
	    font-weight: $font-weight-bold;
		font-size: $font-size-md;
	}

	.shop__item--input {
		width: ($spacer * 4);
	    text-align: center;
	    height: ($spacer * 3);
		align-self: center;
	}
}


.card-item__put-to-cart {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: stretch;
	margin-bottom: 0;

	@include media-breakpoint-up(lg) {
		justify-content: center;
	}
}


.reload-cart .btn--reload {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    display: none;
}

.reload-cart .shop__item--input-change {
	-webkit-transition: visibility 0, opacity .3s ease-in;
	transition: visibility 0, opacity .3s ease-in;
	visibility: visible;
	opacity: 1;
	display: inline-block;
	pointer-events: auto;
}
