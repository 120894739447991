
.glossary-list__term {
	padding-top: ($spacer * 1.5);
	margin-top: ($spacer * 1.5);
	border-top: $border-base;
}


.glossary-list__heading {
	display: inline-block;
}


.glossary-list__text {
	margin-bottom: 0;
}


.glossary-list__more-link {
	font-weight: $font-weight-bold;
}


.glossary-list__definition:last-of-type {
	padding-bottom: ($spacer * 1.5);
	border-bottom: $border-base;
}
