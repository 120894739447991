
.headline__border {
	border-bottom: 1px solid $gray-300;
}

.headline--widget {

	.h3,
	.h4 {
		font-size: ($font-size-base * 1.125);
		text-transform: uppercase;
		margin-bottom: ($spacer * 0.5);
	}
}



/*
.categoryheader {
	position: relative;
	margin-top: floor( -( @line-height-computed * 1 ));
	margin-left: floor( -( @grid-gutter-width / 2 ));
	margin-right: floor( -( @grid-gutter-width / 2 ));
	margin-bottom: floor(( @line-height-computed * 1.5 ));

	.media {
		max-width: none;
	}

	.categories {
		bottom: -15px;
		left: 0;
		z-index: 1;
		position: absolute;
		margin: 0 60px 0 0 !important;

		span {
				font-size: @font-size-h2-mobile;
			}

		.h2 {
			margin: 0;
			hyphens: auto;
		}

	}

	@media (min-width: @screen-sm-min) {
		margin-bottom: floor(( @line-height-computed * 1 ));

		.categories {
			bottom: -20px;

			span {
				font-size: @font-size-h2;
			}

		}

	}

	@media (min-width: @screen-md-min) {
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
	}

}
*/