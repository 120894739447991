


.teaser__item {
	position: relative;
	z-index: 1;


	@include media-breakpoint-down(xs) {
		margin-bottom: 5px !important;
	}
}


.teaser__wrap {
	background: white;
	padding: ($spacer * 1.5) 0 ($spacer * 3);
	overflow: hidden;
	clear: both;
	position: relative;

	@include media-breakpoint-up(sm) {
		overflow: visible;
		box-shadow: 0 0 40px rgba(0,0,0, 0.1);
	}
}


.teaser__head {
	position: relative;
	margin-top: -($spacer * 1.5);


	@include media-breakpoint-up(lg) {
		margin-top: -($spacer * 1.5);
	}
}


.teaser__subcategory {
	padding: ($spacer * 1.5) ($grid-gutter-width * 0.666667);
	margin-bottom: 0;
	position: relative;
	z-index: 1;
	color: $primary;
	@extend .h4;


	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 66px;
		width: 8px;
	}


	@include media-breakpoint-up(md) {
		padding: ($spacer * 1.5) ($grid-gutter-width * 1);


		&:before {
			height: 87px;
		}
	}
}


.teaser__category--informieren {
	position: absolute;
	right: 15px;
	top: 8px;
	line-height: 46px;
	font-size: 36px;
	@extend .icon-teaser-birne;
}


.teaser__category--mitmachen {
	position: absolute;
	right: 20px;
	top: 8px;
	line-height: 46px;
	font-size: 36px;
	@extend .icon-teaser-sonne;
}


.teaser__category--aus-dem-leben {
	position: absolute;
	right: 20px;
	top: 8px;
	line-height: 46px;
	font-size: 36px;
	@extend .icon-teaser-herz;
}


.teaser__category--logo {
	position: absolute;
	right: 20px;
	top: 15px;


	img {
		max-height: 36px;
		width: auto;
	}
}


@each $key, $value in $areas {

	.page-type--#{$key} .teaser__item,
	.teaser__item--#{$key} {

		.teaser__subcategory {
			color: $value !important;


			&:before {
				background: $value;
			}
		}


		.teaser__category--informieren,
		.teaser__category--mitmachen,
		.teaser__category--aus-dem-leben {
			color: $value !important;
		}
	}
}

// Improved class naming

@each $key, $value in $area-colors {

	.page-type--#{$key} .teaser__item,
	.teaser__item--#{$key} {

		.teaser__subcategory {
			color: $value !important;


			&:before {
				background: $value;
			}
		}


		.teaser__category--informieren,
		.teaser__category--mitmachen,
		.teaser__category--aus-dem-leben {
			color: $value !important;
		}
	}
}


.teaser__deckhead {
	margin-bottom: ($spacer * .25);
	padding: 0 ($grid-gutter-width * 0.666667);


	@include media-breakpoint-up(md) {
		padding: 0 ($grid-gutter-width * 1);
	}
}


.teaser__title {
	font-size: $font-size-base;
	margin-bottom: ($spacer * .5);
	padding: 0 ($grid-gutter-width * 0.666667);


	@include media-breakpoint-up(md) {
		font-size: $h3-font-size;
		margin-bottom: $spacer;
		padding: 0 ($grid-gutter-width * 1);
	}
}


.teaser__text {
	padding: 0 ($grid-gutter-width * 0.666667) ($spacer * 1.5);


	@include media-breakpoint-up(md) {
		padding: 0 ($grid-gutter-width * 1) ($spacer * 1.5);
	}


	p {
		margin-bottom: 0;
	}
}

.teaser__image + .teaser__text {
	padding-top: ($spacer * 1.5);
}




.teaser__image {


	@include media-breakpoint-down(xs) {
		float: right;
		width: 120px;
		margin-left: 20px;
	}

	@include media-breakpoint-up(sm) {
		margin-top: -($spacer * 1.5);
	}
}

.teaser__head + .teaser__image {

	@include media-breakpoint-up(sm) {
		margin-top: 0;
	}
}



@include media-breakpoint-up(sm) {

	.teaser__image--book {
		text-align: center;
		background: #f7f7f7;
		padding-bottom: calc(100% * 2/3);


		.image__book {
			display: flex;
			align-items: center;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}


		img {
			width: auto;
			height: 100%;
			position: relative;
		}


		.image__shadow {
			display: inline-block;
			position: relative;
			align-self: center;
			margin: 0 auto;
			margin: 13% auto 12%;
			height: 75%;


			&:before {
				content: "";
				position: absolute;
				right: -15px;
				bottom: 0;
				width: 30px;
				height: 30px;
				background: #c6c6c6;
				transform: skewX(-45deg);
			}
		}


		.image__credits {
			background: transparent;
			color: #c6c6c6;
			padding: 4px;
		}
	}
}



.teaser__lang-accessibility {
	color: #fff;
	position: absolute;
	left: 0;
	bottom: 0;
	padding-top: 10px;
	padding-left: 32px;
	padding-bottom: 3px;
	height: 43px;


	@include media-breakpoint-up(md) {
		padding-left: 42px;
	}


	.lang-accessibility__icon {
	    line-height: 1;
	}


	.lang-accessibility__label {
		line-height: 11px;
		padding: 0;
	}


	&.lang-accessibility__indicator--easy {
		background: $primary;

		@include media-breakpoint-up(sm) {
			background: $gray-100;
			color: $primary;
		}
	}


	&.lang-accessibility__indicator--difficult {
		background: $danger;

		@include media-breakpoint-up(sm) {
			background: $gray-100;
			color: $danger;
		}
	}
}


.teaser__read-more {
	background: $primary;
	border-color: $primary;
	color: #fff;
	font-weight: 600;
	border-radius: 0;
	position: absolute;
	right: 0;
	bottom: 0;
	font-size: $font-size-xs;
	display: none; // ???


	@include media-breakpoint-up(sm) {
		background: #f7f7f7;
		border-color: #f7f7f7;
		color: $primary;
	}
}




// LINK WRAP
.teaser__item {


	a {
		text-decoration: none;
		display: block;


		.teaser__title {
			color: $text-color;
		}


		.teaser__text p {
			color: $text-color;
		}


		.teaser__read-more  {
			display: inline-block;
		}

		.teaser__subcategory {
			color: $primary;
		}


		&:hover,
		&:focus {
			text-decoration: none;

			.teaser__wrap {
				box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
			}


			.teaser__title {
				color: $primary;
			}


			.btn {
				background: $primary;
				color: $white;
				border-color: $primary;
			}


			.teaser__category--informieren,
			.teaser__category--mitmachen,
			.teaser__category--aus-dem-leben {
				color: $primary;
			}

		}

		@include focus();
	}
}


	// LINK SOLO
	.teaser__title a {
		color: $primary;
	}




.teaser__item--network {

	.teaser__wrap {
		background: $blue;
	}

	a .teaser__title {
		text-align: center;
		color: $white !important;
	}

	.teaser__image {
		padding: 1rem 3rem;
		margin: 0 !important;

		float: none !important;
		width: auto !important;


		img {
			margin: 0 auto;
			max-width: 220px;
		}
	}


	.teaser__read-more {
	    background: rgba(255,255,255,.1) !important;
	    border-color: transparent !important;
	    color: $white;
	}


	a:hover {

		.teaser__wrap {
			opacity: .9;
		}
	}
}




.teaser {


	&.teaser--col-2,
	&.teaser--col-3,
	&.teaser--col-4,
	&.teaser--col-5 {
/*
		.teaser__image {
			float: left;
			width: 120px;
			padding-right: floor(( $grid-gutter-width * 0.5 ));


			@include media-breakpoint-up(xs) {
				width: 100%;
				padding-right: 0;
				float: none;
			}
		}

		.teaser__title {
			font-size: $h3-font-size;
		}
*/
	}


	&.teaser--fitinrow {

		&.teaser--col-2 {

			.teaser__item {

				@include media-breakpoint-up(sm) {

					&:nth-of-type(2n+1) {
						clear: both;
					}
				}
			}
		}


		&.teaser--col-3 {

			.teaser__item {

				@include media-breakpoint-between(sm, md) {

					&:nth-of-type(2n+1) {
						clear: both;
					}
				}


				@include media-breakpoint-up(md) {

					&:nth-of-type(3n+1) {
						clear: both;
					}
				}
			}
		}


		&.teaser--col-4 {

			.teaser__item {

				@include media-breakpoint-between(sm, md) {

					&:nth-of-type(2n+1) {
						clear: both;
					}
				}


				@include media-breakpoint-up(md) {


					&:nth-of-type(4n+1) {
						clear: both;
					}
				}
			}
		}


		&.teaser--col-5 {

			.teaser__item {

				@include media-breakpoint-between(sm, md) {

					&:nth-of-type(2n+1) {
						clear: both;
					}
				}


				@include media-breakpoint-up(md) {
					width: 20%;


					&:nth-of-type(5n+1) {
						clear: both;
					}
				}

			}
		}
	}


	&.teaser--slideshow {


		.slick-list {
			padding-top: 20px;
			margin-top: -20px;
		}


		.teaser__item {
			display: block !important;
			flex: none !important;
		}


		.teaser__wrap {
			box-shadow: 0 0 18px rgba(0,0,0,.1);
		}
	}
}




		.slick-dots {
			padding: 0;
			margin-bottom: 0;
			text-align: center;


			@include media-breakpoint-up(md) {
				padding: 0;
				line-height: 0 !important;
			}


			li {
				display: inline;
				font-size: 12px;
				line-height: 1;
				padding: 0 4px !important;


				&:before {
					display: none;
				}


				button {
					border: 0;
					background: $blue-light;
					box-shadow: none !important;
					width: auto;
					padding: 0;
					border: 0 solid red;
					border-radius: 50%;
					height: 12px;
					width: 12px;
					text-indent: -10000em;
					transition: background .2s ease-in-out;
				}


				&:hover,
				&:focus,
				&:active,
				&.slick-active {


					button {
						background: $primary;
					}
				}
			}
		}