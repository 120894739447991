
// ===============================
// Solr Search
// ===============================


$facet-height: ($spacer * 2);
$facet-color: $blue;
$facet-color-active: #fff;
$facet-bg-color: $gray-200;
$facet-bg-color-active: $blue;


.solr {
	margin-bottom: ($spacer * 2);
}


.solr-type-list,
.facet-list {
	display: flex;
	list-style: none outside;
	padding: 0;
	margin: 0;
}


.solr-type-list {
	flex-direction: column;
	margin-bottom: ($spacer * 2);
}


.solr-type-list__label {
	flex: 1 0 100%;
	font-size: $font-size-small;
	padding-top: ($spacer * 1);


	@include media-breakpoint-up(md) {
		min-width: 150px;
		flex: 1 0 15%;
		max-width: 15%;
		padding-top: ($spacer * 0.5);
		margin: 0;
	}
}


.solr-type-list__item {
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;


	@include media-breakpoint-up(md) {
		flex-wrap: nowrap;
	}
}


.facet-list {
	flex-direction: row;
	flex-wrap: wrap;
}


.facet-list__item {
	margin: 0 ($spacer * 0.5) ($spacer * 0.5) 0;


	a,
	& > span  {
		font-size: $font-size-small;
		position: relative;
		text-decoration: none;
		font-weight: 700;
		display: block;
		padding: 0 ($spacer * 0.75);
		white-space: nowrap;
		line-height: $facet-height;
		background: $facet-bg-color;
		color: $facet-color;


		span {
			font-weight: 400;
			display: inline-block;
			padding-left: ($spacer * 0.5);
		}
	}


	a {
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			background: $facet-bg-color-active;
			color: $facet-color-active;
		}
	}


	&.facet-list__item--is-active a {
		padding-right: ($spacer * 2.5);
		text-decoration: none;
		background: $facet-bg-color-active;
		color: $facet-color-active;
		@extend .icon-tickbox;


		&:before,
		&:after {
			position: absolute;
			font-size: 12px;
			display: block;
			top: 0;
			bottom: 0;
			right: 0;
			background: $blue-darker;
			width: $facet-height;
			text-align: center;
			line-height: $facet-height;
			opacity: 0;
			transition: $transition-base;
		}


		&:before {
			opacity: 1;
		}


		&:after {
			content: "\f106";
			font-size: 14px;
			// Repeating everything b/c we can't extent the ::after element…
			display: inline-block;
			font-family: "iconfont";
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-decoration: inherit;
			text-rendering: optimizeLegibility;
			text-transform: none;
			font-smoothing: antialiased;
		}


		&:hover,
		&:focus,
		&:active {


			&:after {
				opacity: 1;
			}
		}
	}


	&.facet-list__item--is-inactive span {
		color: $gray-500;
	}
}



// Meta

.solr__status {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
    justify-content: space-between;
    flex-wrap: wrap;


	p {
		margin-bottom: 0;
		color: $gray-600;
		flex: 1 0 100%;
	}
}


.solr__meta {
	padding-top: ($spacer * 2);
	position: relative;


	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: ($grid-gutter-width / 2);
		right: ($grid-gutter-width / 2);
		height: 4px;
		background: $gray-200;
	}
}


.btn-showall-accessible {
	margin-bottom: ($spacer * 2);
	padding-left: 3rem;


	&:after {
		content: "\f11e";
		position: absolute;
		top: 50%;
		left: 1.5rem;
		transform: translate(-50%, -50%);
		// Repeating everything b/c we can't extent the ::after element…
		display: inline-block;
		font-family: "iconfont";
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-decoration: inherit;
		text-rendering: optimizeLegibility;
		text-transform: none;
		font-smoothing: antialiased;
		font-size: 36px;
	}
}


.btn-reset--solr {
	margin-bottom: ($spacer * 1);
}




