
.image {
	overflow: hidden;
	backface-visibility: hidden;
	position: relative;
}

.lightbox {
	display: block;
	position: relative;

	&:hover,
	&:active,
	&:focus {

		.icon-lightbox {
			opacity: 1;
			@include focus();
		}
	}
}


.icon-lightbox {
	text-shadow: rgba(0,0,0,0.4) 0 0 20px;
	position: absolute;
	height: 60px;
	width: 60px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	@extend .icon-meta-suche;
	font-size: 60px;
	line-height: 1;
	color: #fff;
	opacity: 0;
	transition: $transition-base;
}




















// BACKUP - new code above is less specific

/*
.image {
	overflow: hidden;
	backface-visibility: hidden;
	position: relative;


	.lightbox {
		display: block;
		position: relative;
		// background: $primary;


		.icon-lightbox {
			text-shadow: rgba(0,0,0,0.4) 0 0 20px;
			position: absolute;
			height: 60px;
			width: 60px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@extend .icon-meta-suche;
			font-size: 60px;
			line-height: 1;
			color: #fff;
			opacity: 0;
			transition: $transition-base;
		}
	}


	&:hover,
	&:active {

		a.lightbox {
			// background: #000;


			i.icon-lightbox {
				opacity: 1;
			}
		}
	}
}
*/















/*
@media (min-width: $screen-sm-min) {
	pointer-events: all;


	&:hover:before,
	&:active:before {
		background: $brand-primary;
	}
}
*/









/* overwriting original fancybox img-paths start */

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
	background-image: none !important;
}


#fancybox-loading div {
	background-image: none !important;
}


.fancybox-opened {
    z-index: 9999;
}


.fancybox-overlay {
	z-index: 9998;
	background-color: rgba(255,255,255,.95) !important;
	cursor: pointer;
}


.fancybox-close {
    text-decoration: none !important;
    text-indent: -9999em;
    right: 120px;
    top: 0px;
    width: 20px;
	height: 20px;
	color: $text-color;
	@extend .icon-close;


	&:before {
		display: block;
		text-indent: 0em;
		font-size: 20px;
		line-height: 20px;
		text-align: center;
		transition: all .2s ease-in-out;
	}


	&:hover {
    	color: $blue;
	}
}


.fancybox-skin {
	border-radius: 0;
	padding: 60px 120px !important;
	background: none !important;
}


.fancybox-opened .fancybox-skin {
	box-shadow: none;
}


.fancybox-nav {
	position: absolute;
	width: 60px;
	height: 60px;
	display: block;
	top: 50%;
	transform: translateY(-50%);


	&:before {
		font-size: 60px;
		line-height: 60px;
		color: $text-color;
		transition: all .2s ease-in-out;
		position: absolute;
		text-align: center;
		width: 60px;
	}


	&:hover {
		text-decoration: none;
		color: $blue;
	}


	span {
		display: none !important;
	}


	&.fancybox-prev {
		left: -47px;
		@extend .icon-slider-arrow__prev;
	}


	&.fancybox-next {
		right: -81px;
		@extend .icon-slider-arrow__next;
	}
}


.fancybox-error {
	color: red;
	padding: 0 0 20px !important;
}

/* overwriting original fancybox img-paths end */



.fancybox-title {
	text-align: left;


	.text {
		margin: 0;
		min-height: 70px;
		max-width: 700px;
	}
}


.fancybox-title-inside-wrap {
	color: $text-color;
    padding: 5px 0 0;


	.credits,
	.caption {
	    position: relative;
		font: 400 16px/1.5 $font-family-base;
	}

	.caption {
		margin-bottom: 0;
	}


	a.download  {
		float: right;
		display: block;
		padding: 0 0 0 32px;
		width: 0;
		height: 32px;
		color: $text-color;
		overflow: hidden;
		position: absolute;
		right: 0px;
		margin: 30px 0px 0px;


		&:before {
			position: absolute;
			left: 1px;
			top: 0;
			font-size: 32px;
			line-height: 100%;
		}
	}
}