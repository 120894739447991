
// Full-width background
// Adds a full-width background regardless outer container's width


@mixin wide-bg($bg-color) {
	position: relative;
	overflow: visible;


	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		height: 100%;
		left: -2000px;
		width: 4000px;
		background: $bg-color;
		z-index: 0;
	}
}