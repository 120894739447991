
// META NAVIGATION

.header__meta-nav {
	flex: 1 0 30%;
	align-self: stretch;
}



.meta-nav__list {
	list-style: none outside;
	margin: 0;
	padding: 0;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.meta-nav__item {
	z-index: 9;
	align-self: stretch;
	display: flex;
	align-items: center;
	position: relative;


	> a,
	> span {
		font-size: $font-size-xs;
		font-weight: $font-weight-bold;
		color: $black;
		padding: ($spacer / 2) 0;
		transition: $transition-base;


		&:after {
			content: "";
			display: block;
			height: 0;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transition: $transition-base;
		}


		&:hover,
		&:active,
		&:focus {
			color: $blue-darker;
			text-decoration: none;


			&:after {
				background: $blue-darker;
				height: 6px;
			}
		}
	}


	&.is-active > a,
	&.is-active > span {
		color: $blue-darker;


		&:after {
			background: $blue-darker;
			height: 6px;
		}
	}


	&.meta-nav__item--intern,
	&.meta-nav__item--logout {


		> a,
		> span {
			padding-right: 32px;


			&:before {
				position: absolute;
				display: block;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				font-size: 28px;
			}
		}
	}

	&.meta-nav__item--logout {

		> a,
		> span {
			// color: $blue;
			@extend .icon-meta-logout;


			&:hover,
			&:active,
			&:focus {
				// color: $black;

				&:after {
					// background: $black;
				}
			}
		}
	}


	&.meta-nav__item--intern {

		> a,
		> span {
			@extend .icon-meta-login;
		}
	}
	
	
	&.meta-nav__item--back {


		> a,
		> span {
			@extend .icon-link_intern;
			padding-left: 32px;


			&:before {
				position: absolute;
				display: block;
				left: 0;
				top: 50%;
				transform: translateY(-50%) rotate(-180deg);
				font-size: 22px;
			}
		}
	}
}



.meta-nav__list {
	justify-content: flex-start;

	.meta-nav__item {
		margin-right: 20px;
	}
}


// DONATE

.header__donate {
	z-index: 9;
	height: 100%;
	flex: 0 0 270px;
	align-self: stretch
}

.donate__btn {
	height: 100%;
	display: block;
}



