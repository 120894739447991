.top-scroll__fixed {
	position: fixed;
	height: 48px;
	width: 48px;
	// border-radius: 50%;
	background: $white;
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
	color: $primary;
	text-indent: -10000em;
	cursor: pointer;
	z-index: 999;
	transition: all 0.2s ease-in-out, bottom 400ms ease-in-out;
	right: 0;
	bottom: -60px;


	@include media-breakpoint-up(sm) {
		right: 40px;
		bottom: -60px;
	}

	&.is-visible {
		bottom: 0;

		@include media-breakpoint-up(sm) {
			bottom: 30px;
		}
	}

	@extend .icon-arrow-dropdown;

	&:before {
		display: inline-block;
		text-indent: 0;
		position: absolute;
		line-height: 1;
		text-align: center;
		/*
		top: -50%;
		left: 50%;
		font-size: 40px;
		transform: rotate(-180deg) translate(-50%,-50%);
		*/
		color: $primary;
		line-height: 1;

		top: 15px;
		left: 16px;
		font-size: 16px;
		transform: rotate(-180deg);
	}
	
	
	&:hover {
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
	}
}


@media print {

	.top-scroll__fixed {
		display: none !important;
	}
}