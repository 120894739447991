.facts__item {
	margin-bottom: ($spacer * 1);
	
	
	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}
}


.facts__title {
	line-height: 1.6;
	margin-bottom: 0;
	
	
	@include media-breakpoint-up(md) {
		margin-bottom: ($spacer * 1);
	}
}


.facts__panel {

	p,
	ul,
	ol,
	address {
		margin-bottom: ($spacer * 1);
	}
}

/*
.facts {
	position: relative;


	& > [class^="col-"] {
		padding-top: floor( (@line-height-computed * 0.5) + 5) ;


		&:before {
			content: "";
			display: block;
			position: absolute;
			height: 5px;
			background-color: @brand-primary-light;
			top: 0;
			left: 15px;
			right: 15px;
		}
	}


	.facts__item {
		position: relative;
		margin-bottom: floor(@line-height-computed * 0.5);


		&:before {
			content: "";
			display: block;
			position: absolute;
			height: 1px;
			background-color: @brand-primary-light;
			bottom: 0;
			left: 15px;
			right: 15px;
		}
	}


	.facts__headline:not(:first-child) {
		margin-top: floor( -(@line-height-computed * 0.5));
		padding-top: floor( (@line-height-computed * 0.5)) ;
		border-top: 4px solid @brand-primary-light;
	}



	.facts__title {
		margin-bottom: 0;
		position: relative;
		font-size: @font-size-small;
		line-height: 1.5625;
	}


	.facts__item--borderless {
		margin-bottom: 0;


		&:before {
			display: none;
		}
	}


	.facts__item--light {

		.facts__title {
			font-weight: 300;
		}
	}


	.facts__panel {
		font-size: @font-size-small;


		p {
			margin-bottom: floor(@line-height-computed * 0.5);
		}
	}


	.facts__btn {
		position: absolute;
		top: 19px;
		right: 15px;
	}


	.link-group {
		.link-group--no-border;
		.link-group--compact;
	}
}
*/
