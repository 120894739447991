
.link-group {

	&.link-group--widget .link-group__wrap {
		background: white;
		padding: ($spacer * 2) $grid-gutter-width ($spacer * 0.25);
		overflow: hidden;
		clear: both;


		@include media-breakpoint-up(sm) {
			overflow: visible;
			box-shadow: 0 0 40px rgba(0,0,0, 0.1);
		}
	}


	ul {
		list-style: none !important;
		padding: 0;
		margin-left: floor(( $grid-gutter-width * -0.5 ));
		margin-right: floor(( $grid-gutter-width * -0.5 ));
		margin-bottom: ($spacer * 2);
		display: block;


		li {
			margin-left: floor(( $grid-gutter-width * 0.5 ));
			margin-right: floor(( $grid-gutter-width * 0.5 ));
			margin-bottom: 0;
			padding: ($spacer * 0.75) 0;
			border-bottom: 1px solid $gray-300;
			flex: 1 1 100%;


			&:before {
				display: none !important;
			}


			&:first-of-type {
				border-top: 1px solid $gray-300;
			}


			&:last-of-type {
				// border-bottom: 1px solid $gray-300;
				border-bottom: 0;
			}

			&:first-of-type:last-of-type {
				// border-top: 1px solid $gray-300;
				border-bottom: 1px solid $gray-300;
			}


			@include media-breakpoint-up(lg) {
				padding: ($spacer * 1.25) 0;
			}
		}
	}


	a[class^="icon-link"] {
		color: $text-color;
		padding-left: 30px;
		font-weight: 600;
		font-size: $h4-font-size;


		&:before {
			font-size: 22px;
			color: $primary;
		}


		.icon-link__meta,
		.icon-link__desc {
			font-size: $font-size-xs;
			font-weight: 300;
		}


		.icon-link__meta {
			white-space: nowrap;


			&:before {
				// content: " / ";
			}
		}


		.icon-link__desc {
			display: block;

		}


		.icon-link__lang-accessibility {

			display: inline-block;
			height: 2px;
			width: 22px;
			background: rgba(255,255,0,0.0);
			position: relative;
			padding-right: 32px;
			@extend .icon-leichte-sprache;


			&:before {
				position: absolute;
				left: 0;
				top: -4px;
				font-size: 2rem;
				color: $primary;
				line-height: 0;
			}
		}
		
		
		&.icon-link--preview {
			padding: 0;
			
			&:before {
				display: none;
			}
		
			.icon-link__image {
				margin-right: 20px;
				float: left;
				width: 120px;
				/*
				margin-left: 20px;
				float: right;
				*/
				
				img {
					width: 100%;
					height: auto;
				}		
			}
		
			.icon-link__text {
				overflow: hidden;			
			}
		}
		

		&:hover,
		&:focus,
		&:active {
			color: $primary;
			text-decoration: none;
			

			.icon-link__meta,
			.icon-link__desc {
				color: $text-color;
			}
		}


		@include media-breakpoint-up(lg) {
			padding-left: 50px;


			.icon-link__meta,
			.icon-link__desc {
				font-size: $font-size-sm;
			}
		}
	}


	a.icon-link-- {


		&intern {

			&:before {
				top: 1px;
			}
		}


		&extern {

			&:before {
				top: 1px;
			}
		}


		&mail {

			&:before {
				top: 2px;
				left: 0;
			}
		}


		&download {

			&:before {
				top: 1px;
			}
		}


		&phone {

			&:before {
				top: 1px;
				left: -3px;
			}
		}
	}


	&.link-group--col-2 {


		ul {
			display: flex; /* Not working in IE 11 ! */ 
			flex-flow: column nowrap;
			justify-content: space-between;
			align-items: stretch;
			align-content: stretch;
				
			
			@include media-breakpoint-up(sm) {
				flex-flow: row wrap;
			}


			li {

				&:first-of-type:last-of-type {
					//border-top: none;


					@include media-breakpoint-up(sm) {
						flex: 1 1 100%;
						max-width: 100%;
					}
				}


				@include media-breakpoint-up(sm) {
					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: calc( (100% - 60px) / 2 );
					max-width: calc( (100% - 60px) / 2 );


					&:nth-of-type(2) {
						border-top: 1px solid $gray-300;
					}


					&:last-of-type {
						border-bottom: 1px solid $gray-300;
					}
				}
			}
		}
	}
}


.doc-reader-link {
	color: $text-color;
	font-weight: 700;
	font-size: $h4-font-size;


	&:hover,
	&:active,
	&:focus {
		color: $primary;
	}
}


.link-group--no-border {

	ul li {
		border: none !important;
		padding: 0 0 0.5rem !important;
	}


	ul li:last-of-type {
		border: none !important;
		padding: 0 !important;
	}
}


.link-group--compact {


	ul li {
		padding: ($spacer * .75) 0;
	}


	a[class^="icon-link"] {
		font-size: $font-size-xs;
		padding-left: 30px;
		color: $blue;


		&:before {
			font-size: 16px;
		}


		.icon-link__meta,
		.icon-link__desc {
			color: $text-color;
		}
	}


	a.icon-link-- {

		&intern {

			&:before {
				top: 2px;
			}
		}


		&download {

			&:before {
				top: 3px;
			}
		}


		&mail {

			&:before {
				top: 1px;
			}
		}

		&phone {

			&:before {
				top: -1px;
				left: -5px;
			}
		}
		/*
		&extern {

			&:before {
				font-size: 23px;
				top: -4px;
			}
		}


		&phone {

			&:before {
				top: -4px;
			}
		}
		*/
	}
}