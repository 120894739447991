$readspeaker-height: 3rem;
$readspeaker-bg: #fff;
$readspeaker-shadow: rgba(0,0,0, 0.25) 0 0 40px;
$readspeaker-dropdown-btn-width: 3rem;


html body .main {
	

	#readspeaker_button1 {
		cursor: pointer;
		padding-bottom: 0 !important;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 2;
		transition: $transition-base;
		
		
		&.toolsopen {
			bottom: 80px;
		}
		
		
		@include media-breakpoint-up(sm) {
			left: 30px;
			bottom: 30px;
			
			
			&.toolsopen {
				bottom: 110px;
			}
		}
	}


	.rsbtn.rsexpanded {
		box-shadow: $readspeaker-shadow;
		width: 402px !important;
	}


	// Label

	.rs_addtools .rsbtn_play .rsbtn_left .rsbtn_text,
	.rsbtn .rsbtn_play .rsbtn_left .rsbtn_text {


		&:before {
			display: none;
		}


		.rsbtn_label,
		span {
			color: $text-color !important;
			font-size: 1rem !important;
			line-height: $readspeaker-height !important;
			padding: 0 10px 0 30px !important;
			transition: $transition-base;
		}
	}


	// 	RS outter normal

	.rs_addtools .rsbtn_play,
	.rsbtn .rsbtn_play {
		background: #fff;
		border: 0;
		border-radius: 0;
		height: $readspeaker-height;
		box-shadow: $readspeaker-shadow;
		margin-bottom: 0 !important;
		transition: $transition-base;
	}


	// 	RS outter expanded

	.rsexpanded .rs_addtools .rsbtn_play,
	.rsexpanded.rsbtn .rsbtn_play {
		box-shadow: none;
	}


	.rs_addtools .rsbtn_play .rsbtn_left,
	.rsbtn .rsbtn_play .rsbtn_left {
		margin-left: $readspeaker-dropdown-btn-width;
	}


	// RS Play Button idle

	.rs_addtools .rsbtn_play .rsbtn_right.rsimg,
	.rsbtn .rsbtn_play .rsbtn_right.rsimg {
		color: $orange !important;
		padding: 0 0 0 0;
		border-left: 0;
		line-height: $readspeaker-height;
		margin: 0 30px 0 0px;
	}


	// 	RS Play Button Play

	.rsbtn.rsexpanded .rsbtn_play .rsbtn_right,
	.rscompact:not(.rsdetached).rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_progress_container {
		// display: block;
	}


	// 	RS Controls expanded

	.rsbtn.rsexpanded .rsbtn_exp.rsimg.rspart {
		height: $readspeaker-height !important;
		border: 0 !important;


		.rsbtn_exp_inner {
			border: 0 !important;
			background: #fff !important;
			border-radius: 0 !important;
			padding-left: 0 !important;
			box-shadow: $readspeaker-shadow;
		}
	}


	// 	RS Controls progress bar

	.rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_progress_container::before {
		top: 22px !important;
	}


	// 	RS Controls progress bar played

	.rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_progress_container .rsbtn_progress_played {
		top: 22px !important;
		background: $orange !important;
	}


	// 	RS Controls progress handle

	.rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_progress_container .rsbtn_progress_handle {
		top: 17px !important;


		a {
			cursor: pointer;
			background: $blue !important;


			&:hover,
			&:active {
				color: $blue-darker !important;
			}

		}
	}


	// 	RS Controls Icons

	.rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_player_item {
		color: $blue;
		transition: $transition-base;


		&:hover,
		&:active {
			color: $orange;
		}
	}

	// 	RS Controls second Play/Pause Button

	.rsbtn_pause.rsbtn_player_item {
		border-left: 0 !important;
		padding-left: 0 !important;
	}


	// RS Dropdown Button left

	.zoom-tooltoggler .rs_addtools .rsbtn_tooltoggle,
	.rs_addtools .rsbtn_tooltoggle,
	.zoom-tooltoggler.rsbtn .rsbtn_tooltoggle,
	.rsbtn .rsbtn_tooltoggle {
		top: 0 !important;
		bottom: 11px !important;
		left: 0 !important;
		border: 0 !important;
		height: $readspeaker-height !important;
		width: $readspeaker-dropdown-btn-width !important;
		font-size: 11px !important;
		background: transparent !important;
		color: $text-color !important;
		margin-left: 0 !important;
		padding: 0;
		border-right: 1px solid #ccc !important;
		cursor: pointer;
		line-height: $readspeaker-height !important;
		transition: $transition-base;
		z-index: 9999999999999 !important;


		&:hover,
		&:active {
			background: transparent !important;
			color: $text-color !important;
		}


		.rsicn rsicn-arrow-down {
			color: $blue !important;
			line-height: $readspeaker-height !important;
			display: inline-block !important;
			font-size: 11px !important;
			height: $readspeaker-height !important;
			width: 20px !important;
			transition: $transition-base;


			&:before {
				color: $blue;
				line-height: $readspeaker-height;
				display: inline-block;
				height: $readspeaker-height !important;
				width: 20px !important;
				transition: $transition-base;
			}
		}
	}


	// 	RS Tools

	.rs_addtools .rsbtn_toolpanel .rsbtn_tools,
	.rsbtn .rsbtn_toolpanel .rsbtn_tools {
		background: $readspeaker-bg;
		box-shadow: $readspeaker-shadow;
		border: 0 !important;
		margin-top: 2px;
		width: 450px !important;
	}


	.rs_addtools .rsbtn_toolpanel .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer .rsbtn_toollist li .rsbtn_tool,
	.rsbtn .rsbtn_toolpanel .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer .rsbtn_toollist li .rsbtn_tool {
		border: 0;
	}


	.rs_addtools .rsbtn_toolpanel .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer,
	.rsbtn .rsbtn_toolpanel .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer {
		padding: 20px 0 !important;
		margin: 0 !important;


		.rsbtn_toollist {


			&:after {
				display: block;
				content: "";
				clear: both;
			}
		}
	}


	.rs_addtools .rsbtn_toolpanel .rsbtn_tools .rsbtn_tools_inner .rsbtn_powered,
	.rsbtn .rsbtn_toolpanel .rsbtn_tools .rsbtn_tools_inner .rsbtn_powered {
		top: auto;
		bottom: 9px !important;
		right: 17px !important;


		span,
		a {
			color: $gray-500;
		}
	}


	//  RS Tools Icons

	.rs_addtools .rsbtn_toolpanel .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer .rsbtn_toollist li,
	.rsbtn .rsbtn_toolpanel .rsbtn_tools .rsbtn_tools_inner .rsbtn_toolcontainer .rsbtn_toollist li {


		.rsbtn_tool {
			color: $blue !important;
			padding: 0 15px;
			transition: $transition-base;


			&:hover,
			&:active {
				color: $orange !important;
			}
		}
	}


	// 	RS drag bar

	.rsbtn_dragbar {

	}

	.rsbtn.rsdetached .rsbtn_dragbar {
		background: $gray-500;
		border: none;
		border-bottom: none;
		top: -30px;
		left: 15px;
		width: 100px;
		height: 30px;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		transition: $transition-base;


		&:hover,
		&:active {
			width: 100px;
			background: $gray-600;

			.rsicn {
				color: $text-color;
			}
		}


		&:focus {
			width: 100px;
			background: $orange;

			.rsicn {
				color: $text-color;
			}
		}


		.rsicn {
			font-size: 20px;
			position: absolute;
			top: 5px;
			left: 5px;
			color: $gray-600;
			transition: $transition-base;
		}
	}


	// Readspeaker Tools

	.rsbtn_toolpanel {
		z-index: 2 !important;
	}


	// Prevent changes to text styling when RS starts

	.sync_sent, 
	.sync_word, 
	.sync_word_highlighted,
	.sync-sent_highlighted {
		font-size:inherit !important;
		font-family:inherit !important;
	}
}

@media print {
	
	html body .main {
		
		.read-speaker-container,
		#readspeaker_button1 {
			display: none !important;
		}
	}
}