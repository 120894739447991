
.image {
	position: relative;


	img {
		display: block;
		height: auto;
		width: 100%;
		max-width: 100%;
	}

	picture,
	picture source {
		display: block;
	}
}


/*
.video .media .media-caption {
	z-index: -99999999999;
}
*/


.image__credits {
	display: inline-block;
	font-size: $font-size-xxs;
	line-height: 1.5;
	color: white;
	opacity: 1;
	z-index: 1;
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 0 4px;
	background: rgba(0, 0, 0, .2);
	color: white;
	-webkit-backface-visibility: hidden;
	transition: opacity .2s ease-in-out;
	text-align: right;


	@include media-breakpoint-up(sm) {
		// opacity: 0;
	}
}


@include media-breakpoint-up(sm) {

	.image:hover .image__credits {
		// opacity: 1;
		background: rgba(0, 0, 0, .6);
	}
}


.media {
	position: relative;
	// margin-left: -20px;
	// margin-right: -20px;
	margin: 0;


	@include media-breakpoint-up(sm) {
		margin-left: 0;
		margin-right: 0;
	}
}


.media__caption {
	font-size: 12px;
	padding: ($spacer * .5) 20px;
	position: relative;


	@include media-breakpoint-up(sm) {
		padding: ($spacer * 1) 0;
	}


	@include media-breakpoint-up(lg) {
		font-size: $font-size-xs;
	}


	p {
		padding: 0;
	}
}


audio {
	width: 100%;
}




img.fakeImgLazyLoading {
    opacity: 0;
    transition: ease-in opacity 650ms;
    transition-delay: 50ms;
}


img.fakeImgLazyLoading.fakeImgLazyLoading-fadeIn {
    opacity: 1;
}
