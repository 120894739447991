
.minicart {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	background: $blue-lighter;
	color: $blue;
	background: $blue-lighter;
	background-image: url("../images/bg_teaser_warenkorb@2x.png");
	background-repeat: no-repeat;
	background-size: 173px 138px;
	background-position: top 28% right 33%;
	padding: 0 1rem 1rem 1rem;


	@include media-breakpoint-up(sm) {
		padding: 1rem;
	}


	@include media-breakpoint-up(md) {
		padding: 0;
	}
}


.minicart__status {
	margin-bottom: 0;
	font-weight: 700;
	padding: 1rem 0;


	@include media-breakpoint-up(md) {
		padding: 0 1rem;

	}
}
