
.notice {
	position: relative;


	.notice__wrap {
		position: relative;
		padding: ($spacer * 1) floor(( $grid-gutter-width * 0.5 )) ;
		overflow: hidden;
		background: $blue-lighter;
		// border: 1px solid $blue;
		border-radius: 0;


		@include media-breakpoint-up(md) {
			// padding: ($spacer * 2) 8.333333%;
			padding: ($spacer * 1);
		}

		.notice__icon {

			.image {
				margin-bottom: 0;
				margin-right: $grid-gutter-width;
				width: 70px;
				height: 70px;
				border-radius: 50%;
				float: left;


					img {
						border-radius: 50%;
					}
				}
			}

			.notice__text {
				position: relative;
				display: flex;

				p {
					margin-bottom: 0;
					margin-left: ($spacer * 3);
    				position: relative;
					padding: ($spacer);
				}
			}
			
			
			
			.notice__list {
				padding-left: 1rem;
				
				
				@include media-breakpoint-up(sm) {
					margin-left: ($spacer * 4);
				}
			}
			

			i {
				display: block;
				position: absolute;
				right: 0;
				top: 0;


				&:before {
					z-index: 2;
					color: $text-color;
					font-size: 28px;
				}
			}


			@include media-breakpoint-up(sm) {

				i {

					&:before {
						font-size: 50px;
					}
				}
			}
		}


	&.notice--doubleoptin i {
		top: 5px;


		&:before {
			font-size: 44px;
			// @extend .icon-navi-herz;


			@include media-breakpoint-up(sm) {
				font-size: 80px;
			}
		}
	}



	&.notice--success i {
		top: -15px;


		&:before {
			font-size: 36px;
			// @extend .icon-navi-herz;


			@include media-breakpoint-up(sm) {
				font-size: 70px;
			}
		}
	}

	&.notice--success {
		color: $green;

		& .notice__wrap {
			background: rgba($green, .1);
			// border: 1px solid $green;
		}
		& p {
			font-weight: $font-weight-bold;
		}
		& p:before {
			@extend .icon-tickbox;
			display: inline-block;
			position: absolute;
			font-size: $font-size-lg;
			left: -($spacer * 2.5);
			top: 50%;
			transform: translateY(-50%)
		}
	}

	&.notice--status {
		color: $blue;

		& p {

			font-weight: $font-weight-bold;
		}
		
		& p:before {
			@extend .icon-info;
			display: inline-block;
			position: absolute;
			font-size: $font-size-lg;
			left: -($spacer * 2.5);
			top: 50%;
			transform: translateY(-50%)
		}
	}

	&.notice--status--thx {
		color: ($blue);
		.notice__wrap {
			border: 0;
		}

		& p {
			color: ($black);
			line-height: ($spacer * 7);
			font-size: $font-size-md;
			padding-left: ($spacer * 2);
			font-weight: $font-weight-bold;
			width: 90%;
		}
		
		& p:before {
			@extend .icon-message-shop-bestellt;
			color: ($blue);
			margin-right: 1rem;
		    font-size: 5rem;
		    float: right;
		    border-radius: 100%;
		    width: auto;
		    padding: 1rem;
		    background: white;
		}
	}

	&.notice--status--done {
		color: ($blue);
		
		.notice__wrap {
			border: 0;
		}

		.notice__text,
		.notice__text p {
			color: ($black);
			line-height: ($spacer * 7);
			font-size: $font-size-md;
			font-weight: $font-weight-bold;
		}
		
		.notice__text:before {
			@extend .icon-tickbox;
			color: ($blue);
			margin-right: 2rem;
		    font-size: 2rem;
		    float: right;
		    border-radius: 100%;
		    width: auto;
		    padding: 2.5rem;
		    background: white;
		    
		    position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}


	


	&.notice--info i {
		top: -15px;
		color: white !important;


		&:before {
			font-size: 36px;
			@extend .icon-navi-herz;


			@include media-breakpoint-up(sm) {
				font-size: 70px;
			}
		}
	}


	&.notice--404 {
		color: #000;;


		h1 {
			color: $primary;

		}

		i {
			top: 15px;
			transform: scaleY(-1);


			&:before {
				font-size: 36px;
				// @extend .icon-navi-herz;


				@include media-breakpoint-up(sm) {
					font-size: 70px;
				}
			}
		}
	}


	&.notice--error {
		color: $danger;


		.notice__wrap {
			background: rgba( $danger, .1);
			// border: 1px solid $danger;
		}


		i {
			background: $danger;


			&:before {
				// @extend .icon-navi-herz;
				color: white;
			}
		}
		
		.notice__text {
		
		p {
			font-weight: $font-weight-bold;
			margin-left: 0;
			padding: ($spacer * 2.5) 0 $spacer;
			 
			@include media-breakpoint-up(sm) {
				padding: $spacer;
				margin-left: 3rem;	
			}
			
			&:before {
				@extend .icon-fehler;
				display: inline-block;
				position: absolute;
				font-size: $font-size-lg;
				left: 50%;
				top: 0;
				transform: translate(-50%,0);
					
				@include media-breakpoint-up(sm) {
					left: -($spacer * 2.5);
					top: 50%;
					transform: translate(0,-50%);
				}
			}
		}
		}
	}

}

.shop .notice .notice__wrap {
	@include media-breakpoint-up(md) {
		padding: ($spacer * 1);
	}
}
