
.lang-accessibility {
	margin-top: ($spacer * -0);
	margin-bottom: ($spacer * 2);
	padding: 0 0px;


	@include media-breakpoint-up(md) {
		margin-top: ($spacer * -3);
		margin-bottom: ($spacer * 3);
		padding: 0 30px;
	}


	> div {
		display: flex;
		align-items: center;


		@include media-breakpoint-down(sm) {
			padding: ($spacer * 1) 0;
		}
	}


	.lang-accessibility__btn {
		// float: right;
		margin-left: auto;
	}
}


.lang-accessibility__btn,
.lang-accessibility__indicator {
	padding: 5px 10px 5px 40px;
	position: relative;
	display: inline-block;


	.lang-accessibility__icon {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		line-height: 1;
		font-size: 40px;
		display: inline-block;


		@include media-breakpoint-down(sm) {
			font-size: 30px;
		}
	}


	.lang-accessibility__label {
		// text-transform: uppercase;
		font-size: 11px;
		font-weight: 600;
		line-height: 1.1;
		display: inline-block;
		padding: 3px 0;
	}
}


.lang-accessibility__indicator--easy {
	color: $primary;


	.lang-accessibility__icon {
		@extend .icon-leichte-sprache;
	}
}


.lang-accessibility__indicator--difficult {
	color: $red;


	.lang-accessibility__icon {
		@extend .icon-schwere-sprache;
	}
}


.lang-accessibility__indicator {

	@include media-breakpoint-down(sm) {
		padding-left: 30px;
	}
}



.lang-accessibility__btn {
	color: $white;
	padding: 5px 40px 5px 50px;

	@include media-breakpoint-down(sm) {
		padding: 5px 30px 5px 38px;
	}


	.lang-accessibility__icon {
		left: 5px;
	}


	.lang-accessibility__arrow {
		@extend .icon-arrow-button-next;
		position: absolute;
		right: 7px;
		top: 50%;
		transform: translateY(-50%);
		line-height: 1;
		font-size: 14px;
		display: inline-block;


		@include media-breakpoint-down(sm) {
			font-size: 10px;
		}
	}
}


.lang-accessibility__btn--easy {
	background: $primary;
	transition: $transition-base;


	&:hover,
	&:active {
		background: #00528A;
		color: #fff;
	}


	.lang-accessibility__icon {
		@extend .icon-leichte-sprache;
	}
}


.lang-accessibility__btn--difficult {
	background: $red;
	transition: $transition-base;


	&:hover,
	&:active {
		background: #b02e18;
		color: #fff;
	}


	.lang-accessibility__icon {
		@extend .icon-schwere-sprache;
	}
}





