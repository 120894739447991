
.footer {
	padding: ($spacer * 2) 0;
	background: $primary;
	color: $white;

	a {
		color: $white;
	}
}


.footer__logo {
	margin-bottom: ($spacer * 2);


	img {
		display: block;
		width: 100%;
		width: 100% \9; // IE 10 fix
		max-width: (300px - ($grid-gutter-width * 2));
		height: auto;

		@include media-breakpoint-up(xl) {
			max-width: 300px;
		}
	}
}


.footer__address,
.footer__description {
	margin-bottom: ($spacer * 1);

	@include media-breakpoint-up(md) {
		margin-bottom: ($spacer * 3);
	}
}


.sitemap__list {
	margin-bottom: 0;
}


.sitemap__title {
	font-size: $font-size-base;
	margin-bottom: ($spacer * 1);
}


.sitemap__list,
.sitemap__sub-list {
	list-style: none;
	padding: 0;
}


.sitemap__item {
	padding-top: ($spacer * 1);
	margin-bottom: ($spacer * 2);
	// display: none;

	@include media-breakpoint-up(md) {
		padding-top: ($spacer * 2);
		// display: block;
	}

	&.col-sm-12 {
		// display: block;
	}

	a {
		&:hover,
		&:active,
		&:focus {
		color: #fff;
		}
	}


	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: ($grid-gutter-width / 2);
		right: ($grid-gutter-width / 2);
		height: 1px;
		background: $blue-darker;
	}
}


.footer__address,
.footer__description,
.sitemap__sub-item {
	font-size: $font-size-sm;
}


.footer__logo img,
.footer__address address,
.footer__description p,
.sitemap__title,
.sitemap__sub-list {


	@include media-breakpoint-up(xl) {
		padding: 0 ($grid-gutter-width * 1);
	}
}


.footer__zip-search {
	background: $white;
	padding: ($spacer * 2) 15px;
	margin-top: -($spacer * 2);
	margin-bottom: ($spacer * 2);
	align-items: center;
	@include wide-bg(#fff);


	@include media-breakpoint-up(md) {
		padding: ($spacer * 3) 0;
	}


	.inline-search__input,
	.inline-search__submit {
		color: $blue;


		&::placeholder {
			color: $blue;
			font-size: $font-size-md;
		}
	}


	.inline-search__input {
		box-shadow: inset 0 0 7px rgba($black, 0.16);


		&:focus {
			box-shadow: inset 0 0 14px rgba($black, 0.2);
		}
	}


	.inline-search__submit {


		&:hover,
		&:active {

			&:before {
				color: $text-color;
			}
		}
	}
}


.zip-search__title {
	color: $blue;


	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}


	@include media-breakpoint-up(md) {
		text-align: right;
	}


	@include media-breakpoint-up(xl) {
		text-align: center;
	}
}



@media print {

	.footer {
		display: none !important;
	}
}
