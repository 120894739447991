.share {
	color: $gray;
	text-align: right;
	display: none;


	@include media-breakpoint-up(sm) {
		display: block;
	}


	@include media-breakpoint-up(md) {
		// padding: 0 floor((@grid-gutter-width * 0.5)) 0 0;
	}


	@media print {
		display: none !important;
	}


	span {
		display: block;
		padding-left: 15px;
	}


	ul {
		margin: 0;


		li {
			padding: 0;
			line-height: 60px;
			float: left;


			&:first-child {
				border-left: 1px solid #e8e8e8;
			}


			.share__icon {
				width: 60px;
				height: 60px;
				display: block;
				text-align: center;
				color: $text-color;
				line-height: 60px;
				position: relative;
				text-decoration: none;
				border-right: 1px solid #e8e8e8;


				&:before {
					display: block;
					text-align: center;
					font-size: 24px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
				}


				&.share__icon--facebook {
					@extend .icon-facebook;
				}

				&.share__icon--twitter {
					@extend .icon-twitter;
				}

				&.share__icon--email {
					@extend .icon-mail-small;
				}

				&.share__icon--print {
					@extend .icon-print;
				}


				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
					color: $primary;
				}
			}
		}
	}
}